import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import messagesEn from 'vee-validate/dist/locale/en.json';
import messagesJa from 'vee-validate/dist/locale/ja.json';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { AllCountry } from "vuetify-country-search";
import vuetify from '../plugins/vuetify';

// let's write our custom rules here
//this is a url validation checking function
const validatorUrlValidator = val => {
    if (val === undefined || val === null || val.length === 0) {
      return true
    }
    /* eslint-disable no-useless-escape */
    const re = /^(http[s]?:\/\/){1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
    /* eslint-enable no-useless-escape */
    return re.test(val)
  }

//this is for only checking numeric value
const validatorNumeric = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  return isNaN(val)
}

// check max allowed decimal digits 
const validatorDecimals = val => {
  if (
    (val && val.toString().split('.').length < 2) 
    || (val && val.toString().split('.')[1].length <= 7)
  ){
    return true
  }
  return false
}

//number with dash for postal code validation
const numberDashValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  const re = /^[0-9_-]*$/i
  return re.test(val)
}

// phone number validation
const phoneValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  const re = /^\+?[\d\s\-*]+\d$/
  return re.test(val)
}

// birth date validation
const birthdateValidator = val => {
  if(val === undefined || val === null || val.length === 0) {
    return true
  }
  let date = new Date()
  let today = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
  return new Date(val).getTime() >= new Date(today).getTime() ? false : true
}
// postal_code validation
const postalCodeValidator = (val, { country }) => {
  if (val === undefined || val === null || val.length === 0 || !country) {
    return true
  }
  const countryShortCode = typeof country === 'object' ? country.countryShortCode : AllCountry.filter((obj) => obj.countryCode === country)[0].countryShortCode;
  if (!postcodeValidatorExistsForCountry(countryShortCode)) return true;
  return postcodeValidator(val, countryShortCode);
}

// all the rules from vee-validate package are extend from here
// You can find all the rules from here https://vee-validate.logaretm.com/v3/guide/rules.html#rules
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: (field, v) => {
      let local = vuetify.framework.lang?.current;
      return local === 'ja' ? messagesJa.messages[rule].replace('{_field_}', field).replace('{min}', v.min) : messagesEn.messages[rule].replace('{_field_}', field).replace('{min}', v.min)
    }
  });
});

//let's create our own rules here
extend('url', {
    validate: validatorUrlValidator,
    message: (field) => {
      let local = vuetify.framework.lang?.current;
      return vuetify.preset.lang.locales[local].validation_msg.url;
    }
})

extend('is_numeric', {
  validate: validatorNumeric,
  message: (field) => {
    let local = vuetify.framework.lang?.current;
    return vuetify.preset.lang.locales[local].validation_msg.is_numeric.replace('{_field_}', field)
  }
})

extend('is_decimal_allowed', {
  validate: validatorDecimals,
  message: (field) => {
    let local = vuetify.framework.lang?.current;
    return vuetify.preset.lang.locales[local].validation_msg.max_decimal.replace('{_field_}', field)
  }
})

extend('number_dash', {
  validate: numberDashValidator,
  message: (field) => {
    let local = vuetify.framework.lang?.current;
    return vuetify.preset.lang.locales[local].validation_msg.invalid_filed.replace('{_field_}', field)
  }
})

extend('phone_num', {
  validate: phoneValidator,
  message: (field) => {
    let local = vuetify.framework.lang?.current;
    return vuetify.preset.lang.locales[local].validation_msg.invalid_filed.replace('{_field_}', field)
  }
})

extend('birth_date', {
  validate: birthdateValidator,
  message: (field) => {
    let local = vuetify.framework.lang?.current;
    return vuetify.preset.lang.locales[local].validation_msg.invalid_filed.replace('{_field_}', field)
  }
})

extend('postal_code', {
  params: ['country'],
  validate: postalCodeValidator,
  message: (field) => {
    let local = vuetify.framework.lang?.current;    
    return vuetify.preset.lang.locales[local].validation_msg.postal_code.replace('{_field_}', field)
  }
})
