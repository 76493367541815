<template>
    <div>
        <v-row>
            <v-col cols="12" md="2" class="pl-12 align-self-center">
                <v-card class="pa-2" flat>
                    <strong>{{ `${$vuetify.lang.t("$vuetify.training.items_train")}` }}</strong>
                </v-card>
            </v-col>

            <v-col cols="12" md="8"> </v-col>

            <v-col cols="12" md="2">
                <v-btn
                  v-if="!this.inProgress"
                  :disabled="$authorize([$role.USER])"
                  color="primary"
                  @click="startTraining"
                >
                  <v-icon>mdi-play</v-icon>
                  {{ `${$vuetify.lang.t("$vuetify.tooltip.start")}` }}
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  class="mr-4 secondary-disabled-button"
                >
                  {{ `${$vuetify.lang.t("$vuetify.training.training")}` }}
                  <v-progress-circular
                    indeterminate
                    :size="15"
                    :width="2"
                    color="white"
                  ></v-progress-circular>
                </v-btn>
            </v-col>
        </v-row>
        <v-container class="pl-12">
            <v-row>
                <v-col cols="12" xs="12" md="3">
                    <v-select
                      v-model="filterTrainItemType"
                      :items="filterTrainItemOptions"
                      :label="$vuetify.lang.t('$vuetify.training.train_options')"
                      :disabled="$authorize([$role.USER])"
                      item-text="name"
                      item-value="value"
                      outlined
                      dense
                    ></v-select>
                </v-col>
            </v-row>
            <v-form v-if="filterTrainItemType === 'train_item_within_specified_range'" ref="itemTrainForm">
                <v-row cols="12" md="6" class="mb-0 pb-0">
                    <v-col cols="2">
                        <span>Train From</span>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-menu ref="trainFromItemMenu" v-model="trainFromManue" :close-on-content-click="false"
                            :return-value.sync="trainFromDate" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field ref="startDateField" v-model="trainFromDate"
                                    placeholder="input start date..." clearable readonly
                                    :rules="[validationRules.required, validationRules.startDateLessThanEndDate]"
                                    prepend-inner-icon="mdi-calendar" dense outlined v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="trainFromDate" no-title scrollable :max="new Date().toISOString().slice(0, 10)">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="trainFromManue = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.trainFromItemMenu.save(trainFromDate)">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
                <v-row cols="12" md="6" class="mb-0 pb-0">
                    <v-col cols="2">
                        <span>Train To</span>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-menu ref="trainToMenu" v-model="trainToManue" :close-on-content-click="false"
                            :return-value.sync="trainToDate" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field ref="endDateField" v-model="trainToDate" placeholder="input end date..."
                                    clearable readonly prepend-inner-icon="mdi-calendar" dense outlined v-bind="attrs" v-on="on"
                                    :rules="[validationRules.required, validationRules.endDateGreaterThanStartDate]"></v-text-field>
                            </template>
                            <v-date-picker v-model="trainToDate" no-title scrollable :max="new Date().toISOString().slice(0, 10)">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="trainToManue = false">
                                    Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.trainToMenu.save(trainToDate)">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-form>
            <v-dialog v-model="showWarningModal" max-width="400"  @click:outside="cancelTrainAll">
                <v-card>
                        <v-card-title class="headline">
                            <v-icon left color="warning">mdi-alert</v-icon>
                            {{`${$vuetify.lang.t("$vuetify.common.warning_title_txt")}`}}      
                    </v-card-title>
                        <v-card-text>
                            {{`${$vuetify.lang.t("$vuetify.training.training_all_items_warning_txt")}`}}      
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="grey darken-1" text @click="cancelTrainAll">
                                {{`${$vuetify.lang.t("$vuetify.common.cancel_btn")}`}}
                            </v-btn>
                            <v-btn color="warning" text @click="confirmTrainAll">
                                {{`${$vuetify.lang.t("$vuetify.common.proceed")}`}}
                            </v-btn>
                        </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
        <v-row v-if="updateResult !== ''" class="pl-11">
            <v-col cols="auto" class="">
                <v-icon v-if="updateStatus === 'success'" color="#C8E6C9">mdi-check-circle</v-icon>
                <v-icon v-else-if="updateStatus === 'failed'" color="error">mdi-alert-circle</v-icon>
            </v-col>
            <v-col>
                <p>
                    <span :class="{ 'error--text': updateStatus === 'failed' }">
                        {{ updateResult }}
                    </span>
                </p>
            </v-col>
        </v-row>
        <!-- Snackbar for messages -->
        <v-snackbar v-model="snackbar" color="success" top timeout="3000">
            {{ snackbarMessage }}
        </v-snackbar>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import {
    ITEMS_TRAIN,
    GET_TASK_STATUS,
} from "@/store/_actiontypes";

import getEnv from "@/config/env";

export default {
    props: {},
    components: {},
    data() {
        return {
            filterTrainItemType: "train_updated_item",
            filterTrainItemOptions: [
                { name: this.$vuetify.lang.t("$vuetify.training.train_updated_item"), value: "train_updated_item" },
                { name: this.$vuetify.lang.t("$vuetify.training.train_item_within_specified_range"), value: "train_item_within_specified_range" },
                { name: this.$vuetify.lang.t("$vuetify.training.train_all_item"), value: "train_all_item" },
            ],
            validationRules: {
                required: value => !!value || this.$vuetify.lang.t("$vuetify.training.date_field_required"),
                startDateLessThanEndDate: value => !this.trainToDate || new Date(value) < new Date(this.trainToDate) || this.$vuetify.lang.t("$vuetify.training.start_date_validation"),
                endDateGreaterThanStartDate: value => !this.trainFromDate || new Date(value) > new Date(this.trainFromDate) || this.$vuetify.lang.t("$vuetify.training.end_date_validation")
            },
            trainFromDate: '',
            trainToDate: '',
            trainFromManue: false,
            trainToManue: false,
            showWarningModal: false,
            inProgress: false,
            updateResult: "",
            updateStatus: "",
            polling: null,
            snackbar: false,
            snackbarMessage: "",
        };
    },
    computed: {
        ...mapState({
            selectedProject: (state) => state.project.selectedProject,
        }),
    },
    watch: {
        filterTrainItemType: {
            handler(newValue, oldValue) {
                if (newValue !== "train_item_within_specified_range") {
                    this.trainFromDate = "";
                    this.trainToDate = "";
                }
                this.isTrainAll = (newValue === "train_all_item");
                if (newValue === "train_all_item") {
            this.showWarningModal = true;
                } else {
                this.isTrainAll = false;
                }
            },
            immediate: true,
        },
        trainFromDate() {
            this.$refs.endDateField.validate();
        },
        trainToDate() {
            this.$refs.startDateField.validate();
        }
    },
    beforeDestroy() {
        this.stopPolling();
    },
    methods: {
        ...mapActions("project", [
            ITEMS_TRAIN,
        ]),
        ...mapActions("task", [GET_TASK_STATUS]),
        async startPolling(task_id) {
            this.polling = setInterval(() => {
                this.getTaskStatus(task_id);
            }, getEnv("VUE_APP_POLLING_INTERVAL"));
        },
        stopPolling() {
            clearInterval(this.polling);
            this.polling = null;
        },
        async getTaskStatus(task_id) {
            let project_id = this.selectedProject.id;
            this.GET_TASK_STATUS({
                project_id: project_id,
                task_id: task_id
            }).then(
                (response) => {
                    if (response.status === 200) {
                         const taskResponse = response.data.detail.response;
                        if (taskResponse.status !== "pending") {
                            this.stopPolling();
                            this.inProgress = false;
                            this.isLoading = false;
                        }
                         if (taskResponse.status === "success") {
                        this.showSnackBar("success", taskResponse.message);
                    } else if (taskResponse.status === "failed") {
                        this.showSnackBar("error", taskResponse.message);
                    }
                         this.updateResult = taskResponse.message;
                        this.updateStatus = taskResponse.status;
                    }
                },
                (error) => {
                    console.error(error.response);
                }
            );
        },
        async startTraining() {
            if (this.$refs.itemTrainForm && !this.$refs.itemTrainForm.validate()) {
                return;
            }

            let project_id = this.selectedProject.id;
            let is_trainAll = this.isTrainAll
            let start_date = this.trainFromDate
            let end_date = this.trainToDate
            this.inProgress = true;
            this.ITEMS_TRAIN({
                project_id: project_id,
                train_all: is_trainAll,
                train_from: start_date,
                train_to: end_date
            }).then(
                async (response) => {
                    if (response.status === 202) {
                        this.startPolling(response.data.detail.response);
                    } else {
                        this.inProgress = false;
                        console.error(response);
                    }
                },
                async (error) => {
                    this.inProgress = false;
                    console.error(error.response);
                }
            );
        },
       cancelTrainAll() {
            this.showWarningModal = false;
            this.filterTrainItemType = "train_updated_item";
         },
        confirmTrainAll() {
            this.showWarningModal = false;
            this.isTrainAll = true;
        },
        showSnackBar(type = "success", message = "Success") {
            this.snackbar = true;
            switch (type) {
                case "success":
                this.snackbarMessage = message;
                break;
                case "error":
                this.snackbarMessage = message;
                break;
            }
        },
    },
};
</script>

<style scoped>
.secondary-disabled-button {
    cursor: not-allowed;
    pointer-events: none;
}
</style>
