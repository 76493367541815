import {
  getCurrentUser,
  deleteCurrentUser,
  setCurrentUser,
} from "@/helpers/helper";
const localUser = getCurrentUser();
import store from "@/store";
import Api from "@/services/api";
import router from "@/router/index";
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  REFRESHTOKEN,
  // CREATE_ACCOUNT,
  SWITCH_ACCOOUNT,
  EDIT_USER_DETAILS,
  EDIT_USER_SETTINGS,
  EDIT_USER_PROFILE,
  ADD_ALERT,
  LOAD_CURRENCIES,
  LOAD_ORGANIZATION,
  TOGGLE_LOADING,
  RESEND_VERIFICATION_EMAIL,
  SEND_PASSWORD_RESET_LINK,
  VERIFY_RESET_PASSWORD_LINK,
  RESET_PASSWORD,
  CHANGE_USER_PASSWORD,
  VERIFY_EMAIL,
  VERIFY_MEMBER,
  COMPLETE_REGISTRATION,
  LOAD_ACCOUNT,
  EDIT_ACCOUNT_SETTINGS,
  UPLOAD_USER_ID,
} from "@/store/_actiontypes";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SWITCH_SUCCESS,
  SWITCH_FAILURE,
  LOGOUT_USER,
  UPDATE_USER_DETAILS,
  UPDATE_USER_SETTINGS,
  UPDATE_USER_PROFILE,
  SET_CURRENCIES,
  SET_ORGANIZATION,
  CLEAR_ERROR,
  SET_LOGIN_ERROR,
  SET_SIGNUP_ERROR,
  SET_SWITCH_ERROR,
  SET_RESET_PASSWORD_USER_DETAILS,
  SET_ACCOUNT,
} from "@/store/_mutationtypes";

const state = {
  user: localUser ? localUser : null,
  currencies: [],
  organization: {},
  login_error: null,
  signup_error: null,
  resetPasswordUserDetails: {},
  account: {},
};

const actions = {
  [LOGIN](
    { dispatch, commit },
    { email, password, recaptcha_token, clientIP },
  ) {
    commit(CLEAR_ERROR);
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(
        "oauth2/token?grant_type=password",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "X-Captcha-Token": recaptcha_token,
            "X-Client-IP": clientIP,
          },
        },
      )
        .then(
          response => {
            updateLoaderTo(false);
            let user = response.data;
            commit(LOGIN_SUCCESS, user);
            resolve(response);
            // router.push("/solutions");
            // window.location.reload(true);
          },
          error => {
            let login_error = error.response.data.message;
            commit(SET_LOGIN_ERROR, login_error);
            commit(LOGIN_FAILURE);
            updateLoaderTo(false);
            reject(error);
          },
        )
        .catch(() => {});
    });
  },

  //Not used for now as its autometically created after verify member request
  // [CREATE_ACCOUNT]({ dispatch, commit },{account_id ,user_id}){
  //   // commit(CLEAR_ERROR);
  //   updateLoaderTo(true);
  //   // console.log("CREATE_ACCOUNT hited--------------------------------------");
  //   return new Promise((resolve, reject) => {
  //     console.log("ACCOUNT ID ", account_id ,user_id);
  //     Api.post(
  //       "user/account/create/", {account_id ,user_id},

  //     ).then(
  //       (response) => {
  //         // updateLoaderTo(false);
  //         let user = response.data;
  //         console.log("response from vuex 111 ," ,response);
  //         // commit(SWITCH_SUCCESS, user);
  //         resolve(response);
  //         // router.push("/solutions");
  //         // window.location.reload(true);
  //       },
  //       (error) => {
  //         console.log("error from vuex---------");
  //           let switch_error = error.response.data.message;
  //           // commit(SET_SWITCH_ERROR, switch_error );
  //           // commit(SWITCH_FAILURE);
  //           // updateLoaderTo(false);
  //         reject(error);
  //       }
  //     ).catch(() => {})
  //   });
  // },

  [SWITCH_ACCOOUNT]({ dispatch, commit },{account_id}){
    // commit(CLEAR_ERROR);
    // updateLoaderTo(true);

    return new Promise((resolve, reject) => {
      Api.put(
        "users/switch/accounts", {account_id},

      ).then(
        (response) => {
          // updateLoaderTo(false);
          let user = response.data;
          commit(SWITCH_SUCCESS, user);
          resolve(response);
          router.push("/");
          window.location.reload(true);
        },
        (error) => {
            let switch_error = error.response.data.message;
            commit(SET_SWITCH_ERROR, switch_error );
            commit(SWITCH_FAILURE);
            // updateLoaderTo(false);
          reject(error);
        }
      ).catch(() => {})
    });
  },

  [REGISTER](
    { commit, dispatch },
    { name, email, password, recaptcha_token, clientIP },
  ) {
    commit(CLEAR_ERROR);
    updateLoaderTo(true);

    return new Promise((resolve, reject) => {
      Api.post(
        "accounts/register",
        {
          account_name: name,
          email: email,
          password: password,
        },
        {
          headers: {
            "X-Captcha-Token": recaptcha_token,
            "X-Client-IP": clientIP,
          },
        },
      )
        .then(
          response => {
            updateLoaderTo(false);
            // dispatch(
            //   `alert/${ADD_ALERT}`,
            //   { message: "Organization registered successfully", color: "success" },
            //   { root: true }
            // );
            // router.push("/login");
            resolve(response);
          },
          error => {
            commit(SET_SIGNUP_ERROR, error.response.data.message);
            updateLoaderTo(false);
            reject(error);
          },
        )
        .catch(error => {
          commit(SET_SIGNUP_ERROR, error.response.data.message);
          updateLoaderTo(false);
        });
    });
  },
  async [REFRESHTOKEN]({ commit }, { refresh_token }) {
    try {
      const response = await Api.post("oauth2/token?grant_type=refresh_token", {
        refresh_token,
      });

      const user = {
        access_token: response.data.access_token,
        refresh_token: response.data.refresh_token,
        expires_at: response.data.expires_at,
        token_type: "Bearer"
      };    
      commit(LOGIN_SUCCESS, user);
      setCurrentUser(user)
      return response;
    } catch (error) {
      commit(LOGIN_FAILURE);
      throw error;
    }
  },

  async [LOGOUT]({ commit }, { refresh_token }) {
    try {
      await Api.post("oauth2/revoke", { refresh_token });

      commit(LOGOUT_USER);
      await router.push("/login");
    } catch (error) {
      console.error("Error during logout:", error);
    }
  },
  [VERIFY_EMAIL](
    { commit, dispatch },
    { token, verify_method, recaptcha_token, clientIP },
  ) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(
        `users/${token}/verify/${verify_method}`,
        {},
        {
          headers: {
            "X-Captcha-Token": recaptcha_token,
            "X-Client-IP": clientIP,
          },
        },
      ).then(
        response => {
          updateLoaderTo(false);
          let jsonResponse = response.data;
          commit(LOGIN_SUCCESS, jsonResponse);
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: jsonResponse.message, color: "success" },
            { root: true },
          );
          resolve(response);
        },
        error => {
          updateLoaderTo(false);
          reject(error);
        },
      );
    });
  },

  [VERIFY_MEMBER]({ commit, dispatch }, { token, verify_method, recaptcha_token }) {
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(
        `users/accounts/${token}/accept/${verify_method}`,
        {},
        {
          headers: {
            'X-Captcha-Token': recaptcha_token
          }
        }
      ).then((response) => {
        updateLoaderTo(false);
        let jsonResponse = response.data;
        // commit(LOGIN_SUCCESS, jsonResponse);
        dispatch(
          `alert/${ADD_ALERT}`,
          { message: jsonResponse.message, color: "success" },
          { root: true }
        );
        resolve(response);
      },(error) => {
        updateLoaderTo(false);
        reject(error);
      });
    });
  },

  //let's add account setup endpoint here in front end complete registration
  [COMPLETE_REGISTRATION]({ commit, dispatch, state }, { userData }) {
    return new Promise((resolve, reject) => {
      Api.put("accounts/setup", {
        account_name: userData.account_name,
        type: userData.type,
        first_name: userData.first_name,
        middle_name: userData.middle_name,
        last_name: userData.last_name,
        birth_date: userData.birth_date,
        gender: userData.gender,
        phone: userData.phone,
        photo: userData.photo,
        ip_address: userData.ip_address,
        country_code: userData.country_code,
        state_or_province: userData.state_or_province,
        city: userData.city,
        postal_code: userData.postal_code,
        address: userData.address,
        // organization
        org_name: userData.org_name,
        org_size: userData.org_size,
        org_vat_number: userData.org_vat_number,
        org_registration_number: userData.org_registration_number,
        org_director_name: userData.org_director_name,
        org_email: userData.org_email,
        org_logo: userData.org_logo,
        org_industry_id: userData.org_industry_id,
        org_website_url: userData.org_website_url,
        org_phone: userData.org_phone,
        org_address: userData.org_address,
        // address_title: "Home Address",
      })
        .then(response => {
          updateLoaderTo(false);
          dispatch(REFRESHTOKEN, {
            refresh_token: state.user.refresh_token,
          })
            .then(res => {
              router.push("/dashboard");
            })
            .catch(err => {});
          resolve(response);
        })
        .catch(error => {
          updateLoaderTo(false);
          reject(error);
        });
    });
  },

  [EDIT_USER_DETAILS]({ commit }) {
    commit(UPDATE_USER_DETAILS);
  },
  [EDIT_USER_SETTINGS](
    { commit, dispatch },
    { systemName, currencyRegionName, useDarkMode },
  ) {
    return Api.put("settings", {
      systemName,
      currencyRegionName,
      useDarkMode,
    }).then(response => {
      commit(UPDATE_USER_SETTINGS, response.data);
      dispatch(
        `alert/${ADD_ALERT}`,
        { message: "Settings updaded successfully", color: "success" },
        { root: true },
      );
    });
  },
  // update user account data from '/profile' route
  [EDIT_USER_PROFILE]({ commit, dispatch }, { userData }) {
    return new Promise((resolve, reject) => {
      Api.put("users/me", {
        first_name: userData.first_name,
        middle_name: userData.middle_name,
        last_name: userData.last_name,
        birth_date: userData.birth_date,
        gender: userData.gender,
        phone: userData.phone,
        photo: userData.photo,
        country_code: userData.country_code,
        state_or_province: userData.state_or_province,
        city: userData.city,
        postal_code: userData.postal_code,
        address: userData.address,
        photo_id_front: userData.photo_id_front,
        photo_id_back: userData.photo_id_back,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // upload id
  [UPLOAD_USER_ID]({ commit, dispatch }, { userData }) {
    return new Promise((resolve, reject) => {
      Api.post("users/identities", {
        photo_id_front: userData.photo_id_front,
        photo_id_back: userData.photo_id_back,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // update user account data from '/settings' route
  [EDIT_ACCOUNT_SETTINGS]({ commit, dispatch }, { userData }) {
    return new Promise((resolve, reject) => {
      Api.put("accounts", {
        account_name: userData.account_name,
        type: userData.type,
        org_name: userData.org_name,
        org_size: userData.org_size,
        org_email: userData.org_email,
        org_phone: userData.org_phone,
        org_logo: userData.org_logo,
        org_industry_id: userData.org_industry_id,
        org_website_url: userData.org_website_url,
        org_vat_number: userData.org_vat_number,
        org_registration_number: userData.org_registration_number,
        org_director_name: userData.org_director_name,
        org_address: userData.org_address,
      })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // Load all the account settings data
  [LOAD_ACCOUNT]({ commit }) {
    return new Promise((resolve, reject) => {
      Api.get("accounts")
        .then(response => {
          commit(SET_ACCOUNT, response.data);
          resolve(response);
        })
        .catch(error => {
          commit(SET_ACCOUNT, {});
          reject(error);
        });
    });
  },

  [LOAD_CURRENCIES]({ commit }) {
    Api.get("currencies").then(response => {
      commit(SET_CURRENCIES, response.data);
    });
  },
  [LOAD_ORGANIZATION]({ commit }) {
    return new Promise((resolve, reject) => {
      Api.get("users/me")
        .then(response => {
          commit(SET_ORGANIZATION, response.data);
          resolve(response);
        })
        .catch(error => {
          commit(SET_ORGANIZATION, {});
          reject(error);
        });
    });
  },
  [SEND_PASSWORD_RESET_LINK](
    { commit, dispatch },
    { email, reset_page_route, recaptcha_token, clientIP },
  ) {
    commit(CLEAR_ERROR);
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(
        `users/passwords/${email}/forgot`,
        {
          reset_page_route: reset_page_route,
          recaptcha_token: recaptcha_token,
        },
        {
          headers: {
            "X-Captcha-Token": recaptcha_token,
            "X-Client-IP": clientIP,
          },
        },
      )
        .then(
          response => {
            updateLoaderTo(false);
            dispatch(
              `alert/${ADD_ALERT}`,
              { message: response.data.message, color: "success" },
              { root: true },
            );
            resolve(response);
          },
          error => {
            updateLoaderTo(false);
            reject(error);
          },
        )
        .catch(error => {});
    });
  },
  [RESEND_VERIFICATION_EMAIL](
    { commit, dispatch },
    { email, recaptcha_token, clientIP },
  ) {
    commit(CLEAR_ERROR);
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(
        `users/resend-verification-email`,
        {
          email: email,
        },
        {
          headers: {
            "X-Captcha-Token": recaptcha_token,
            "X-Client-IP": clientIP,
          },
        },
      )
        .then(
          response => {
            updateLoaderTo(false);
            dispatch(
              `alert/${ADD_ALERT}`,
              { message: response.data.message, color: "success" },
              { root: true },
            );
            resolve(response);
          },
          error => {
            updateLoaderTo(false);
            reject(error);
          },
        )
        .catch(error => {});
    });
  },
  [VERIFY_RESET_PASSWORD_LINK]({ commit }, { token }) {
    updateLoaderTo(true);
    commit(SET_RESET_PASSWORD_USER_DETAILS, {});
    return new Promise((resolve, reject) => {
      Api.get(`users/passwords/${token}/reset`).then(
        response => {
          updateLoaderTo(false);
          if (response.data) {
            commit(SET_RESET_PASSWORD_USER_DETAILS, response.data);
          }
          resolve(response);
        },
        error => {
          updateLoaderTo(false);
          reject(error);
        },
      );
    });
  },
  [RESET_PASSWORD](
    { commit, dispatch },
    { token, password, recaptcha_token, clientIP },
  ) {
    commit(CLEAR_ERROR);
    updateLoaderTo(true);
    return new Promise((resolve, reject) => {
      Api.post(
        `users/passwords/${token}/reset`,
        {
          password: password,
        },
        {
          headers: {
            "X-Captcha-Token": recaptcha_token,
            "X-Client-IP": clientIP,
          },
        },
      )
        .then(
          response => {
            updateLoaderTo(false);
            dispatch(
              `alert/${ADD_ALERT}`,
              { message: response.data.message, color: "success" },
              { root: true },
            );
            resolve(response);
            router.push({ name: "login" });
          },
          error => {
            updateLoaderTo(false);
            reject(error);
          },
        )
        .catch(error => {});
    });
  },
  [CHANGE_USER_PASSWORD]({ commit, dispatch }, { old_password, new_password }) {
    return new Promise((resolve, reject) => {
      Api.put(`users/passwords`, {
        old_password: old_password,
        new_password: new_password,
      }).then(
        response => {
          dispatch(
            `alert/${ADD_ALERT}`,
            { message: response.data.message, color: "success" },
            { root: true },
          );
          commit(LOGOUT_USER);
          router.push("/login");
          resolve(response);
        },
        error => {
          reject(error);
        },
      );
    });
  },
};

const mutations = {
  [LOGIN_SUCCESS](state, user) {
    state.user = user;
    // login successful if there's a jwt token in the response
    if (user.access_token) {
      // store user details and jwt token in local storage
      setCurrentUser(user);
    }
  },

  [SWITCH_SUCCESS](state, user) {
    if (user.access_token) {
      setCurrentUser(user);
    }
    state.user = user;
  },

  [LOGIN_FAILURE](state) {
    state.user = null;
    // remove user from local storage
    deleteCurrentUser();
  },
  [LOGOUT_USER](state) {
    deleteCurrentUser();
    // state.user = null;
    // Reset the state by replacing its properties with the default state
    Object.assign(state, {
      user: null,
      currencies: [],
      organization: {},
      login_error: null,
      signup_error: null,
      resetPasswordUserDetails: {},
      account: {},
    });
    //TODO - useless
    // this.reset();

  },
  [UPDATE_USER_DETAILS](state) {
    state.user.useDarkMode = !state.user.useDarkMode;
  },
  [UPDATE_USER_SETTINGS](
    state,
    { systemName, currencyRegionName, useDarkMode, theme, displayCurrency },
  ) {
    state.user.systemName = systemName;
    state.user.useDarkMode = useDarkMode;
    state.user.theme = theme;
    state.user.currencyRegionName = currencyRegionName;
    state.user.displayCurrency = displayCurrency;
  },
  [UPDATE_USER_PROFILE](state, { firstName, lastName, fullName }) {
    state.user.firstName = firstName;
    state.user.lastName = lastName;
    state.user.fullName = fullName;
  },
  [SET_CURRENCIES](state, currencies) {
    state.currencies = currencies;
  },
  [SET_ORGANIZATION](state, organization) {
    state.organization = organization;
  },
  [SET_LOGIN_ERROR](state, login_error) {
    state.login_error = login_error;
    state.signup_error = null;
  },
  [SET_SIGNUP_ERROR](state, payload) {
    state.login_error = null;
    state.signup_error = payload;
  },
  [CLEAR_ERROR](state) {
    state.login_error = null;
    state.signup_error = null;
  },
  [SET_RESET_PASSWORD_USER_DETAILS](state, resetPasswordUserDetails) {
    state.resetPasswordUserDetails = resetPasswordUserDetails;
  },
  [SET_ACCOUNT](state, account) {
    state.account = account;
  },
};

const getters = {
  currentUser: state => state.user,
  userProfile: state => {
    return state.organization;
  },
  nameInitials: state => {
    var initials = state.user.fullName.match(/\b\w/g) || [];
    return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
  },
  // nameFirstCharacter: (state) => {
  //   if(state.organization.status === "created") {
  //     return state.account.account_name ? state.account.account_name.charAt(0).toUpperCase():"H";
  //   }else if (state.organization.status === "active") {
  //     return state.organization.first_name ? state.organization.first_name.charAt(0).toUpperCase():"H";
  nameFirstCharacter: state => {
    if (state.organization.status === "created") {
      return (
        state.account.account_name &&
        state.account.account_name.charAt(0).toUpperCase()
      );
    } else if (state.organization.status === "active") {
      return (
        state.organization.first_name &&
        state.organization.first_name.charAt(0).toUpperCase()
      );
    }
    return "H";
  },
};

export let updateLoaderTo = loading => {
  store.dispatch(
    `loader/${TOGGLE_LOADING}`,
    { loading: loading },
    { root: true },
  );
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
