<template>
  <v-container>
    <template v-if="setupStateLoading">
      <Loader class="mr-2 mt-6" />
    </template>
    <template v-else>
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">
            {{ $vuetify.lang.t("$vuetify.embeddingModel.embedding_model_title") }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">
            {{ `${$vuetify.lang.t("$vuetify.maira_setup.profile_menu_txt")}` }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 3" step="3">
            {{ `${$vuetify.lang.t("$vuetify.maira_setup.upload_dataset")}` }}
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step step="4">
            {{ `${$vuetify.lang.t("$vuetify.maira_setup.dataset_training")}` }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <EmbeddedModel
            ref="embeddedModel"
            mode="create"
            :model="embeddingModel"
            />
            <v-row>
              <v-col cols="12" md="8"> </v-col>
              <v-col cols="12" md="4" class="text-right">
                <v-btn
                  v-if="$refs.embeddedModel && $refs.embeddedModel.selectedModel"
                  class="mr-4 primary--text"
                  outlined
                  @click="skip(step)"
                >
                  Skip Update >>
                </v-btn>
                <v-btn
                  :loading="loading"
                  color="primary"
                  class="white--text"
                  @click="nextStep(step)"
                >
                  {{ $vuetify.lang.t("$vuetify.common.continue_btn") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2">
            <CreateGptProfile ref="profile" />
            <v-col class="d-flex justify-space-between">
              <v-btn
                class="mr-4 primary--text"
                outlined
                :loading="false"
                @click="previousStep(step)"
              >
                {{ $vuetify.lang.t("$vuetify.common.previous") }}
              </v-btn>
              <div>
                <v-btn
                  v-if="gptProfiles.length > 0"
                  class="mr-4 primary--text"
                  outlined
                  @click="skip(step)"
                >
                  Skip Update >>
                </v-btn>
                <v-btn
                  :loading="loading"
                  color="primary"
                  class="white--text"
                  @click="nextStep(step)"
                >
                  {{ $vuetify.lang.t("$vuetify.common.continue_btn") }}
                </v-btn>
              </div>
            </v-col>
          </v-stepper-content>

          <v-stepper-content step="3">
            <UploadDataset ref="dataset" propName="Test props" />
            <v-col class="d-flex justify-space-between">
              <v-btn
                class="mr-4 primary--text"
                outlined
                :loading="false"
                @click="previousStep(step)"
              >
                {{ $vuetify.lang.t("$vuetify.common.prev_btn") }}
              </v-btn>
              <div>
                <v-btn
                  outlined
                  v-if="gptSetupState.dataset.completed"
                  class="mr-4 primary--text"
                  @click="skip(step)"
                >
                  Skip Update >>
                </v-btn>
                <v-btn
                  class="mr-4"
                  :loading="datasetCreateLoader"
                  @click="nextStep(step)"
                  color="primary"
                >
                  {{ $vuetify.lang.t("$vuetify.common.continue_btn") }}
                </v-btn>
              </div>
            </v-col>
          </v-stepper-content>

          <v-stepper-content step="4">
            <div v-if="datasetFileUploadInProgress">
              <div
                style="min-height: 200px"
                class="d-flex justify-center align-center"
              >
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-progress-circular indeterminate color="primary">
                      </v-progress-circular>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          $vuetify.lang.t(
                            "$vuetify.gpt_dataset.dataset_process_progress",
                          )
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </div>
            </div>
            <v-card
              v-else-if="gptSetupState.dataset.error"
              class="pa-2 d-flex flex-column align-center justify-center"
              height="200px"
            >
              <v-col class="d-flex align-center justify-center" cols="auto">
                <v-icon color="red">mdi-close-circle-outline</v-icon>
                <v-list-item-title class="ml-2">
                  {{ gptSetupState.dataset.message }}
                </v-list-item-title>
              </v-col>
              <v-btn
                class="mt-1 primary--text"
                outlined
                :loading="false"
                @click="datasetErrorTryAgain"
              >
                {{ $vuetify.lang.t("$vuetify.common.upload_again") }}
              </v-btn>
            </v-card>
            <!-- training error -->
            <v-card
              v-else-if="gptSetupState.training.error"
              class="pa-2 d-flex flex-column align-center justify-center"
              height="200px"
            >
              <v-col class="d-flex align-center justify-center" cols="auto">
                <v-icon color="red">mdi-close-circle-outline</v-icon>
                <v-list-item-title class="ml-2">
                  {{ gptSetupState.training.message }}
                </v-list-item-title>
              </v-col>
              <v-btn
                class="mr-4 mt-1 primary--text"
                outlined
                :loading="false"
                @click="trainingErrorTryAgain"
              >
                {{ $vuetify.lang.t("$vuetify.common.try_again") }}
              </v-btn>
            </v-card>

            <div v-else-if="dataSetProcessCompleted">
              <v-card
                v-if="trainingInProgress"
                height="200px"
                class="d-flex justify-center align-center"
              >
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-progress-circular indeterminate color="primary">
                      </v-progress-circular>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $vuetify.lang.t("$vuetify.gpt_dataset.training_in_progress") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-card>
              <v-form ref="train_form" v-else>
                <v-card
                  height="200px"
                  style="box-shadow: none"
                  class="d-flex justify-center align-center"
                >
                  <v-col cols="12" md="6">
                    <v-select
                      :label="
                        $vuetify.lang.t('$vuetify.gpt_dataset.training_type')
                      "
                      :items="
                              [
                                {
                                  text: this.$vuetify.lang.t('$vuetify.gpt_dataset.text_training_type'),
                                  value: 'text',
                                },
                                {
                                  text: this.$vuetify.lang.t('$vuetify.gpt_dataset.image_training_type'),
                                  value: 'image',
                                },
                              ]"
                      v-model="trainType"
                      outlined
                      :rules="[
                        required(
                          $vuetify.lang.t('$vuetify.gpt_dataset.training_type'),
                        ),
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model="batchSize"
                      :label="
                        $vuetify.lang.t('$vuetify.gpt_dataset.batch_size')
                      "
                      outlined
                      type="number"
                      :rules="batchSizeRules"
                      disabled
                    ></v-text-field>
                  </v-col>
                </v-card>
              </v-form>

              <div
                v-if="!trainingInProgress"
                class="d-flex justify-space-between"
              >
                <v-btn
                  class="mr-4 primary--text"
                  outlined
                  :loading="false"
                  @click="previousStep(step)"
                >
                  {{ $vuetify.lang.t("$vuetify.common.prev_btn") }}
                </v-btn>
                <v-btn
                  @click="handleTraining"
                  class="mr-4 pr-8 pl-8"
                  :loading="loading"
                  color="primary"
                >
                  {{ $vuetify.lang.t("$vuetify.maira_setup.dataset_training") }}
                </v-btn>
              </div>
            </div>
            <template v-else>
              <v-card
                style="box-shadow: none;"
                height="200px"
                class="d-flex justify-center align-center"
              >
                <v-col cols="6">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-progress-circular indeterminate color="primary">
                      </v-progress-circular>
                    </v-list-item-icon>

                    <v-list-item-content>
                      {{
                        $vuetify.lang.t(
                          "$vuetify.gpt_dataset.dataset_process_progress"
                        )
                      }}
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-card>
              <v-btn
                class="mr-4 primary--text"
                outlined
                :loading="false"
                @click="previousStep(step)"
              >
                {{ $vuetify.lang.t("$vuetify.common.prev_btn") }}
              </v-btn>
            </template>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EmbeddedModel from "@/components/Projects/EmbeddedModel/EmbeddedModel";
import UploadDataset from "@/components/Projects/UploadDataset/UploadDataset";
import CreateGptProfile from "@/components/Projects/CreateGptProfile/CreateGptProfile";
import Loader from "@/components/common/loader/Loader";
import {
  GET_GPT_PROFILE_BY_ID,
  CREATE_GPT_PROFILE,
  UPDATE_GPT_PROFILE_STATE,
  UPDATE_GPT_PROFILE_API,
  ADD_ALERT,
  GET_GPT_DATASET_BY_ID,
  CREATE_GPT_DATASET,
  UPDATE_GPT_DATASET_API,
  TRAIN_GPT_DATASET,
  UPDATE_GPT_DATASET_FILE,
  CREATE_GPT_SETTINGS,
  UPDATE_GPT_SETUP_STATE,
  GET_TASK_STATUS,
  UPDATE_GPT_DATASET_STATE,
  DB_STORE_TASKS_STATUS,
  DB_GET_TASKS_STATUS,
  CREATE_SETTINGS
} from "@/store/_actiontypes";
import { GptProfileMapper } from "@/helpers/dataMappers/gptProfile";
import { GptDatasetMapper } from "@/helpers/dataMappers/gptDataset";
import getEnv from "@/config/env";


export default {
  components: {
    UploadDataset,
    CreateGptProfile,
    Loader,
    EmbeddedModel
  },
  data() {
    return {
      loading: false,
      step: 1,
      datasetFileUploadInProgress: false,
      trainingInProgress: false,
      trainType: "",
      batchSize: 1,
      dataSetProcessCompleted: false,
      intervalId: null,
      datasetCreateLoader: false,
      setupStateLoading: false,
      batchSizeRules: [
        value => value >= 1 || "Min value is 1",
        value => value <= 10 || "Max value is 10",
      ],
      embeddingModel: [
        "text-embedding-ada-002",
        "text-embedding-3-small",
      ],
      selectedModel: '',
    };
  },
  computed: {
    ...mapState({
      gptProfiles: (state) => state.project.gptProfiles?.profiles || [],
      selectedGptProfile: (state) => state.project.selectedGptProfile,
      gptDatasets: (state) => state.project.gptDatasets.datasets,
      selectedGptDataset: (state) => state.project.selectedGptDataset,
      profileExist: (state) => (state.project.gptProfiles?.profiles || []).length > 0,
      datasetExist: (state) => state.project.gptSetupState.dataset.completed,
      gptSetupState: (state) => state.project.gptSetupState,
      selectedProject: (state) => state.project.selectedProject,
    }),
  },
  watch: {
    gptSetupState: {
      handler(setupState) {
        //for dataset task
        if (
          setupState.dataset?.task_id &&
          !this.intervalId &&
          !setupState.dataset?.error
        ) {
          this.datasetFileUploadInProgress = true;
          this.startInterval(setupState.dataset?.task_id, "dataset");
        }
        //for training task
        if (
          setupState.training?.task_id &&
          !this.intervalId &&
          !setupState.training?.error
        ) {
          this.startInterval(setupState.training?.task_id, "train");
          this.trainingInProgress = true;
        }

        if (setupState.dataset?.completed) {
          this.dataSetProcessCompleted = true;
        }
        if (setupState.training?.completed) {
          this.trainingInProgress = false;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.loadGptSetupInitialData();
    if (this.selectedModel) {
      this.$nextTick(() => {
        if (this.$refs.embeddedModel) {
          this.$refs.embeddedModel.selectedModel = this.selectedModel;
        }
      });
    }
  },

  beforeDestroy() {
    this.stopInterval();
    this.resetGptProfile();
    this.resetGptDataset();
  },

  methods: {
    ...mapActions("project", [
      GET_GPT_PROFILE_BY_ID,
      CREATE_GPT_PROFILE,
      UPDATE_GPT_PROFILE_STATE,
      UPDATE_GPT_PROFILE_API,
      GET_GPT_DATASET_BY_ID,
      CREATE_GPT_DATASET,
      UPDATE_GPT_DATASET_API,
      UPDATE_GPT_DATASET_FILE,
      TRAIN_GPT_DATASET,
      CREATE_GPT_SETTINGS,
      UPDATE_GPT_SETUP_STATE,
      UPDATE_GPT_DATASET_STATE,
      DB_STORE_TASKS_STATUS,
      CREATE_SETTINGS
    ]),
    ...mapActions("task", [GET_TASK_STATUS]),
    stopInterval() {
      clearInterval(this.intervalId);
      this.intervalId = null;
    },
    resetGptProfile() {
      this.UPDATE_GPT_PROFILE_STATE({
        profile: GptProfileMapper.mapBackendToFrontend(),
      });
    },
    loadGptSetupInitialData() {
      if (this.gptProfiles.length > 0) {
        this.step = 2;
        this.setupStateLoading = true;
        this.GET_GPT_PROFILE_BY_ID({
          project_id: this.$route.params.project_id,
          profile_id: this.gptProfiles[0]?.id,
        })
          .then(
            _res => {
              this.step = 2;
              if (this.gptSetupState.dataset.error) {
                this.step = 3;
                this.setupStateLoading = false;
              }

              if (this.gptDatasets.length > 0) {
                this.GET_GPT_DATASET_BY_ID({
                  project_id: this.$route.params.project_id,
                  dataset_id: this.gptDatasets[0]?.dataset_id,
                })
                  .then(
                    _res => {
                      this.step = 3;
                    },
                    _err => {
                      this.$store.dispatch(
                        `alert/${ADD_ALERT}`,
                        {
                          message: this.$vuetify.lang.t(
                            "$vuetify.gpt_dataset.dataset_fetch_error",
                          ),
                          color: "error",
                        },
                        { root: true },
                      );
                    },
                  )
                  .finally(() => {
                    this.setupStateLoading = false;
                  });
              } else {
                this.setupStateLoading = false;
                this.resetGptDataset();
              }
            },
            _err => {
              this.setupStateLoading = false;
              this.$store.dispatch(
                `alert/${ADD_ALERT}`,
                {
                  message: this.$vuetify.lang.t(
                    "$vuetify.gpt_profile.profile_fetch_error",
                  ),
                  color: "error",
                },
                { root: true },
              );
            },
          )
          .finally(() => {});
      } else {
        this.setupStateLoading = false;
      }
    },
    resetGptDataset() {
      this.UPDATE_GPT_DATASET_STATE({
        dataset: GptDatasetMapper.mapBackendToFrontend({
          dataset_id: "",
          description: "",
          idx_column_name: "",
          image_url_column: "",
          is_idx_fillup_if_empty: true,
          name: "",
          secondary_idx_column: "",
        }),
      });
    },
    startInterval(task_id, type) {
      this.intervalId = setInterval(() => {
        if (type === "dataset") {
          this.GET_TASK_STATUS({
            project_id: this.selectedProject.id,
            task_id: task_id,
          })
            .then(res => {
              if (res.data.detail?.response?.status === "success") {
                this.$store.dispatch(
                  `alert/${ADD_ALERT}`,
                  {
                    message: "Dataset Processing Successful",
                    color: "success",
                  },
                  { root: true },
                );
                this.stopInterval();
                this.dataSetProcessCompleted = true;
                this.datasetFileUploadInProgress = false;
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  dataset: {
                    ...this.gptSetupState.dataset,
                    completed: true,
                    dataset_id: this.gptSetupState.dataset.dataset_id,
                    task_id: null,
                    error: false,
                  },
                });
                this.DB_STORE_TASKS_STATUS({
                  project_id: this.selectedProject.id,
                  tasks_status: {
                    ...this.gptSetupState,
                    dataset: {
                      ...this.gptSetupState.dataset,
                      completed: true,
                      dataset_id: this.gptSetupState.dataset.dataset_id,
                      task_id: null,
                      error: false,
                    },
                  },
                })
                  .then(_response => {})
                  .catch(error => {
                    console.error(error.response);
                  });
              } else if (
                res.data?.detail?.response?.status !== "pending" &&
                res.data?.detail?.response?.status !== "claimable"
              ) {
                this.stopInterval();
                this.datasetFileUploadInProgress = false;
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  dataset: {
                    ...this.gptSetupState.dataset,
                    status: res.data.detail?.response.status,
                    message: res.data.detail?.response?.message,
                    error: true,
                  },
                });
                this.DB_STORE_TASKS_STATUS({
                  project_id: this.selectedProject.id,
                  tasks_status: {
                    ...this.gptSetupState,
                    dataset: {
                      ...this.gptSetupState.dataset,
                      status: res.data.detail?.response.status,
                      message: res.data.detail?.response?.message,
                      error: true,
                    },
                  },
                })
                  .then(_response => {})
                  .catch(error => {
                    console.error(error.response);
                  });
              } else {
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  dataset: {
                    ...this.gptSetupState.dataset,
                    status: res.data.detail.response.status,
                    error: false,
                  },
                });
                this.DB_STORE_TASKS_STATUS({
                  project_id: this.selectedProject.id,
                  tasks_status: {
                    ...this.gptSetupState,
                    dataset: {
                      ...this.gptSetupState.dataset,
                      status: res.data.detail.response.status,
                      error: false,
                    },
                  },
                })
                  .then(_response => {})
                  .catch(error => {
                    console.error(error.response);
                  });
              }
            })
            .catch(err => {
              console.error("error", err);
              this.UPDATE_GPT_SETUP_STATE({
                ...this.gptSetupState,
                dataset: {
                  ...this.gptSetupState.dataset,
                  status: "failed",
                  message: err?.response?.data?.detail?.error,
                  error: true,
                },
              });
              this.datasetFileUploadInProgress = false;
              this.stopInterval();
            });
        }
        if (type === "train") {
          this.GET_TASK_STATUS({
            project_id: this.selectedProject.id,
            task_id: task_id,
          })
            .then(res => {
              if (res.data.detail.response.status === "success") {
                this.stopInterval();
                this.trainingInProgress = false;
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  // completed: true,
                  training: {
                    ...this.gptSetupState.training,
                    completed: true,
                    task_id: null,
                    status: "success",
                    error: false,
                  },
                });
                this.DB_STORE_TASKS_STATUS({
                  project_id: this.selectedProject.id,
                  tasks_status: {
                    ...this.gptSetupState,
                    completed: true,
                    training: {
                      ...this.gptSetupState.training,
                      completed: true,
                      task_id: null,
                      status: "success",
                      error: false,
                    },
                  },
                })
                  .then(_response => {})
                  .catch(error => {
                    console.error(error.response);
                  });
              } else if (
                res.data.detail.response.status !== "pending" &&
                res.data?.detail?.response?.status !== "claimable"
              ) {
                this.stopInterval();
                this.trainingInProgress = false;
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  training: {
                    ...this.gptSetupState.training,
                    status: res.data.detail.response.status,
                    message: res.data?.detail?.response?.message,
                    error: true,
                  },
                });
                this.DB_STORE_TASKS_STATUS({
                  project_id: this.selectedProject.id,
                  tasks_status: {
                    ...this.gptSetupState,
                    training: {
                      ...this.gptSetupState.training,
                      status: res.data.detail.response.status,
                      message: res.data.detail?.response?.message,
                      error: true,
                    },
                  },
                })
                  .then(_response => {})
                  .catch(error => {
                    console.error(error.response);
                  });
                // updated training state
              } else {
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  training: {
                    ...this.gptSetupState.training,
                    status: res.data.detail.response.status,
                    error: false,
                  },
                });
                this.DB_STORE_TASKS_STATUS({
                  project_id: this.selectedProject.id,
                  tasks_status: {
                    ...this.gptSetupState,
                    training: {
                      ...this.gptSetupState.training,
                      status: res.data.detail.response.status,
                      error: false,
                    },
                  },
                })
                  .then(_response => {})
                  .catch(error => {
                    console.error(error.response);
                  });
              }
            })
            .catch(err => {
              console.error("error", err);
            });
        }
      }, getEnv("VUE_APP_POLLING_INTERVAL"));
    },
    trainingErrorTryAgain() {
      this.UPDATE_GPT_SETUP_STATE({
        ...this.gptSetupState,
        training: {
          ...this.gptSetupState.training,
          task_id: null,
          status: null,
          message: null,
          error: false,
        },
      });
      this.DB_STORE_TASKS_STATUS({
        project_id: this.selectedProject.id,
        tasks_status: {
          ...this.gptSetupState,
          training: {
            ...this.gptSetupState.training,
            task_id: null,
            status: null,
            message: null,
            error: false,
          },
        },
      })
        .then(_response => {})
        .catch(error => {
          console.error(error.response);
        });
    },
    datasetErrorTryAgain() {
      this.step = 2;
      this.UPDATE_GPT_SETUP_STATE({
        ...this.gptSetupState,
        dataset: {
          ...this.gptSetupState.dataset,
          task_id: null,
          status: null,
          message: null,
          error: false,
        },
      });
      this.DB_STORE_TASKS_STATUS({
        project_id: this.selectedProject.id,
        tasks_status: {
          ...this.gptSetupState,
          dataset: {
            ...this.gptSetupState.dataset,
            task_id: null,
            status: null,
            message: null,
            error: false,
          },
        },
      })
        .then(_response => {})
        .catch(error => {
          console.error(error.response);
        });
    },

    previousStep(step) {
      this.step = step - 1;
      if (this.step < 1) this.step = 1;
    },
    required(fieldName) {
      return (value) => {
        return !!value || `${fieldName}${this.$vuetify.lang.t('$vuetify.validation.required_error_msg_txt')}`;
      };
    },
    skip(step) {
      this.step = step + 1;
    },
    nextStep(step) {
      if (step === 1) {
      // Validate embedding model selection
      const embeddingModelRef = this.$refs.embeddedModel;
      const isValid = embeddingModelRef.$refs.embeddingModelForm.validate();

      if (!isValid) return;

      this.loading = true;
      const settingsPayload = {
        general: {
          embedding_model: embeddingModelRef.selectedModel
        }
      };

      this.CREATE_SETTINGS({
        project_id: this.$route.params.project_id,
        embedded_models: settingsPayload,
      })
        .then(
          res => {
            this.$store.dispatch(`alert/${ADD_ALERT}`, {
              message: this.$vuetify.lang.t("$vuetify.embeddingModel.embedding_model_creation_success"),
              color: "success"
            }, { root: true });

            // Move to next step
            this.step = step + 1;
          },
          err => {
            this.$store.dispatch(`alert/${ADD_ALERT}`, {
              message: err.response?.data?.detail ||
                      this.$vuetify.lang.t("$vuetify.embeddingModel.embedding_model_creation_error"),
              color: "error"
            }, { root: true });
          }
        )
        .finally(() => {
          this.loading = false;
        });
      }
      else if (step === 2) {
        const valid = this.$refs.profile.validateForm();
        if (!valid) return;
        if (!this.profileExist) {
          this.loading = true;
          setTimeout(() => {
            this.CREATE_GPT_PROFILE({
              project_id: this.$route.params.project_id,
              profile: this.selectedGptProfile,
            })
              .then(
                res => {
                  this.step = step + 1;
                  const profileData = {
                    ...this.gptSetupState,
                    gpt_profile: {
                      completed: true,
                      profile_id: res.data.detail?.profile_id,
                    },
                  };
                  this.UPDATE_GPT_SETUP_STATE(profileData);
                  this.DB_STORE_TASKS_STATUS({
                    project_id: this.selectedProject.id,
                    tasks_status: profileData,
                  })
                    .then(_response => {})
                    .catch(error => {
                      console.error(error.response);
                    });
                  this.$store.dispatch(
                    `alert/${ADD_ALERT}`,
                    {
                      message: this.$vuetify.lang.t(
                        "$vuetify.gpt_profile.profile_creation_success",
                      ),
                      color: "success",
                    },
                    { root: true },
                  );
                  // set default profile, don't remove
                  this.CREATE_GPT_SETTINGS({
                    project_id: this.$route.params.project_id,
                    default_profile: res.data?.detail?.profile_id,
                  })
                    .then(_res => {})
                    .catch(err => {
                      console.error("default profile creation error", err);
                    });
                },
                err => {
                  console.error("Err ", err);
                  this.$store.dispatch(
                    `alert/${ADD_ALERT}`,
                    {
                      message:
                        err.response?.data?.detail?.response ||
                        this.$vuetify.lang.t(
                          "$vuetify.gpt_profile.profile_creation_error",
                        ),
                      color: "error",
                    },
                    { root: true },
                  );
                },
              )
              .finally(() => {
                this.loading = false;
              });
          }, 100);
        } else {
          this.loading = true;
          //adding setTimeout to add unconfirmed chip to be added to the list
          setTimeout(() => {
            this.UPDATE_GPT_PROFILE_API({
              project_id: this.$route.params.project_id,
              profile: this.selectedGptProfile,
            })
              .then(
                _res => {
                  this.step = step + 1;
                  this.$store.dispatch(
                    `alert/${ADD_ALERT}`,
                    {
                      message: this.$vuetify.lang.t(
                        "$vuetify.gpt_profile.profile_update_success",
                      ),
                      color: "success",
                    },
                    { root: true },
                  );
                },
                _err => {
                  this.step = step + 1;
                  this.$store.dispatch(
                    `alert/${ADD_ALERT}`,
                    {
                      message: this.$vuetify.lang.t(
                        "$vuetify.gpt_profile.profile_update_error",
                      ),
                      color: "error",
                    },
                    { root: true },
                  );
                },
              )
              .finally(() => {
                this.loading = false;
              });
          });
        }
      } else if (step === 3) {
        const valid = this.$refs.dataset.validateForm();
        if (!valid) return;
        this.datasetCreateLoader = true;
        if (!this.datasetExist) {
          this.loading = true;
          this.CREATE_GPT_DATASET({
            project_id: this.$route.params.project_id,
            dataset: this.selectedGptDataset,
          })
            .then(
              res => {
                this.UPDATE_GPT_SETUP_STATE({
                  ...this.gptSetupState,
                  dataset: {
                    ...this.gptSetupState.dataset,
                    dataset_id: res.data.detail?.dataset_id,
                  },
                });
                this.UPDATE_GPT_DATASET_FILE({
                  project_id: this.$route.params.project_id,
                  datasetId: this.selectedGptDataset.datasetId,
                  datasetFile: this.selectedGptDataset.datasetFile,
                })
                  .then(res => {
                    const datasetData = {
                      ...this.gptSetupState.dataset,
                      task_id: res.data.detail?.task_id,
                    };
                    this.UPDATE_GPT_SETUP_STATE({
                      ...this.gptSetupState,
                      dataset: datasetData,
                    });
                    this.DB_STORE_TASKS_STATUS({
                      project_id: this.selectedProject.id,
                      tasks_status: {
                        ...this.gptSetupState,
                        dataset: datasetData,
                      },
                    })
                      .then(_response => {})
                      .catch(error => {
                        this.$store.dispatch(
                          `alert/${ADD_ALERT}`,
                          {
                            message: error.response,
                            color: "error",
                          },
                          { root: true },
                        );
                        console.error(error.response);
                      });
                    if (res.data.detail?.task_id) {
                      this.datasetFileUploadInProgress = true;
                    } else {
                      this.$store.dispatch(
                        `alert/${ADD_ALERT}`,
                        {
                          message: this.$vuetify.lang.t(
                            "$vuetify.gpt_dataset.dataset_submission_success",
                          ),
                          color: "success",
                        },
                        { root: true },
                      );
                    }
                    this.step = step + 1;
                  })
                  .catch(err => {
                    console.error("error", err);
                    this.$store.dispatch(
                      `alert/${ADD_ALERT}`,
                      {
                        message: this.$vuetify.lang.t(
                          "$vuetify.gpt_dataset.dataset_submission_error",
                        ),
                        color: "error",
                      },
                      { root: true },
                    );
                  })
                  .finally(() => {
                    this.datasetCreateLoader = false;
                  });
              },

              err => {
                this.datasetCreateLoader = false;
                console.error("dataset submission err", err);
                this.$store.dispatch(
                  `alert/${ADD_ALERT}`,
                  {
                    message: err.response?.message,
                    color: "error",
                  },
                  { root: true },
                );
              },
            )
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.loading = true;
          ///adding setTimeout to add unconfirmed chip to be added to the list
          setTimeout(() => {
            this.UPDATE_GPT_DATASET_API({
              project_id: this.$route.params.project_id,
              dataset: this.selectedGptDataset,
            })
              .then(
                res => {
                  const datasetData = {
                    ...this.gptSetupState.dataset,
                    task_id: res.data.detail?.task_id,
                  };
                  this.UPDATE_GPT_SETUP_STATE({
                    ...this.gptSetupState,
                    dataset: datasetData,
                  });
                  this.DB_STORE_TASKS_STATUS({
                    project_id: this.selectedProject.id,
                    tasks_status: {
                      ...this.gptSetupState,
                      dataset: datasetData,
                    },
                  })
                    .then(_response => {})
                    .catch(error => {
                      this.$store.dispatch(
                        `alert/${ADD_ALERT}`,
                        {
                          message: error.response,
                          color: "error",
                        },
                        { root: true },
                      );
                      console.error(error.response);
                    });
                  if (res.data.detail?.task_id) {
                    this.datasetFileUploadInProgress = true;
                  } else {
                    this.$store.dispatch(
                      `alert/${ADD_ALERT}`,
                      {
                        message: this.$vuetify.lang.t(
                          "$vuetify.gpt_dataset.dataset_update_success",
                        ),
                        color: "success",
                      },
                      { root: true },
                    );
                  }
                },
                _err => {
                  this.datasetCreateLoader = false;
                  this.$store.dispatch(
                    `alert/${ADD_ALERT}`,
                    {
                      message: this.$vuetify.lang.t(
                        "$vuetify.gpt_dataset.dataset_update_error",
                      ),
                      color: "error",
                    },
                    { root: true },
                  );
                },
              )
              .finally(() => {
                this.datasetCreateLoader = false;
                this.step = step + 1;
                this.loading = false;
              });
          }, 100);
        }
      }
      if (step === 4) this.step = 2;
    },
    handleTraining() {
      const valid = this.validateForm();
      if (!valid) return;
      this.loading = true;
      this.TRAIN_GPT_DATASET({
        project_id: this.$route.params.project_id,
        datasetId: this.selectedGptDataset.datasetId,
        trainType: this.trainType,
        batchSize: this.batchSize,
      })
        .then(
          res => {
            const trainingData = {
              ...this.gptSetupState.training,
              task_id: res.data?.detail?.task_id,
            };
            this.UPDATE_GPT_SETUP_STATE({
              ...this.gptSetupState,
              training: trainingData,
            });
            this.DB_STORE_TASKS_STATUS({
              project_id: this.selectedProject.id,
              tasks_status: {
                ...this.gptSetupState,
                training: trainingData,
              },
            })
              .then(_response => {})
              .catch(error => {
                console.error(error.response);
              });
            //res.data.detail.response <-- contains the train task id
            this.trainingInProgress = true;
          },
          err => {
            console.error("train err", err.response);
            this.$store.dispatch(
              `alert/${ADD_ALERT}`,
              {
                message:
                  err.response?.data?.detail?.response ||
                  "Dataset training error",
                color: "error",
              },
              { root: true },
            );
          },
        )
        .finally(() => {
          this.loading = false;
        });
    },
    validateForm() {
      return this.$refs.train_form.validate();
    },
  },
};
</script>

<style>
.v-stepper__header {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
