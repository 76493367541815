import getEnv from "@/config/env";

export const CAPTCHA_SITE_KEY = getEnv("VUE_APP_CAPTCHA_SITE_KEY");
export const NUMBER_FORMAT_LOCALE = "en-US";
export const CURRENCY = "USD";
export const IMAGE_KEYS = ['pictures', 'large_pictures', 'small_pictures'];
export const TEXT_KEYS = ['description', 'description_html', 'guide', 'short_description'];
export const SEGMENTS = {
  Maira: 'Maira',
  PERSONALIZER: 'Personalizer',
  CV: 'CV'
};

export const SEGMENTS_TAB_STORAGE_KEYS = {Maira : "savedMairaTab", PERSONALIZER : "savedPersonalizerTab",CV: "savedCVTab" }

export const GPT_SETUP_STEPS = ["gpt_profile", "dataset", "training"];

export const MAIRA_TABS = []
export const PERSONALIZER_TABS = ["dataUpdate"]

export const COMMON_TABS = ["settings", "insights", "details", "gptDatasets", "gptProfiles", "gptSetting"]
export const PROMPT_TEMPLATE_SEPARATORS_TOKEN_COUNT = 100

export const HISTORY_TYPES = {
  CONVERSATION: "conversation",
  QUESTIONNAIRE: "questionnaire"
}

export const GPT_PROFILE_INIT_SYSTEM = "You are an expert on COMPANY_NAME/PERSONA/TOPIC. BRIEF INTRODUCTION ABOUT THE COMPANY/PERSONA/TOPIC.";

export const GPT_PROFILE_INIT_DATA = {
  name: "",
  intro: "",
  system: GPT_PROFILE_INIT_SYSTEM,
  model: "",
  provider: "",
  temperature: 0,
  top_p: 1,
  frequency_penalty: 0,
  presence_penalty: 0,
  stop: [],
  search_max_token: 2500,
  completion_token: 2000,
  vision_settings: {
    resolution: "low",
    is_image_context_enabled: true,
  },
  id: undefined,
  dataset_tags: {
    includes: [],
    excludes: [],
  },
  chat_history_length: 0,
  is_personalizer_only: false,
  is_auto_evaluation: false,
};
