<template>
  <v-container>
    <v-form ref="embeddingModelForm">
      <v-select
        v-model="selectedModel"
        :items="modelOptions"
        :label="$vuetify.lang.t('$vuetify.embeddingModel.embedding_model_placeholder')"
        outlined
        :rules="[v => !!v || $vuetify.lang.t('$vuetify.embeddingModel.model_required')]"
      ></v-select>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_SETTINGS, UPDATE_SETTINGS } from "@/store/_actiontypes";

export default {
  props: {
    mode: {
      type: String,
      default: 'create',
      validator: value => ['create', 'update'].includes(value)
    },
    model: {
      type: Array,
      default: () => []
    },
    embeddedModelValue: {
      type: String,
      default: ''
    }
  },
  mounted() {
    if (this.model && this.model.length > 0) {
      this.modelPreviouslySelected = true;
      this.selectedModel = this.embeddedModelValue;
    }
  },
  data() {
    return {
      loading: false,
      selectedModel: '',
      modelOptions: [
        'text-embedding-ada-002', 
        'text-embedding-3-small'
      ],
      modelPreviouslySelected: false,

    };
  },
  methods: {
    ...mapActions('project', [CREATE_SETTINGS, UPDATE_SETTINGS]),
    
    updateEmbeddedModel() {
      const isValid = this.$refs.embeddingModelForm.validate();
      if (!isValid) return;

      this.loading = true;
      
      const payload = {
        general: {
          embedding_model: this.selectedModel
        }
      };

      this.UPDATE_SETTINGS({
        project_id: this.$route.params.project_id,
        embedded_models: payload
      })
        .then(response => {
          this.$store.dispatch('alert/ADD_ALERT', {
            message: 'Embedding Model Updated Successfully',
            color: 'success'
          });
          this.$emit('updated', this.selectedModel);
          this.loading = false;
        })
        .catch(error => {
          this.$store.dispatch('alert/ADD_ALERT', {
            message: 'Failed to Update Embedding Model',
            color: 'error'
          });
          this.loading = false;
        });
    }
  }
};
</script>