<template>
    <v-card class="elevation-0">
        <v-card-title class="text-h6 font-weight-bold">
          {{ $vuetify.lang.t('$vuetify.access_control.title') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-row class="mx-2 mb-3">
          <v-col cols="12" md="6">
            <v-card class="pa-2" flat>
              <strong>
                {{$vuetify.lang.t('$vuetify.access_control.assigned_members')}}
              </strong>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-row class="justify-end mt-1">
              <v-btn 
                @click="memberRemoveConfirmationModal = true" 
                class="mr-3" 
                color="primary" 
                :disabled="removeBtnDisable"
                >
                {{$vuetify.lang.t('$vuetify.common.remove')}} ({{ selectedProjectMembers.length }})
              </v-btn>
              <Button
                :buttonType="FLOATING_ACTION_BUTTON"
                iconName="mdi-plus"
                size="x-small"
                :buttonText="$vuetify.lang.t('$vuetify.access_control.assign_new_member')"
                color="primary"
                :disabled="assignedMembersLoading"
                @onClick="addNewProjectMember()"
              />
            </v-row>
          </v-col>
        </v-row>
        <Loader v-if="assignedMembersLoading" class="mr-2 mt-6" />
        <v-data-table
          v-else
          :items="filteredProjectMembers"
          v-model="selectedProjectMembers"
          class="elevation-1 mt-6"
          :headers="projectMembersHeaders"
          outlined
          :show-select="this.organization?.is_account_owner"
        >
          <template v-slot:[`item.user.first_name`]="{ item }">
            <span>{{ item.user.first_name }} {{ item.user.last_name }}</span>
          </template>
        </v-data-table>
        <v-dialog v-model="newProjectMemberDialog" width="80%">
          <v-card class="pa-8">
              <v-row>
                <v-col cols="6">
                  <div class="py-5 primary--text font-weight-bold">
                    {{$vuetify.lang.t('$vuetify.access_control.assign_to_project')}}
                  </div>
                </v-col>
                <v-col cols="6">
                  <v-row class="d-flex justify-end">
                    <v-btn @click="showMemberAssignmentConfirmationModal" class="mt-4 mr-3" color="primary" :disabled="assignBtnDisable">{{$vuetify.lang.t('$vuetify.access_control.assign')}} ({{ selectedMembers.length }})</v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <Loader v-if="isLoading" class="mr-2 mt-6" />
              <v-data-table
                v-else
                :items="selectableMembers"
                v-model="selectedMembers"
                class="elevation-1"
                :headers="headers"
                outlined
                show-select
              >
                <template v-slot:[`item.first_name`]="{ item }">
                  <span>{{ item.first_name }} {{ item.last_name }}</span>
                </template>
              </v-data-table>
          <v-row>
            <v-col cols="12" class="d-flex justify-end">
              <v-btn text @click="closeMembersAssignmentModal" color="error">
                {{$vuetify.lang.t('$vuetify.common.close_btn')}}
              </v-btn>
            </v-col>
          </v-row>
          </v-card>
        </v-dialog>
        <DialogModal
          v-model="memberConfirmationModal"
          :title="$vuetify.lang.t('$vuetify.common.confirm_alert_title_txt')"
          :message=confirmMessage
          :confirmButtonText="$vuetify.lang.t('$vuetify.common.confirm_btn')"
          :cancelButtonText="$vuetify.lang.t('$vuetify.common.preview')"
          @onConfirmation="assignMembers"
          @onCancel="memberConfirmationModal = false; newProjectMemberDialog = true "
        />
        <!-- member remove modal -->
        <DialogModal
          v-model="memberRemoveConfirmationModal"
          :title="$vuetify.lang.t('$vuetify.common.confirm_alert_title_txt')"
          :message=confirmRemoveMessage
          :confirmButtonText="$vuetify.lang.t('$vuetify.common.confirm_btn')"
          :cancelButtonText="$vuetify.lang.t('$vuetify.common.cancel')"
          @onConfirmation="removeProjectMembers"
          @onCancel="memberRemoveConfirmationModal = false;"
        />
      </v-card>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Button from "@/components/common/button/Button";
import {
  FLOATING_ACTION_BUTTON,
} from "@/components/common/button/_buttontypes";
import {
  LOAD_ALL_MEMBERS,
  ASSIGN_PROJECT_MEMBERS,
  RETRIEVE_PROJECT_MEMBERS,
  DELETE_PROJECT_MEMBERS,
  ADD_ALERT
} from "@/store/_actiontypes";
import Loader from "@/components/common/loader/Loader";
import DialogModal from "@/components/common/DialogModal";
export default {
  props: {

  },
  components: {
    Button,
    DialogModal,
    Loader
  }, 
  computed: {
    ...mapState({
      allMembers: state => state.member.allMembers,
      selectedProject: state => state.project.selectedProject,
      projectId : state => state.project.selectedProject?.id,
      organization: state => state.account.organization,
    }),
    assignBtnDisable (){
      return this.selectedMembers.length === 0
    },
    filteredProjectMembers(){
        const filteredMembers = this.projectMembers.filter((member) => {
          return (member?.user?.status === "inactive" || member?.user?.status === "created") ? false :  true
        })
        return filteredMembers
    },
    selectableMembers () {
      const unassignedMembers = this.allMembers?.items?.filter(member => {
        if(member?.is_account_owner || member?.status === "inactive" || member?.status === "created" || this.isProjectMember(member.id))
          return false
        else return true
      })
      return unassignedMembers
    },
    removeBtnDisable (){
      return this.selectedProjectMembers.length === 0
    },
    confirmMessage(){
      return this.$vuetify.lang.t('$vuetify.access_control.member_assignment_confirmation') + ` (${this.selectedMembers.length})`
    },
    confirmRemoveMessage(){
      return this.$vuetify.lang.t('$vuetify.access_control.member_removal_confirmation')
    }
  },
  mounted(){
    this.getAllMembersFunction()
    this.getProjectMembers()
  },
  data(){
    return {
      FLOATING_ACTION_BUTTON: FLOATING_ACTION_BUTTON,
      newProjectMemberDialog : false,
      isLoading: false,
      selectedMembers: [],
      memberConfirmationModal: false,
      memberRemoveConfirmationModal: false,
      selectedProjectMembers: [],
      assignedMembersLoading: false,
      projectMembers: [],
      headers: [
          {
            text: this.$vuetify.lang.t("$vuetify.member.full_name"),
            value: "first_name",
            sortable: true,
            align: "start",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.member.email"),
            align: "start",
            sortable: true,
            value: "email",
            cellClass: "d-inline-inherit",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.member.role"),
            align: "start",
            sortable: true,
            value: "role",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.table_header.status"),
            align: "start",
            sortable: true,
            value: "status",
          }
      ],
      projectMembersHeaders: [
          {
            text: this.$vuetify.lang.t("$vuetify.member.full_name"),
            value: "user.first_name",
            sortable: true,
            align: "start",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.member.email"),
            align: "start",
            sortable: true,
            value: "user.email",
            cellClass: "d-inline-inherit",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.member.role"),
            align: "start",
            sortable: true,
            value: "user.role",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.table_header.status"),
            align: "start",
            sortable: true,
            value: "user.status",
          }
      ],
      
    }
  },
  methods: {
    ...mapActions("member", [
      LOAD_ALL_MEMBERS,
    ]),
    ...mapActions("project", [
      ASSIGN_PROJECT_MEMBERS,
      RETRIEVE_PROJECT_MEMBERS,
      DELETE_PROJECT_MEMBERS
    ]),
    ...mapActions("alert", [ADD_ALERT]),
    addNewProjectMember() {
      this.newProjectMemberDialog = true
    },
    isProjectMember(memberId){
      return this.projectMembers.some(projectMember => projectMember.user_id === memberId)
    },
    closeMembersAssignmentModal(){
      this.newProjectMemberDialog = false
      this.selectedMembers = []
    },
    showMemberAssignmentConfirmationModal(){
        this.memberConfirmationModal = true
        this.newProjectMemberDialog = false
    },
    isProjectOwner(id){
      if(this.selectedProject.owner?.id === id){
        return true
      } 
      return false
    },
    getAllMembersFunction() {
      this.isLoading = true;
      this.LOAD_ALL_MEMBERS({start: 1, size:1000}).then(
        _response => {
          this.isLoading = false;
        },
        _error => {
          this.isLoading = false;
        },
      );
    },
    removeProjectMembers(){
      this.assignedMembersLoading = true
      const membersToBeRemoved = this.selectedProjectMembers.map((user) => {
          return user.user_id
      })
      const previousMembers = [...this.projectMembers]

      this.DELETE_PROJECT_MEMBERS({
        project_id: this.projectId,
        users : {
          user_ids : membersToBeRemoved
        }
      }).
      then(res => {
        if(res?.status === 200) {
          const filteredMembers = previousMembers.filter(member => {
            return !membersToBeRemoved.some(removedMember => {
              return removedMember === member.user_id
            })
          })
          this.projectMembers = filteredMembers
          this.selectedProjectMembers = []
        }
      }).
      catch(err => {
        this.$store.dispatch(
          `alert/${ADD_ALERT}`,
          {
            message: this.$vuetify.lang.t('$vuetify.access_control.member_removal_error'),
            color: "error",
          },
          { root: true },
        );
        console.error("Err", err)
      }).finally(()=> {
        this.memberRemoveConfirmationModal = false
          this.assignedMembersLoading = false
      })
    },
    assignMembers() {
      this.memberConfirmationModal = false
      this.assignedMembersLoading = true
      const mappedSelectedMembers = this.selectedMembers.map(user => {
        return {
          user_id: user.id,
          permission: {}
        }
      })
      const users = {
        user_assignments: mappedSelectedMembers
      }
      this.ASSIGN_PROJECT_MEMBERS({ project_id: this.projectId, users})
      .then(res => {
        if(res?.status === 201){
          this.getProjectMembers()
          this.selectedMembers = []
        }
      })
      .catch(err => {
        this.$store.dispatch(
          `alert/${ADD_ALERT}`,
          {
            message: this.$vuetify.lang.t('$vuetify.access_control.member_assignment_error'),
            color: "error",
          },
          { root: true },
        );
        console.error("Error", err)
      }).finally(()=> {
        this.assignedMembersLoading = false
      })
    },
    getProjectMembers() {
      this.assignedMembersLoading = true
      this.RETRIEVE_PROJECT_MEMBERS({
        project_id: this.projectId
      }).
      then(res => {
        this.projectMembers = res.data?.members
      }).catch( err => {
        console.error("Err", err)
      }).finally(()=> {
        this.assignedMembersLoading = false
      })
    }
  }

}

</script>

<style>
</style>