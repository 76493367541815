import { getCurrentUser } from "@/helpers/helper";
import getEnv from "@/config/env";


export default {
  methods: {
    checkUserLoggedOut(event) {
      if (event.key !== 'doozie_user') return;
      if (!getCurrentUser()) {
        this.$router.push('/login');
      }
    },
    mairaIntegration(){

      var greeting = this.$vuetify.lang.t("$vuetify.maira.greeting");
      const clientKey = getEnv("VUE_APP_MAIRA_CLIENT_KEY"); // クライアント機密キー
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src=getEnv("VUE_APP_MAIRA_SRC");
      // s1.setAttribute('crossorigin','*');
      s1.setAttribute('data-greeting',greeting);
      s1.setAttribute('data-client-key', clientKey);
      s1.setAttribute('data-chat-header','Ask Maira!');
      s1.setAttribute('data-icon-color','#004aad');
      // s1.setAttribute('data-icon-bottom-position','YOUR_ICON_BOTTOM_POSITION');
      // s1.setAttribute('data-iframe-bottom-position','YOUR_IFRAME_BOTTOM_POSITION');
      s1.setAttribute('data-prefix', window.location.hostname);
      s0.parentNode.insertBefore(s1,s0);     
    }
  },
  mounted() {
    window.addEventListener('storage', this.checkUserLoggedOut);
    this.mairaIntegration();
  },
  beforeDestroy() {
    window.removeEventListener('storage', this.checkUserLoggedOut);
  },
};