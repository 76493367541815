//LOGIN
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";

//SWITCH ACCOUNT
export const SWITCH_SUCCESS = "SWITCH_SUCCESS";
export const SWITCH_FAILURE = "SWITCH_FAILURE";
// export const CREATE_ACCOUNT = "CREATE_ACCOUNT"

// ERROR
export const SET_ERROR = "SET_ERROR";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_SIGNUP_ERROR = "SET_SIGNUP_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SET_SWITCH_ERROR = "SET_SWITCH_ERROR";

//USER
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS";
export const UPDATE_USER_SETTINGS = "UPDATE_USER_SETTINGS";
export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";

//Account
export const SET_ACCOUNT = "SET_ACCOUNT";

// RESET PASSWORD
export const SET_RESET_PASSWORD_USER_DETAILS = "SET_RESET_PASSWORD_USER_DETAILS";

//ORGANIZATION
export const SET_ORGANIZATION = "SET_ORGANIZATION";

//CURRENCIES
export const SET_CURRENCIES = "SET_CURRENCIES";

//ALERT
export const CREATE_ALERT = "CREATE_ALERT";

//LOADING
export const UPDATE_LOADING = "UPDATE_LOADING";

//PROJECTS
export const SET_ALL_PROJECT = "SET_ALL_PROJECT";
export const SET_PROJECT_BY_ID = "SET_PROJECT_BY_ID";
export const SET_MAPPER = "SET_MAPPER";
export const SET_PROJECT_SETUP_STATE = "SET_PROJECT_SETUP_STATE";
export const SET_PROJECT_COST = "SET_PROJECT_COST";
export const SET_ALL_CREDENTIAL = "SET_ALL_CREDENTIAL";
export const SET_TRAIN_TASKS = "SET_TRAIN_TASKS";
export const SET_PREVIOUSLY_SETUP_STATE = "SET_PREVIOUSLY_SETUP_STATE";
export const SET_PROJECT_COUNTS = "SET_PROJECT_COUNTS";
export const SET_CATALOG_ITEMS = "SET_CATALOG_ITEMS";
export const CATALOG_SCROLL_ID_STACK = "CATALOG_SCROLL_ID_STACK";

//GPT PROFILE
export const SET_GPT_PROFILES = "SET_GPT_PROFILES";
export const SET_GPT_PROFILE = "SET_GPT_PROFILE";
export const SET_GPT_SETUP_STATE = "SET_GPT_SETUP_STATE";


//GPT DATASET
export const SET_GPT_DATASETS = "SET_GPT_DATASETS"
export const SET_GPT_DATASET = "SET_GPT_DATASET"
export const SET_GPT_DATASET_SUMMARY = "SET_GPT_DATASET_SUMMARY"

//GPT DOCUMENT
export const SET_GPT_DOCUMENTS = "SET_GPT_DOCUMENTS"
export const SET_GPT_DOCUMENT = "SET_GPT_DOCUMENT"
export const SET_GPT_DOCUMENT_SUMMARY = "SET_GPT_DOCUMENT_SUMMARY"

//GPT Conversations
export const SET_GPT_CONVERSATIONS = "SET_GPT_CONVERSATIONS"

// RECOMMENDER
export const RECOMMENDER_SET_INSIGHTS = "RECOMMENDER_SET_INSIGHTS";

//SOLUTIONS
export const SET_ALL_SOLUTION = "SET_ALL_SOLUTION";
export const SET_ALL_SOLUTION_BY_TYPE = "SET_ALL_SOLUTION_BY_TYPE";
export const SET_SOLUTION_BY_ID = "SET_SOLUTION_BY_ID";
export const SET_RELATED_SOLUTIONS_BY_TYPE = "SET_RELATED_SOLUTIONS_BY_TYPE";
export const SET_ALL_SOLUTION_BY_DEV = "SET_ALL_SOLUTION_BY_DEV";
export const SET_PUBLIC_SOLUTIONS = "SET_PUBLIC_SOLUTIONS";
export const SET_SOLUTION_DETAILS = "SET_SOLUTION_DETAILS";
export const SET_SOLUTION_HAS_PROJECT = "SET_SOLUTION_HAS_PROJECT";

//RESOURCEMETADATA
export const SET_ALL_RESOURCEMETADATA = "SET_ALL_RESOURCEMETADATA";
export const SET_ALL_RESOURCEMETADATA_BY_TYPE = "SET_ALL_RESOURCEMETADATA_BY_TYPE";

//CATEGORY
export const SET_ALL_CATEGORY = "SET_ALL_CATEGORY";

// SUBSCRIPTION
export const SET_ALL_SUBSCRIPTION = "SET_ALL_SUBSCRIPTION";

// INVOICE
export const SET_ALL_INVOICE = "SET_ALL_INVOICE";
export const SET_INVOICE_BY_ID = "SET_INVOICE_BY_ID";

// SEGMENT
export const SET_ALL_SEGMENT = "SET_ALL_SEGMENT";

// WALLET
export const SET_ALL_WALLET_ACCOUNT = "SET_ALL_WALLET_ACCOUNT";
export const SET_ALL_WALLET_TRANSACTION = "SET_ALL_WALLET_TRANSACTION";
export const SET_WALLET_ACCOUNT_DETAILS = "SET_WALLET_ACCOUNT_DETAILS";
export const SET_ALL_WALLET_CLAIMABLE_BALANCE = "SET_ALL_WALLET_CLAIMABLE_BALANCE";
export const SET_ALL_WALLET_RECIEVED_CLAIMABLE_BALANCE = "SET_ALL_WALLET_RECIEVED_CLAIMABLE_BALANCE";
export const SET_WALLET_STELLAR_TRANSACTION =  "SET_WALLET_STELLAR_TRANSACTION";
export const SET_WALLET_SELECTED_ACC = "SET_WALLET_SELECTED_ACC";
export const SET_WALLET_BUY_HISTORY_BY_ACCOUNT = "SET_WALLET_BUY_HISTORY_BY_ACCOUNT";
export const SET_ALL_WALLET_ASSET = "SET_ALL_WALLET_ASSET";

// UPLOAD
export const SET_ALL_CONTENT = "SET_ALL_CONTENT";
export const SET_CONTENT = "SET_CONTENT";

// BANK
export const SET_ALL_BANK_ACCOUNT = "SET_ALL_BANK_ACCOUNT";
export const SET_BANK_ACCOUNT_BY_ID = "SET_BANK_ACCOUNT_BY_ID";

// REGION
export const SET_ALL_REGION = "SET_ALL_REGION";
export const SET_REGION_BY_ID = "SET_REGION_BY_ID";

// JOBS
export const SET_ALL_JOB = "SET_ALL_JOB";

// MEMBERS
export const SET_ALL_MEMBERS = "SET_ALL_MEMBERS";
export const SET_MEMBER_BY_ID = "SET_MEMBER_BY_ID";
export const SET_SEARCHED_MEMBERS = "SET_SEARCHED_MEMBERS";
export const SET_SELECTED_MEMBER  = "SET_SELECTED_MEMBER";
export const SET_SEARCHED_EMAIL ="SET_SEARCHED_EMAIL"


// LOCALE
export const SET_LOCALE = "SET_LOCALE";

//GPT MODEl
export const SET_GPT_MODELS = 'SET_GPT_MODELS';
export const SET_GPT_PROVIDERS = 'SET_GPT_PROVIDERS';