import ja from "vuetify/lib/locale/ja";

export default {
  ...ja,

  common: {
    create: "作成",
    read_more: "もっと読む",
    page_no: "ページ番号",
    add_btn: "追加",
    add_more_btn: "追加",
    edit_btn: "編集",
    delete_btn: "削除",
    save_btn: "保存",
    share_btn_txt: "共有",
    download_btn: "ダウンロード",
    update_btn: "更新する",
    upload_btn: "アップロード",
    uploading: "アップロード中",
    ok_btn: "OK",
    yes_btn: "はい",
    no_btn: "いいえ",
    cancel_btn: "キャンセル",
    clear_btn: "クリア",
    send_btn: "送信",
    subs_upgrade_modal_confirm_btn: "リクエストを送信",
    confirm_btn: "確認",
    subs_upgrade_btn: "全機能のロック解除リクエスト",
    details: "詳細",
    confirm_alert_title_txt: "確認",
    error_title_txt: "エラー",
    warning_title_txt: "警告",
    details_header_txt: "詳細",
    continue_btn: "次へ",
    prev_btn: "前に戻る",
    close_btn: "閉じる",
    reset_btn: "送信",
    submit_btn: "送信",
    generate_btn: "生成",
    submit_review_btn: "レビューのために提出",
    draft_btn: "下書き",
    sandbox_btn: "サンドボックス",
    tutorial_btn: "チュートリアル",
    documentation_btn: "ドキュメンテーション",
    get_started_btn_txt: "始めましょう",
    copy_msg_txt: "コピー",
    copied_msg_txt: "コピーされました",
    copy_text_alert_msg_txt: "内容をコピーしました：",
    download: "ダウンロード",
    select_file: "ファイルを選択",
    try_again: "再試行",
    upload_again: "再度アップロードする",
    history: "履歴",
    privacy_policy_txt_1: "内容を読んで同意します。",
    privacy_policy_txt_2: "プライバシーポリシー",
    privacy_policy_txt_3: "利用規約",
    confirm_alert_msg_txt: "この内容でよろしですか？",
    confirm_alert_msg_txt_2: "このソリューションをレビューのために提出しますか？",
    confirm_alert_msg_txt_3: "このプロジェクトを停止してもよろしいですか？",
    confirm_alert_msg_txt_4: "このプロジェクトを削除してもよろしいですか？",
    confirm_alert_msg_txt_5: "このプロジェクトを開始してもよろしいですか？",
    confirm_alert_msg_txt_6: "このプロファイルを削除してもよろしいですか？",
    confirm_alert_msg_txt_7: "このカタログアイテムを削除してもよろしいですか？",
    confirm_alert_msg_txt_8: "すべてのカタログアイテムを削除してもよろしいですか？",
    confirm_alert_msg_txt_9: "選択したカタログアイテムを削除してもよろしいですか？",
    unknown_err_msg_txt: "不明なエラーが発生しました！",
    daterange_placeholder_txt: "日付範囲を選択",
    no_data_available:  "データなし",
    required_field: "必須項目",
    field_required_error_txt: "この項目は必須です",
    duplicate_error_txt: "このフィールドはすでに存在します",
    req_field_error_msg_txt: "必須項目が入力されておりません",
    done: "完了",
    proceed: "進む",
    review: "レビュー",
    input: "入力",
    actions: "アクション",
    load_more: "さらに読み込む",
    date: "日付",
    status: "ステータス",
    type: "タイプ",
    name: "名前",
    query: "クエリ",
    response: "応答",
    feedback: "フィードバック",
    description: "説明",
    loading: "読み込み中",
    pay_sent_btn_txt: "振込を完了しました",
    pay_btn_txt: "お支払い",
    page_not_found_msg_txt_1: "ページが見つかりません",
    page_not_found_msg_txt_2: "要求されたURLが存在しません",
    contact_sales_btn_txt: "お問い合わせ",
    search_btn_txt: "検索",
    add: "追加",
    cancel: "キャンセル",
    confirm: "確認",
    continue: "次へ",
    next: "次",
    previous: "前に戻る",
    save: "保存する",
    close: "近い",
    copied: "コピーしました",
    copy: "コピー",
    remove: "削除",
    preview: "プレビュー"
  },
  tab: {
    solutions_users_tab: "ソリューションリスト",
    solutions_projects_tab: "プロジェクト",
    solutions_developers_tab: "マイソリューション",
    solutions_playground_tab: "テスト環境",
    project_details_tab: "詳細",
    project_settings_tab: "プロジェクト設定",
    project_insights_tab: "インサイト",
    project_setup_tab: "設定",
    personalizer_datasets_tab: "データセット",
    maira_datasets_tab: "Maira データセット",
    maira_profiles_tab: "プロフィール",
    maira_settings_tab: "Maira 設定",
  },
  setup_steps: {
    details_step_txt: "詳細",
    pricing_step_txt: "価格設定",
    metadata_step_txt: "メタデータ",
    review_step_txt: "確認",
    total_amount_step_txt: "合計金額",
    payment_method_step_txt: "お支払い方法",
    project_basic_tab: "プロジェクト基本情報",
  },
  tooltip: {
    draft: "下書き",
    submit_for_review: "レビューのために提出",
    edit: "編集",
    copy: "テキストをコピーする",
    stop: "停止する",
    start: "再開する",
    delete: "削除する",
    open_window: "新しいウィンドウで開きます",
    document: "ドキュメント",
  },
  validation: {
    required_error_msg_txt: "は必須です",
    email_validation_error_msg_txt: "有効なメールアドレスを入力してください",
    characters_validation_error_msg_txt: "文字",
    min_val_error_msg_txt: "より大きい値にしてください",
    password_email_error_msg_txt: "メールアドレスを含めることはできません",
    password_error_msg_txt: "含めることはできません",
    password_confirm_error_msg_txt: "確認用のパスワードが一致しません",
    password_valid_error_msg_txt_1: "あなたの",
    password_valid_error_msg_txt_2: "パスワード条件：（必須）長さ8文字以上からなる（必須）英大文字を1文字以上含む（必須）英小文字を1文字以上含む（必須）記号または数字を1文字以上含む",
    numeric_error_msg_txt: "数値だけの使用はできません",
  },
  table_header: {
    id_header_txt: "身元",
    name_header_txt: "氏名",
    credentials_name_header_txt: "認証情報の名称",
    created_at: "作成日",
    updated_at: "更新日",
    status: "ステータス",
    actions: "操作項目",
    project_id: "プロジェクトID",
    project_name: "プロジェクト名",
    solution_type: "プロダクト名",
    language: "プロジェクトの言語",
    industry: "業種",
  },
  top_nav: {
    profile_menu_txt: "プロフィール",
    signout_menu_txt: "ログアウト",
  },
  side_nav: {
    dashboard: "ダッシュボード",
    personalizer: "Personalizer",
    cv: "CV",
    maira: "Maira",
    members: "メンバー管理",
    wallet: "ウォレット",
    jobs: "バッチ処理",
    release_note: "リリース情報",
    solutions: "ソリューション",
    projects: "プロジェクト",
    invoices: "請求書",
    switch_account:"アカウントを切り替える"
  },
  dashboard: {
    projects: "全プロジェクト",
    active: "アクティブ",
    click_to_see_projects: "クリックして全てのプロジェクトを表示",
    recommended_articles: "おすすめ記事"
  },
  member: {
    members_header: "メンバー管理",
    search: "メンバーを検索",
    id: "メンバーID",
    first_name: "ファーストネーム",
    middle_name: "ミドルネーム",
    last_name: "苗字",
    full_name: "名前",
    email: "メールアドレス",
    role: "役割",
    invite: "メンバーを招待",
    reInvite: "再招待",
    modal_title: "メンバーを招待",
    choose_one_placeholder_txt: "1つ選択",
    details: "会員詳細",
    role_dialog_msg: "__email__の役割を更新してもよろしいですか？",
    status_dialog_msg: "__email__のステータスを更新してもよろしいですか",
    role_list: {
      admin: "管理者",
      user: "メンバー",
    },
  },
  profile: {
    profile_basic_tab: "基本情報",
    profile_account_tab: "アカウント情報",
    profile_verify_tab: "本人確認",
    profile_settings_tab: "設定",
    first_name_label_txt: "名",
    first_name_error_msg_txt: "「名」項目は必須です",
    first_name_error_msg_txt_2: "「名」に数値のみの使用は不可能です",
    middle_name_label_txt: "ミドルネーム",
    middle_name_error_msg_txt_2: "「ミドルネーム」には数値のみの使用は不可能です",
    last_name_label_txt: "姓",
    last_name_error_msg_txt: "「姓」項目は必須です",
    last_name_error_msg_txt_2: "「姓」に数値のみの使用は不可能です",
    full_name_label_txt: "フルネーム",
    dob_label_txt: "生年月日",
    gender_label_txt: "性別",
    male_label_txt: "男性",
    female_label_txt: "女性",
    other_label_txt: "その他",
    phone_no_label_txt: "電話番号",
    phone_no_error_msg_txt: "電話番号項目は必須です",
    phone_no_error_msg_txt_2: "有効な電話番号を入力してください",
    address_label_txt: "住所",
    country_label_txt: "国籍",
    country_error_msg_txt: "国籍項目は必須です",
    state_label_txt: "県",
    state_error_msg_txt: "県項目は必須です",
    city_label_txt: "市",
    city_error_msg_txt: "市項目は必須です",
    city_error_msg_txt_2: "市には数値のみの使用は不可能です",
    post_code_label_txt: "郵便番号",
    post_code_error_msg_txt: "郵便番号項目は必須です",
    post_code_error_msg_txt_2: "有効な郵便番号を入力してください",
    id_verify_header_txt: "身分証明書（パスポート/運転免許証/在留カードなど）をアップロードしてください。",
    upload_img_1_label_txt: "表面をアップロード",
    upload_img_2_label_txt: "裏面をアップロード",
    upload_img_1_error_msg_txt: "表面は必須です",
    upload_img_2_error_msg_txt: "裏面は必須です",
    id_verify_msg_txt_1: "本人確認完了しないと作成されたプロジェクトが承認されません。本人確認手続きを行なってください",
    id_verify_msg_txt_2: "本人確認",
    id_verify_msg_txt_3: "すぐ",
    verify_alert_msg_txt: "本人確認が完了しておりません。支払いを行うには本人確認が必須です。",
    address_error_msg_txt: "住所項目は必須です",
    review_alert_msg_txt: "現在、お客様の本人確認書類をレビューしており、完了次第お知らせ致します。",
    address_error_msg_txt_2: "住所には数値のみの使用は不可能です",
    id_upload_success_msg_txt: "Eid が正常にアップロードされました",
  },
  registration: {
    login_msg_txt: "ログイン - ",
    email_label_txt: "メールアドレス",
    password_label_txt: "パスワード",
    login_btn_txt: "ログイン",
    forgot_password_msg_txt: "パスワードをお忘れですか？",
    back_to_home_txt: "ホームへ戻る",
    current_password_label_txt: "現在のパスワードを入力してください",
    current_password_error_msg_txt: "現在のパスワード: 必須項目です",
    registration_msg_txt: "まだアカウントをお持ちではありませんか？",
    registration_btn_txt: "アカウントを作成する",
    register_header_txt: "Gigalogy AI プラットフォームへようこそ",
    account_name_label_txt: "アカウント名",
    name_header_txt: "氏名",
    new_password_label_txt: "新しいパスワードを入力してください",
    new_password_error_msg_txt: "新しいパスワード: 必須項目です",
    new_password_error_msg_txt_2: "現在のパスワードを新しいパスワードとして設定することはできません",
    re_password_label_txt: "パスワードを再入力してください",
    re_password_error_msg_txt: "パスワードの再入力: 必須項目です",
    resend_verification_email_btn_txt: "認証メール再送信",
    already_logged_in_error_msg: "すでにログインしている",
    account_already_verified: "アカウントはすでに認証されています。",
    account_exist_msg_txt: "すでにアカウントをお持ちですか？",
    registration_success_msg_txt_1: "アカウント登録が成功しました",
    registration_success_msg_txt_2: "アカウントを設定するための残りの手順が記載されたメールを送信しました。受信トレイを確認してください。",
    forgot_password_msg_txt_1: "パスワードをお忘れですか？",
    forgot_password_msg_txt_2: "メールアドレスを入力してください。パスワードをリセットするためのリンクが送信されます。",
    resend_verification_email_msg_txt_1: "メールが届かない場合は、まず迷惑メール/ジャンクフォルダをご確認ください。そこにメールがない場合は、以下のボタンをクリックして、メールを再送信してください。",
    resend_verification_email_msg_txt_2: "受信トレイを確認し、アカウント認証手続きを行なってください。",
    reset_password_msg_txt_1: "パスワードをリセット",
    reset_password_msg_txt_2: "パスワード条件：長さは8文字以上、メールアドレスや名前の一部分が含まれない、小文字、大文字、数字、記号のうち少なくとも3つが含まれていること",
    change_password_btn_txt: "パスワードを変更する",
    login_link_txt: "ログインに戻る",
    verify_msg_txt: "確認中...",
    complete_register_header_txt: "アカウント登録を完了してください",
  },
  account: {
    account_name_label_txt: "アカウント名",
    account_name__error_msg_txt: "アカウント名: 必須項目です",
    account_name_error_msg_txt_2: "アカウント名には数字と文字列を含める必要があります",
    account_type_label_txt: "口座の種類",
    account_type_value_individual: "個人",
    account_type_value_organization: "組織",
    account_no_label_txt: "口座番号",
    org_name_label_txt: "法人名",
    org_name_error_msg_txt: "「法人名」項目は必須です",
    org_name_error_msg_txt_2: "法人名には数値のみの使用は不可能です",
    vat_no_label_txt: "法人のVAT番号",
    reg_no_label_txt: "法人番号",
    ceo_label_txt: "代表取締役社長/CEO",
    ceo_error_msg_txt: "代表取締役社長/CEO項目は必須です",
    ceo_error_msg_txt_2: "代表取締役社長/CEOに数値のみの使用は不可能です",
    org_email_label_txt: "メールアドレス(法人)",
    org_email_error_msg_txt: "有効なメールアドレス(法人)を入力してください",
    org_phone_label_txt: "電話番号(法人)",
    org_phone_error_msg_txt_2: "有効な電話番号(法人)を入力してください",
    org_website_label_txt: "ホームページ",
    org_website_error_msg_txt: "ホームページ項目は必須です",
    org_website_error_msg_txt_2: "https://example.com のような有効なURLを入力してください",
    org_size_label_txt: "従業員数",
    org_size_error_msg_txt: "「従業員数」項目は必須です",
    org_size_opt_1_txt: "10人未満",
    org_size_opt_2_txt: "10〜49人",
    org_size_opt_3_txt: "50〜149人",
    org_size_opt_4_txt: "150〜249人",
    org_size_opt_5_txt: "250〜999人",
    org_size_opt_6_txt: "1000人以上",
    org_industry_label_txt: "業種",
    city_error_msg_txt: "市項目は必須です",
    city_error_msg_txt_2: "市には数値のみの使用は不可能です",
    country_error_msg_txt: "国項目は必須です",
    state_error_msg_txt: "県項目は必須です",
    post_code_error_msg_txt: "郵便番号項目は必須です",
    post_code_error_msg_txt_2: "有効な郵便番号を入力してください",
    upload_img_1_error_msg_txt: "表面は必須です",
    upload_img_2_error_msg_txt: "裏面は必須です",
    registered_address_header_txt: "住所",
    address_error_msg_txt: "住所項目は必須です",
    address_error_msg_txt_2: "住所には数値のみの使用は不可能です",
    acc_update_msg_txt: "アカウント情報が更新されました",
    basic_info_header_txt: "基本情報",
    acc_info_header_txt: "口座情報",
    select_img_msg_txt: "画像を選択してください！",
  },
  project: {
    projects_header_txt: "プロジェクト一覧",
    search_btn_txt: "プロジェクトを検索",
    create_project_btn_txt: "プロジェクトを作成",
    create_project_success_msg_txt: "プロジェクトが正常に登録されました!",
    update_project_success_msg_txt: "プロジェクトが正常に更新されました!",
    upgrade_subscription_success_msg_txt: "プロジェクトのサブスクリプションが成功裏にアップグレードされました！",
    profile_basic_header: "プロジェクト基本情報",
    project_name_header_txt: "プロジェクト名",
    project_name_placeholder_text: "プロジェクト内容に沿った名称を推奨",
    language_header_txt: "言語",
    language: {
      english_txt: "英語",
      japanese_txt: "日本語",
    },
    solution_header_txt: "種類",
    industry_header_txt: "業種",
    enabled_solution_label_txt: "有効なソリューション",
    enable_solution_label_txt: "ソリューションの選択",
    enable_solution_error_msg_txt: "ソリューションの選択は必須です",
    project_app_infra_step_txt: "アプリケーションとインフラ",
    cloud_provider_label_txt: "クラウドプロバイダー",
    purpose_of_gpt_label_txt: "プロジェクトの目的",
    randomness_label_txt: "データ参照範囲の設定",
    accurate_ans_label_txt: "アップロードしたデータのみで回答を生成",
    generalized_ans_label_txt: "アップロードしたデータ+LLMの一般的な知識で回答を生成",
    region_label_txt: "領域",
    auto_scaling_label_txt: "自動スケーリング",
    subs_label_txt: "サブスクリプション",
    subs_type_label_txt: "サブスクリプション ステータス",
    item_limit_label_txt: "アイテムリミット（アイテムカタログの最大数)",
    subs_api_rate_limit: "API レート制限",
    month_txt: "月",
    calls_txt: "回",
    seconds_txt: "秒",
    ai_assistant_txt: "AIアシスタント",
    search_txt: "検索",
    message: "メッセージ",
    generated_by: "作成者",
    recommendation_txt: "レコメンド",
    billing_cycle_label_txt: "現在の請求サイクル",
    est_total_cost_label_txt: "推定総費用／月",
    trial_subs_expire_date_label: "トライアル期間終了",
    next_invoice_issue_label_txt: "次回請求書発行日",
    next_invoice_due_label_txt: "次回請求書支払期限日",
    customer_support_message: "API 利用数の上限を引き上げたい場合は、support@gigalogy.com までご連絡ください。",
    subs_upgrade_modal_title: "全機能のロック解除リクエスト！",
    subs_upgrade_modal_message: "このプロジェクトのトライアル期間が終了し、これらの変更が適用されます。：",
    subs_upgrade_modal_message_continued: "初月は、アクティベーション日から月末までの期間に基づいて日割り計算されます。ご了承ください。",
    subs_upgrade_modal_message_last: "数日以内にリクエストを確認し、プロジェクトを承認します。",
    subscription_fee_header_txt: "サブスクリプション費",
    upgrade_project_request_success_msg_txt: "スタンダードプランへのアップグレードのリクエストが送信されました。",
    already_assigned_to_another_field: "すでに別のフィールドに割り当てられています",
    field_required: "この項目は必須です",
    field_required_for_prioritize_category: "値を設定する場合、優先カテゴリは必須です",
    field_required_for_prioritize_values: "カテゴリが選択されている場合は優先順位の値が必要です",
    try_again: "再試行",
    train_using_dataset: "データセットを使用してモデルをトレーニングする",
    full_error_log: "詳細",
    key_type: "キータイプ",
    value_type: "バリュータイプ",
    finish: "完了",
    setup_in_progress: "設定中",
    dataset: "データセット",
    unique_user_label_txt: "予想ユニークユーザー数/月",
    item_count_label_txt: "期待される項目数",
    subs_cost_label_txt: "サブスクリプションコスト/月",
    est_infra_cost_label_txt: "推定インフラコスト/月",
    project_pricing: "プロジェクト_価格設定",
    expected_request_label_txt: "予想されるリクエスト/月",
    request_per_sec_label_txt: "秒あたりの予想リクエスト数（RPS）",
    next_billing_date_label_txt: "次回の請求日",
    subs_start_label_txt: "サブスクリプション開始日",
    create_trial_project_btn_txt: "試用プロジェクトを作成する",
    created_at: "作成日",
    settings_initial_setup_info: "トレーニングを開始する前に初期設定を完了してください。",
    training: "トレーニング中",
    dataset_update: "データセット更新",
    upload_dataset: "データセットのアップロード",
    dataset_create_success: "データセットが正常に作成されました",
    dataset_create_failed: "データセットの作成に失敗しました",
    uploading: "アップロード中",
    update: "アップデート",
    update_rank_settings: "ランク設定の更新",
    create_rank_settings: "ランク設定の作成",
    train_options: "列車のオプション",
    train_all_item: "すべてのアイテムをトレーニングする",
    train_updated_item: "更新されたアイテムをトレーニングする",
    train_item_within_specified_range: "指定された範囲内の画像をトレーニングする",
    train_all_image: "すべての画像をトレーニング",
    train_image_within_specified_range: "指定された範囲内の画像をトレーニングする",
    date_field_required:"特定の範囲には日付フィールドが必要です",
    start_date_validation: "開始日は終了日よりも前の日付である必要があります",
    end_date_validation: "終了日は開始日よりも後の日付である必要があります",
    train_history: "トレーニング履歴",
    train: "Train",
    items_train: "アイテムトレーニング",
    images_train: "画像トレーニング",
    ranks_train: "ランクトレーニング",
    gpt_train: "GPTトレーニング",
    insight_process: "洞察プロセス",
    setup: "設定",
    select_file: "ファイルを選択",
    map_data: "データマッピング",
    rank_settings: "ランク設定",
    progress: "進行",
    add_more: "追加",
    train_data: "データトレーニング",
    train_type: "トレーニングタイプ",
    all: "全て",
    update_mapper: "マッピング更新",
    dataset_update_info: "アイテムカタログの更新はこちらから",
    dataset_update_log: "データセット更新ログ",
    task_status: "タスクステータス",
    updating: "更新中",
  },
  access_control: {
    title: "アクセス管理",
    assigned_members: "アサインされたメンバー",
    assign_to_project: "プロジェクトにメンバーをアサイン",
    assign: "アサイン",
    member_assignment_confirmation: "選択したメンバーをアサインしてよろしいですか？",
    member_removal_confirmation: "選択したメンバーを削除してよろしいですか？",
    member_removal_error: "メンバーの削除中にエラーが発生しました。",
    member_assignment_error: "メンバーのアサイン中にエラーが発生しました。",
    assign_new_member: "新しいメンバーをアサイン"
  },
  solution: {
    developed_by: "開発者：",
    preview: "プレビュー",
    search_btn_txt: "検索",
    search_label_txt: "ソリューションを検索",
    filter_label_txt: "ステータスでフィルター",
    no_result_msg_txt: "申し訳ありませんが、結果が見つかりませんでした",
    solution_name_label_txt: "ソリューション名",
    solution_overview_label_txt: "ソリューションの概要",
    overview_label_txt: "概要",
    solution_description_label_txt: "ソリューションの詳細（オプション）",
    description_label_txt: "説明",
    solution_description_placeholder_txt: "ソリューションの詳細",
    use_case_label_txt: "ユースケース",
    use_case_placeholder_txt: "使用事例",
    language_label_txt: "対応言語",
    industry_label_txt: "業種",
    contact_sales_url: "https://gigalogy.com/request-demo",
    linked_solution_label_txt_1: "一緒に使用できるソリューション（オプション）",
    linked_solution_label_txt_2: "一緒に使用されるソリューション",
    linked_solution_label_txt_3: "一緒に使用できる他のソリューション",
    solution_name_error_msg_txt: "「ソリューション名」項目は必須です",
    solution_name_error_msg_txt_2: "ソリューション名には数値のみの使用は不可能です",
    solution_type_error_msg_txt: "「ソリューションタイプ」項目は必須です",
    solution_overview_error_msg_txt: "「ソリューションの概要」項目は必須です",
    solution_usecase_error_msg_txt: "「ユースケース」項目は必須です",
    solution_industry_error_msg_txt: "「業種」項目は必須です",
    solution_language_error_msg_txt: "「対応言語」項目は必須です",
    solution_cpu_error_msg_txt: "「CPUコア数」項目は必須です",
    solution_memory_error_msg_txt: "「メモリ」項目は必須です",
    solution_request_error_msg_txt: "「ベンチマークリクエスト/月」項目は必須です",
    solution_base_price_error_msg_txt: "ソリューションのサブスクリプション価格項目は必須です",
    solution_additional_price_error_msg_txt: "追加ソリューション価格項目は必須です",
    solution_version_error_msg_txt: "バージョン番号項目は必須です",
    solution_version_error_msg_txt_2: "バージョン番号が無効です",
    solution_sourcecode_url_error_msg_txt: "ソースコードのURL項目は必須です",
    solution_sourcecode_url_error_msg_txt_2: "https://example.com のような有効なURLを入力してください",
    solution_update_msg_txt: "ソリューションが正常に更新されました",
    solution_register_msg_txt: "ソリューションが正常に登録されました",
    solution_submit_msg_txt: "ソリューションは正常に提出されました",
    solution_draft_msg_txt: "ソリューションは正常に下書き保存されました",
    content_lang_header_txt: "コンテンツ 言語",
    solution_header_txt: "種類",
    resource_header_txt: "インフラ情報",
    cpu_label_txt: "CPUコア数(最小限)",
    memory_label_txt: "メモリ(GB)(最小限)",
    gpu_label_txt: "GPU(GB)(最小限",
    performance_header_txt: "パフォーマンス",
    benchmark_request_label_txt: "ベンチマークリクエスト/月",
    benchmark_rps_label_txt: "ベンチマークRPS",
    price_header_txt: "価格",
    million_requests_month_txt: "ミリオンリクエスト/月",
    base_price_label_txt_1: "ソリューションの基本価格（USD）",
    base_price_label_txt_2: "基本価格",
    base_infra_price_label_txt: "基本インフラ価格",
    additional_price_label_txt: "追加ソリューション価格（USD）",
    version_label_txt: "バージョン番号",
    source_code_label_txt: "ソースコードのURL",
    sandbox_label_txt: "サンドボックスのURL",
    api_docs_label_txt: "ドキュメンテーションのURL",
    tutorial_label_txt: "チュートリアルのURL",
    select_img_label_txt: "画像を選択",
    cover_img_label_txt: "表紙画像",
    create_industry_header_txt: "業種を追加",
    industry_code_label_txt: "コード",
    industry_name_label_txt: "業種",
    select_solution_type_msg_txt: "ソリューションタイプを選択してください",
  },
  job: {
    side_nav: "バッチ処理",
    header: "バッチ処理一覧",
    re_run: "再実行",
    started_at: "開始日",
    finished_at: "終了日",
    exception: "エラー",
    re_run_job: "バッチ処理を再実行する",
    run: "実行",
  },
  general_settings: {
    sys_name_label_txt: "システム名",
    settings_header_txt: "一般設定",
    currency_label_txt: "表示通貨",
    theme_label_txt: "ダークモード",
  },
  project_settings: {
    train_data: "データトレーニング",
    train_type: "学習タイプ",
    train_history: "トレーニング履歴",
    update_mapper: "マッピング更新",
    settings_initial_setup_info: "トレーニングを開始する前に初期設定を完了してください。",
    create_restricted_msg_txt: "現在、プロジェクトが確認中です。プロジェクトが承認されるまで、認証情報の発行ができません",
    project_locked_msg_txt: "期限までに請求書の支払い確認が取れませんでしたため、プロジェクトをロックしました。ロックを解除するには、請求書の支払いを行なってください。何かご不明な点がございましたらお気軽にご連絡ください。",
  },
  project_credentials: {
    credentials_name_header_txt: "認証情報の名称",
    project_key_label_txt: "プロジェクトキー",
    api_key_label_txt: "APIキー",
    client_key_label_txt: "クライアントキー",
    credentials_label_txt: "認証情報",
    all_credentials_label_txt: "認証情報一覧",
    edit_credential_label_txt: "認証情報の編集",
    add_credential_label_txt: "認証情報を作成する",
    credentials_error_msg_txt: "認証情報の名前が必須です",
    key_type_selector_txt: "キータイプを選択してください",
    project_api_key_tooltip_txt: "サーバー間の統合用。",
    client_key_tooltip_txt: "クライアントとサーバーの統合用",
    whitelisted_domain_label_txt: "許可リストドメイン",
    whitelisted_domain_security_alert_txt: "許可リストにドメインが登録されていません！セキュリティ保護のために、ドメインを許可リストに追加してください。 ドメインを入力した後は、必ず 「Enter」 キーを押してください。",
    whitelisted_domain_validation_txt: 'ドメインは有効な形式に従わなければなりません。例えば、「localhost」、「example.com」、「subdomain.example.com」、または「120.0.0.1」のようにします。スキーム（「https://」）、ページパス（「example.com/page」）、またはポート番号（「localhost:8000」）を含めないでください。',
    credentials_risk_level_high_risk_description_txt: "認証情報が公開されている可能性があります。直ちに対策を講じてセキュリティを確保してください。",
    credentials_risk_level_medium_description_txt: "認証情報にホワイトリストがありません。セキュリティを強化するためにホワイトリスト ドメインを追加してください。",
    credentials_risk_level_no_risk_description_txt: "すべての認証情報は現在安全です。アクションは不要です。",
    whitelisted_domain_placeholder_txt: "ドメインを入力したら Enter を押します",
    credentials_download_json_msg_txt: "ポップアップを閉じる前に、必ず認証情報をダウンロードしてください。一度ポップアップを閉じると、認証情報を再表示できなくなります。",
    download_json_msg_txt: "Jsonをダウンロード",
    key_type_header_txt: "キーの種類",
    label_header_txt: "セキュリティレベル",
    credential_type: {
      project_api_key_txt: "プロジェクトキーおよびAPIキー",
      client_key_txt: "クライアントキー",
    },
  },
  training: {
    all: "全て",
    train: "学習させる",
    training: "トレーニング中",
    items_train: "アイテムトレーニング",
    images_train: "画像トレーニング",
    ranks_train: "ランクトレーニング",
    gpt_train: "GPTトレーニング",
    insight_process: "洞察プロセス",
    train_options: "列車のオプション",
    train_all_item: "すべてのデータをトレーニングする",
    train_updated_item: "更新されたデータを訓練する",
    train_item_within_specified_range: "指定された範囲内のデータをトレーニングします",
    train_all_image: "すべての画像をトレーニング",
    train_image_within_specified_range: "指定された範囲内の画像をトレーニングする",
    date_field_required:"特定の範囲には日付フィールドが必要です",
    start_date_validation: "開始日は終了日よりも前の日付である必要があります",
    end_date_validation: "終了日は開始日よりも後の日付である必要があります",
    training_all_items_warning_txt: "すべての項目をトレーニングするには、多くの時間とリソースが必要となる可能性があります。本当に実行しますか？",
    training_images_warning_txt: "すべての画像をトレーニングするには、多くの時間とリソースが必要となる可能性があります。本当に実行しますか？",
  },
  rank_setting: {
    update_rank_settings: "ランク設定の更新",
    create_rank_settings: "ランク設定の作成",
  },
  insights: {
    search: "検索",
    recommend: "レコメンド",
    orders: "注文",
    revenue: "売上",
    average_cart_size: "平均カートサイズ",
    average_order_value: "平均注文額",
    average_sales_users: "ユーザーあたりの平均売上",
    average_sales_items: "アイテムあたりの平均売上",
    top_users: "トップユーザ",
    top_users_txt: "購入金額上位ユーザー一覧",
    repeated_users: "繰り返しユーザ",
    repeated_users_txt: "リピート ユーザーは、選択した期間内にサイト/アプリに再度アクセスしたユーザーを表します。",
    item: "アイテム",
    user: "ユーザー",
    line_chart: "折れ線グラフ",
    items_counts: "アイテム",
    items_counts_txt: "閲覧、購入、評価アクティビティに基づいて計算された合計アイテム数。",
    users_counts: "ユーザー数",
    users_counts_txt: "閲覧、購入、評価に基づく合計ユーザー数。",
    top_purchased_items: "最も購入されたアイテム",
    top_purchased_items_txt: "最も多く購入されたアイテムのリスト。",
    top_browsed_items: "最も閲覧されたアイテム",
    top_browsed_items_txt: "最も閲覧されたアイテムのリスト。",
    top_rated_items: "高評価アイテム",
    top_rated_items_txt: "最も評価の高いアイテムのリスト。",
    browse: "閲覧",
    rated: "評価された",
    feedback_type_required: "フィードバックのタイプが必要です。",
    positive: "ポジティブ",
    negative: "ネガティブ",
    positive_rated: "肯定的評価",
    negative_rated: "否定的評価",
    purchase: "購入",
    search_txt: "アイテムと画像の総検索数。",
    recommend_txt: "おすすめエンドポイントの合計リクエスト数。",
    orders_txt: "注文の総数。",
    revenue_txt: "購入金額の合計。",
    average_cart_size_txt: "注文ごとの平均アイテム数を計算します。",
    average_sales_orders_txt: "注文数で割った総売上高。",
    average_sales_users_txt: "ユーザー数で割った総売上高。",
    average_sales_items_txt: "アイテム数で割った総売上高。",
    last_updated_at: "最終更新日",
    repeated_users_count: "リピーターユーザー",
    repeated_users_count_txt: "以前の日付範囲と現在の日付範囲を表示、評価、または購入したユーザーの合計数。",
    device_ratio_txt: "デバイス比率",
    browser_ratio_txt: "ブラウザ比率",
    os_ratio_txt: "OS比率",
    show_device_ratio_txt: "エンドユーザーの各プラットフォームまたはデバイスの比率を表示します。",
    show_browser_ratio_txt: "エンドユーザーの各ブラウザの割合を表示します。",
    show_os_ratio_txt: "エンド ユーザーの各オペレーティング システムの比率を表示します。",
    source: "ソース",
    score: "スコア",
    dataset_id: "データセットID",
    index: "インデックス",
  },
  gpt_history: {
    gpt_history_add_document_success: "ドキュメントが正常に追加されました",
    gpt_history_add_document_failed: "ドキュメントの追加に失敗しました",
    gpt_history_filter_endpoints: "エンドポイントで絞り込み",
    gpt_history_filter_feedback: "フィードバックで絞り込む",
    gpt_history_search_query: "クエリで検索",
    gpt_history_search_user_id: "ユーザーIDで検索",
    gpt_flow_history_title: "GPT-Flow 履歴",
    gpt_conversation_history_title:"会話履歴",
    search_txt: "検索",
    gpt_history_date: "日付",
    gpt_history_query: "クエリ",
    gpt_history_response: "応答",
    gpt_history_user_id: "ユーザーID",
    gpt_history_member_id: "メンバーID",
    gpt_history_member_count: "カウント",
    gpt_history_conversation_id: "カンバセーションID",
    gpt_history_feedback: "フィードバック",
    gpt_history_feedback_message: "フィードバックメッセージ",
    gpt_history_consumed_tokens: "トークン",
    gpt_history_processing_time: "処理時間（秒）",
    gpt_history_model: "モデル",
    gpt_history_provider: "プロバイダー",
    gpt_history_endpoint: "エンドポイント",
    gpt_history_temperature: "温度",
    gpt_history_frequency_penalty: "頻度ペナルティ",
    gpt_history_presence_penalty: "プレゼンスペナルティ",
    gpt_history_search_max_token: "検索最大トークン",
    gpt_history_result_includes: "結果に含む",
    gpt_history_profile_name: "プロファイル名",
    gpt_history_system: "システム",
    feedback_type_required: "フィードバックのタイプが必要です。",
    positive: "ポジティブ",
    negative: "ネガティブ",
    positive_rated: "肯定的評価",
    negative_rated: "否定的評価",
    gpt_history_conversation_type: "会話タイプ",
    gpt_history_context_preference: "文脈の好み",
    gpt_history_conversation_metadata: "会話メタデータ",
    gpt_history_conversation_metadata_invalid: "会話メタデータの JSON 形式が無効です",
    gpt_history_completion_tokens: "完了トークン数",
    gpt_history_prompt_tokens: "プロンプトトークン数",
    gpt_history_query_tokens: "クエリトークン数",
    gpt_history_total_tokens: "トークン総数",
    gpt_history_vision_settings: "ビジョン設定",
    gpt_history_image_context_enabled: "画像コンテキスト有効",
    gpt_history_resolution: "解像度",
    gpt_history_references: "参照",
    gpt_history_auto_evaluation: "自動評価",
    gpt_history_response_format: "レスポンスフォーマット",
    gpt_history_chat_history_length: "チャット履歴の長さ",
    gpt_history_personalizer_only: "パーソナライザーのみ",
    gpt_history_intro_token: "イントロトークン",
    gpt_history_system_token: "システムトークン",
    add_to_training_data: "トレーニングデータ",
    add_gpt_response_to_training_data: "GPTのレスポンスをトレーニングデータに追加",
    request_path_header_txt: "リクエストパス",
    api_call_response_summary: "APIコールとレスポンスの要約",
    number_of_request_header_txt: "リクエスト数",
    avg_processing_time_header_txt: "平均処理時間",
    gpt_history_is_evaluation: "評価",
    gpt_history_score: "スコア",
    gpt_conversation_context: "会話コンテキスト",
    gpt_top_references: "トップ参照",
  },
  gpt_dataset: {
    select_dataset: "データセットを選択、",
    search_btn_txt: "データセットの検索",
    dataset_name: "データセットの名称",
    dataset_update: "データセット更新",
    index_column_name: "インデックス列名",
    secondary_index_column: "二次インデックス列名",
    image_url_column: "画像URL列",
    dataset_file: "データセットファイルをアップロードする",
    description_label_txt: "詳細",
    fill_up_index_if_empty: "空の場合はインデックスを埋める",
    create_dataset: "データセット作成",
    add_new_dataset: "新しいデータセットの追加",
    dataset_create_success: "データセットが正常に作成されました",
    dataset_create_failed: "データセットの作成に失敗しました",
    dataset_update_info: "アイテムカタログの更新はこちらから",
    dataset_update_log: "データセット更新ログ",
    dataset_header_txt: "私のデータセット",
    total_label_txt: "合計",
    dataset_document_header_txt: "書類",
    training_in_progress: "トレーニング中",
    dataset_submission_success: "データセット作成リクエストが正常に送信されました",
    dataset_submission_error: "gpt データセット作成リクエストの送信中にエラーが発生しました",
    dataset_update_success: "データセット更新リクエストが正常に送信されました",
    dataset_update_error: "データセットの更新中にエラーが発生しました",
    training_type: "トレーニングタイプ",
    dataset_fetch_error: "データセットの取得中にエラーが発生しました",
    batch_size: "バッチサイズ",
    document_count: "ドキュメント数",
    text_training_type: "テキスト",
    image_training_type: "画像",
    text_trained: "テキストトレーニング",
    image_trained: "画像トレーニング",
    dataset_uploading_progress: "書類をアップロードしています",
    document_upload_header_txt: "書類のアップロード",
    dataset_process_progress: "アップロードしたデータセットを処理中です。処理が完了し次第、学習に進むことができます。",
    confirm_alert_msg_txt_1: "このデータセットを削除してもよろしいですか?",
    confirm_alert_msg_txt_2: "すべてのドキュメントを削除してもよろしいですか?",
    confirm_alert_msg_txt_3: "選択したドキュメントをすべて削除してもよろしいですか?",
    confirm_alert_msg_all_download: "すべてのドキュメントをダウンロードしてもよろしいですか？",
    confirm_alert_msg_selected_download: "選択したドキュメントをダウンロードしてもよろしいですか?",
    delete_dataset_progress_txt: "データセットの削除が進行中です。タスクが完了するまでお待ちください。",
    dataset_filter_menu: {
      all: "すべて",
      idx_column_auto_filled: "ID が自動で生成されたドキュメント",
      idx_column_manually_filled: "ID が手動で生成されたドキュメント",
    },
    active_status: "ステータス",
    active_status_menu: {
      all: "すべて",
      active: "アクティブ",
      archived:  "アーカイブ"
    },
    train_status: "学習ステータス",
    train_status_menu: {
      all: "すべて",
      text_trained: "テキスト学習済み",
      image_trained: "画像学習済み",
      both: "テキストと画像学習済み"
    },
    download: {
      dataset_download_history: "データセットのダウンロード履歴",
    },
  },
  gpt_document: {
    search_btn_text: "文書の検索",
    create_document: "書類",
    upload_csv: "CSV",
    update_document: "ドキュメントを更新す",
    document_key: "キー",
    document_value: "値",
    delete_document_progress_header_txt: "ドキュメントの削除",
    delete_document_progress_txt: "ドキュメントの削除が進行中です",
    file_support_type: "対応フォーマットはCSVとPDF",
    upload_file: "ファイル",
  },
  gpt_profile: {
    stop_hint: "",
    name_header_txt: "GPTのプロフィール名",
    introduction: "応答方針",
    system: "botのペルソナ",
    temperature: "温度（確率分布）",
    top_p: "核サンプリング（思考の柔軟性）",
    frequency_penalty: "頻度ペナルティ",
    presence_penalty: "存在ペナルティ",
    stop: "停止する",
    search_max_token: "検索最大トークン数",
    completion_token: "応答トークン数",
    chat_history_length: "参照する会話履歴数",
    is_personalizer_only: "パーソナライザーの学習データのみを参照",
    is_auto_evaluation: "自動応答評価",
    preferred_content: "優先コンテンツ",
    vision_settings: "ビジョン設定",
    resolution: "解像度",
    image_context_enabled: "画像コンテキスト有効",
    profile_creation_success: "GPT プロファイルが正常に作成されました",
    profile_creation_error: "GPT プロファイルの作成中にエラーが発生しました",
    profile_update_success: "GPT プロファイルが正常に更新されました",
    profile_update_error: "GPT プロファイルの更新中にエラーが発生しました",
    profile_validation_error: "すべての必須項目に入力してください",
    profile_fetch_error: "GPT プロファイルの取得中にエラーが発生しました",
    profiles_fetch_error: "GPT プロファイルの取得中にエラーが発生しました",
    confirm_alert_msg_gpt_setting: "この GPT 設定を削除してもよろしいですか?",
    profile_information: "プロフィール情報",
    default_profile: "テキスト標準プロフィール",
    bot_mode_strict: "厳密な制御",
    default_vision_profile: "テキスト画像プロフィール",
    max_feedback_rating: "評価の上限値",
    summary_settings: "サマリー設定",
    create_settings: "作成する 設定",
    update_settings: "更新する",
    maira_datasets: "Maira データセット",
    maira_settings: "Maira 設定",
    view_details: "詳細を見る",
    create_profile: "プロフィール作成",
    update_profile: "プロフィールを更新する",
    profiles: "プロフィール",
    settings: "設定",
    profileNamePlaceholder: "GPTのプロフィール名を付けてください。",
    randomnessPlaceholder: "ランダム性",
    introductionPlaceholder: "問い合わせに対するbotの対応方法についての詳細な指示　例：簡潔に回答をする。ユーザーが明確に要求していない情報の提供はしない。敬語を使う。",
    systemPlaceholder: "botのペルソナを定義してください。例：“あなたはジョセフであり、X社の専門家であり、あなたの役割は顧客をサポートすることです。”",
    includes: "含むタグ",
    excludes: "除外するタグ",
    dataset_tags: "データセットタグ",
    filterable_fields: "フィルタリング可能なフィールド",
    json_warning: "JSON形式を使用する場合は、システムプロンプトまたは導入部に「json」という単語が含まれていることを確認してください。",
    available_gpt_models: "利用可能な GPT モデル",
    summary_settings_example: "概要設定の例",
    summary_settings_explanation:
      "概要設定は、JSON 形式を入力として受け取ります。 JSON 内には以下のいくつかのフィールドが必要です",
    required_fields: "必須フィールド",
    optional: "オプション",
    exclude_filters: "フィルタを除外する:",
    exclude_filters_text: "概要の生成から除外される会話をフィルターで除外するためのキーと値のペア",
    model: "GPTモデル",
    model_text: "対応機種名.",
    prompt: "プロンプト",
    prompt_text: "概要を生成するための GPT プロンプト",
    interval: "間隔",
    interval_text: "概要の生成間隔。サポートされている間隔は次のとおりです。",
    daily: "毎日",
    List_str: "List[str]: ",
    units: "単位",
    units_text: "要約を生成するためにグループ化される会話に基づくキー",
    chip_hint: "Enterキーを押すことで複数のタグを追加することが可能です",
    filterable_chip_hint: "複数のフィールドを追加するには「Enter」を押してください",
    json_mode_settings: "JSONモード設定:",
    json_mode_settings_explanation: "JSON形式を使用する場合は、システムプロンプトまたは導入文に「json」という単語を含めるようにしてください。",
    stop_sequence: "「Enter」キーを押して、最大4つの停止シーケンスを追加",
    stop_placeholder: "テキスト生成を終了させる停止シーケンス（例：###、STOP、\n\n)",
  },
  questionnaire: {
    questionnaire_logs_title: "アンケートログ",
    questionnaire_id: "アンケート ID",
    user_id: "ユーザーID",
    request_id: "リクエストID",
    created_at: "作成日",
    prompt: "プロンプト",
    response: "応答",
    questionnaire_response_title: "アンケート 応答",
    survey_values: "調査値",
    excludes:"除外項目",
    recommendation: "おすすめ",
    search_questionnaire_id: "アンケートIDを検索",
    search_user_id: "ユーザーIDで検索",
    search_query: "検索クエリ",
  },
  catalog: {
    search: "検索",
    date: "日付",
    upload_catalog_items: "カタログアイテムのアップロード",
    catalog_items: "カタログアイテム",
    catalog_item_details: "カタログ項目の詳細",
    edit_catalog_items: "カタログアイテムの編集",
    item_id: "アイテムID",
    title: "タイトル",
    availability: "在庫状況",
    price: "価格",
    actions: "アクション",
    catalog_item_success_msg_txt: "カタログアイテムが正常に更新されました！",
    confirm_alert_msg_txt: "選択したドキュメントをすべて削除してもよろしいですか?",
  },
  maira_setup: {
    profile_menu_txt: "プロフィール",
    upload_dataset: "データセットのアップロード",
    dataset_training: "学習させる",
  },
  personalizer_setup: {
    select_file: "ファイルを選択",
    map_data: "データマッピング",
    rank_settings: "ランク設定",
    progress: "進行",
  },
  setup_messages: {
    creating_mapper: "マッパーの作成...",
    creating_indices: "インデックスの作成...",
    creating_rank_settings: "ランク設定の作成...",
    uploading_data: "データのアップロード...",
    error_in_completing_the_step: "ステップ完了時のエラー！",
    mapper_created_successfully: "マッパーの作成に成功しました。",
    index_created_successfully: "インデックスは正常に作成されました。",
    rank_settings_created_successfully: "ランク設定が正常に作成されました。",
    data_uploaded_successfully: "データのアップロードに成功しました。",
    flag_value_should_be_boolean: "フラグの値はブール値でなければならない。",
    mapper_updated_successfully: "マッパーの更新に成功",
  },
  download_request: {
    request_instruction: "履歴ボタンをクリックして、ダウンロードリクエストを追跡",
    request_submitted: "リクエスト提出",
    dataset_download_request_txt: "すべてのドキュメントのダウンロードのリクエストが送信されました。",
  },
  invoice: {
    pay: "支払",
    all_state_txt: "全て",
    invoice_created_state_txt: "請求済み",
    pending_state_txt: "確認待ち",
    invoice_cleared_state_txt: "支払い完了",
    overdue_state_txt: "未払い",
    invoice_header_txt: "請求書",
    invoices_header_txt_1: "請求書一覧",
    gigalogy_ai_platform_txt: "Gigalogy AI プラットホーム",
    company_address_line: "1 クロム-28-2 松濤",
    company_address_city: "渋谷区",
    company_address_state_country: "東京 150-0046, 日本",
    bill_to_label_txt: "請求書送付先",
    invoice_id_label_txt: "請求書ID",
    next_invoice_issue_label_txt: "次回請求書発行日",
    next_invoice_due_label_txt: "次回請求書支払期限日",
    invoice_date_header_txt: "請求日",
    due_date_header_txt: "支払期限",
    payment_date_header_txt: "支払日",
    paid_by_label_txt: "支払者：",
    amount_due_header_txt: "未払料金",
    subtotal_label_txt: "小計",
    total_label_txt: "合計",
    total_due_label_txt: "未払金額の合計",
    bill_from_date_header_txt: "請求開始日",
    bill_to_date_header_txt: "現在までの請求金額",
    infra_fee_header_txt: "インフラ料金",
    subscription_fee_header_txt: "サブスクリプション費",
    amount_header_txt: "金額",
    pay_method_label_txt: "支払方法",
    select_method_label_txt: "決済方法を選択",
    payment_ref_header_txt: "お支払いの際は、以下の参考資料をご利用ください",
    bank_account_header_txt: "請求書の金額を次の銀行口座のいずれかに送金し、取引が成功しましたら次のステップへお進みください。",
    bank_account_label_txt: "銀行口座を選択してください",
    bank_name_label_txt: "銀行名",
    bank_code_label_txt: "金融機関コード",
    branch_name_label_txt: "支店名",
    branch_code_label_txt: "支店コード",
    reference_label_txt: "振込人名義",
    total_amount_label_txt: "合計金額",
    bank_transfer_label_txt: "銀行振込",
  },
  validation_msg: {
    url: "https://example.com のような有効な URL を入力してください",
    is_numeric: "{_field_} 数値のみを含むことはできません",
    invalid_filed: "有効な {_field_} を入力してください",
    max_decimal: "{_field_} 小数点以下の桁数が7桁を超えてはいけません",

    number_dash: "有効な {_field_} を入力してください",
    phone_num: "有効な {_field_} を入力してください",
    birth_date: "有効な {_field_} を入力してください",
    postal_code: "有効な {_field_} を入力してください",
  },
  wallet: {
    passcode: "パスコード",
    create_passcode: "パスコードを作成する",
    create_passcode_success: "パスコードが正常に登録されました",
    enter_passcode: "パスコードを入力してください",
    re_enter_passcode: "パスコードの再入力",
    confirm_passcode: "パスコードの確認",
    add_account_message: "ウォレットにアクセスするには、アカウントを追加または作成してください。",
    action_type: "アクションタイプ",
    action_type_tooltip: "アクションタイプ",
    import_account: "アカウントをインポート",
    import: "輸入",
    create: "作成",
    secret_key: "secret キー",
    enter_secret_key: "秘密鍵を入力してください",
    account_name: "アカウント名",
    account_name_tooltip: "アカウント名は、複数のアカウントがある場合に各アカウントを区別するのに役立ちます",
    secret_key_tooltip: "インポートしたいアカウントのsecret キーを表します",
    create_account: "アカウントを作成する",
    create_account_tooltip: "ソースアカウントを使用して、またはソースアカウントなしでアカウントを作成できます。",
    create_account_source: "ソースアカウントを使用",
    create_account_without_source: "ソースアカウントなしで進む",
    source_account_secret: "ソースアカウントのsecret キー",
    source_account_secret_tooltip: "ソースアカウントのsecret キーを表しています",
    starting_balance: "開始残高",
    starting_balance_tooltip: "新しいstellarアカウントに送金したい金額",
    memo: "メモ",
    memo_tooltip: "メモには、このアクションの目的やreference番号など入力できます",
    credentials: "資格情報",
    credentials_download_btn: "Jsonをダウンロード",
    add_account: "アカウントを追加する",
    activate_account_notice: "選択したアカウントはまだ有効化されていません。有効化するには、XLMを入金してください。",
    buy: "買う",
    send_swap: "送信/交換",
    transactions: "トランザクション",
    rewards: "報酬",
    save_secret_key_notice: "弊社のウォレットはお客様の資金を保持していません。より良いユーザー体験を提供するためお客様のsecretキーを暗号化して保存しています。secret キーは安全な場所に保管してください。",
    see_secret_key_btn: "secret キーを表示",
    understood_btn: "同意します",
    passcode_message: "警告：パスコードは、機密キーをアクセスする唯一の方法です。パスコードを他人と共有しないでください。パスコードを紛失した場合、残高とsecret キーは永久に失われ、パスコードなしで再度復元する方法はありません。お客様のsecret キーはサーバーに暗号化された状態で保存されています。パスコードなしで再度取得することができません。",
    selected_account: "選択されたアカウント",
    account_details: "アカウント詳細",
    balance: "総資産",
    buying_liabilities: "購入負債",
    selling_liabilities: "売却負債",
    reserve: "予約",
    base_network_reserve: "ベース ネットワーク予約",
    base_network_reserve_tooltip: "ベース ネットワーク予約",
    doozie_reserve_tooltip: "Doozie 予約",
    per_entry_reserve: "エントリーリザーブあたり",
    per_entry_reserve_tooltip: "エントリーリザーブあたり",
    entry_count: "エントリー数",
    entry_count_tooltip: "エントリー数",
    calculation: "計算",
    total_reserve: "総積立金",
    wallet_account: "ウォレットアカウント",
    wallet_account_status: "アカウントのステータス",
    public_key: "公開鍵",
    wallet_click_for_copy: "クリックしてクリップボードにコピーします。",
    edit_account: "アカウントの編集",
    delete_account: "アカウントを削除する",
    wallet_account_delete_confirmation_msg: "このウォレットアカウントを削除してもよろしいですか？",
    send_or_swap: "送信または交換",
    send_swap_not_possible_msg: "送信および交換アクションを実行できません。暗号化された秘密鍵がブラウザに見つかりません。この操作を行うには、秘密鍵をもう一度入力してください。ありがとうございました！",
    send_swap_can_upto_msg: "最大で送信/交換できます",
    send_swap_action_type: "アクションタイプ",
    send_swap_action_tooltip: "オプションを選択",
    send_swap_send: "送信",
    send_swap_swap: "スワップ",
    destination_public_key_label: "宛先公開鍵",
    destination_public_key_msg: "宛先アカウントの公開鍵を選択します。",
    destination_account: "宛先アカウント",
    destination_account_placeholder: "例: GAGM6XQAUGFE5CMCOHQVW4TJCXJDRLSOM6TJUXCKBDO75DBHJMAEHF2G",
    destination_amount_label: "額",
    destination_amount_msg: "送りたい金額",
    available: "利用可能",
    asset: "資産",
    amount: "額",
    swap: "スワップ",
    swap_asset: "アセットを交換します。",
    from_asset: "アセットから",
    from_amount: "金額から",
    to_asset: "資産へ",
    to_amount_name: "金額に",
    to_amount_label: "金額まで（推定）",
    next_update_will: "次のアップデートは",
    exchange_rate: "為替レート",
    allowed_slippage_label: "許容スリッページ:",
    allowed_slippage_message: "価格がこのパーセンテージを超えて不利に変化した場合、取引は元に戻ります。",
    minimum_received_label: "最小受信：",
    minimum_received_message: "スワップが完了すると受け取るトークンの最小量。",
    path_label: "道：",
    path_message: "オーダーブックと流動性レートに基づいてこれらのトークンをルーティングすることで、スワップの最良の価格が得られました。",
    send_swap_account: "アカウント",
    send_to: "に送る",
    transaction_success: "トランザクションの成功",
    created_at: "作成日",
    transaction_id: "トランザクションID",
    open_stellar_network_txt: "恒星ネットワークで開く",
    buy_history: "購入履歴",
    buy_xlm_btn: "XLM を購入する",
    buy_lumens_txt: "ルーメン（XLM）を購入する",
    buy_lumens_by_credit_card: "デビットカードまたはクレジットカードを使用して、Stellar Lumens (XLM) を購入します",
    currency: "通貨",
    gateway: "ゲートウェイ",
    buy_with_wyre: "Wyreで購入",
    destination: "行き先",
    from: "から",
    account: "アカウント",
    address: "住所",
    dzt_rewards: "報酬",
    rewards_pending: "保留中",
    rewards_claimed: "主張",
    rewards_claimed_not_possible_msg: "報酬を請求することができません。暗号化された機密キーがブラウザ上で見つかりません。報酬を請求するには、機密キーをもう一度入力してください。",
    claim: "請求",
    reward_claimed: "報酬請求",
    no_more_pending_reward: "保留中の報酬はありません。",
    total_paid: "支払総額",
    source_amount: "ソース金額",
    received_amount: "受入金額",
    transaction_fee: "取引手数料",
    click_to_show_secret: "クリックすると秘密鍵が表示されます。",
    secret_save_msg: "秘密鍵は安全な場所に保管してください。",
    failed_to_send: "送信できませんでした",
    holding_balance: "保有残高（DZT） オーバー",
    reward_percentage: "報酬率",
    reward_percentage_details: "特典の割合の詳細",
    dzt_holding_balance_text: "現在のDZTの保有残高は",
    dzt_reward_percentage_text_1: "取引手数料の",
    dzt_reward_percentage_text_2: "をDZTで報酬として受け取ることができます。",
    dzt_increase_text: "DZTの残高を増やすことで、決済手数料の還元を受けることができます。",
    minimum_buy: "ミニマムバイ",
    minimum_buy_amount_text: "{_source_currency_}の最低購入額はドル",
    track_buy: "クリックしてwyreで追跡",
    track: "追跡",
    doozie_wallet_label_txt: "Doozieウォレット",
  },
  logs_file_generation: {
    conversation_logs_file_generation_title: "会話履歴ファイル生成リクエスト",
    questionnaire_logs_file_generation_title: "アンケートログファイルの生成をリクエスト",
    conversation_logs_file_generated_history_title: "会話ログファイル生成履歴",
    questionnaire_logs_file_generated_history_title: "アンケートログファイルの生成履歴",
    download_file_url_text: "ここをクリックしてファイルをダウンロードしてください。",
    date_range: "日付範囲",
    file_url: "ファイル",
    expiration: "有効期限",
    expired: "期限切れ",
    file_already_exists: "ファイルはすでに生成され、S3 バケットに保存されています。",
    file_not_exists: "ファイル生成タスクが正常にスケジュールされました。",
    file_generation_failed: "ファイルの生成に失敗しました。",
    date_range_validation: "日付範囲は必須であり、終了日は開始日以上である必要があります",
    request_date: "リクエスト日",
    conversation_history_download_request_txt: "会話履歴のダウンロードリクエストが送信されました。ダウンロードリクエストを追跡するには、「履歴」ボタンをクリックしてください。",
    questionnaire_logs_download_request_txt: "アンケートログのダウンロードリクエストが送信されました。ダウンロードリクエストを追跡するには、「履歴」ボタンをクリックしてください。",
    files_already_exist_txt: "指定された日付範囲のファイルはすでに存在しています。指定された日付範囲",
    generate_conversation_history_instruction: "この日付範囲内で新しい会話履歴ファイルを生成するには「生成」ボタンをクリックしてください。",
    generate_questionnaire_logs_instruction: "この日付範囲内で新しいアンケートログファイルを生成するには「生成」ボタンをクリックしてください。",
  },
  logs: {
    questionnaire_logs_title: "アンケートログ",
  },
  filter: {
    questionnaire_id: "アンケートIDを検索",
    user_id: "ユーザーIDで検索",
    search_query: "検索クエリ",
  },
  gpt_history_details: {
    share_credentials_header: "プロジェクト資格情報の共有",
    share_credentials_info: "プロジェクトの認証情報を入力して、会話の詳細を共有します。",
    credential_form: {
      project_key_required_txt: "API キーを提供する場合はプロジェクト キーが必要です。",
      api_key_required_txt: "プロジェクトキーを指定する場合はAPIキーが必要です。",
      client_key_required_txt: "他の資格情報が指定されていない場合は、クライアント キーを指定する必要があります。",
    },
  },
  embeddingModel: {
    embedding_model_creation_success: "埋め込みモデルが正常に作成されました",
    embedding_model_creation_error: "埋め込みモデルの作成中にエラーが発生しました",
    embedding_model_title: "埋め込みモデル",
    embedding_model_update: "埋め込みモデルの更新",
    embedding_model_placeholder: "埋め込みモデル",
    model_required: "モデルは必須です",
    model_message: "現時点でのプロジェクトにおける埋め込みモデル更新は推奨していません。\n変更すると、プロジェクト内のすべてのデータセットを再学習する必要があります。"
  },
  maira: {
    greeting: "こんにちは、本日は何をお探しでしょうか"
  }
};
