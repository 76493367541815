<template>
  <div>
    <v-container fill-height fluid>
      <v-row>
        <v-col class="pa-0" xs="12" md="12">
          <v-card outlined class="mx-auto px-2">
            <v-row>
              <v-col md="6">
                <div class="px-2 primary--text font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.member.members_header") }}
                </div>
              </v-col>

              <v-col md="6">
                <v-dialog
                  v-if="is_admin"
                  v-model="invitationModal"
                  persistent
                  max-width="700px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="float-right text-capitalize"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $vuetify.lang.t("$vuetify.member.invite") }}
                    </v-btn>
                  </template>
                  <v-card>
                    <div class="px-3 py-3 primary--text font-weight-bold">
                      {{ $vuetify.lang.t("$vuetify.member.modal_title") }}
                    </div>
                    <v-divider></v-divider>

                    <v-card-text>
                      <v-container class="px-0 pb-0">
                        <v-form ref="invitationForm" @submit.prevent="sendInvitation()">
                          <v-row class="mt-0">
                            <v-col cols="4" sm="4" class="pb-0">
                              <v-subheader>{{ $vuetify.lang.t("$vuetify.member.email") }}</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="8" class="pb-0 d-flex ">
                              <v-text-field
                                v-model="selectedNewMember.email"
                                placeholder="adam@gigalogy.com"
                                outlined
                                dense
                                clearable
                                type="email"
                                :rules="[required($vuetify.lang.t('$vuetify.member.email')), email('Email')]"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>

                          <!-- ROLE -->
                          <v-row class="mt-0">
                            <v-col cols="4" sm="4" class="py-0">
                              <v-subheader>{{ $vuetify.lang.t("$vuetify.member.role") }}</v-subheader>
                            </v-col>
                            <v-col cols="8" sm="8" class="py-0">
                              <form autocomplete="off" @submit.prevent>
                                <v-autocomplete
                                  :items="role_list"
                                  :label="$vuetify.lang.t('$vuetify.member.role')"
                                  item-text="text"
                                  item-value="value"
                                  :placeholder="$vuetify.lang.t('$vuetify.member.choose_one_placeholder_txt')"
                                  v-model="selectedNewMember.role"
                                  :rules="[required($vuetify.lang.t('$vuetify.member.role'))]"
                                  outlined
                                  dense
                                ></v-autocomplete>
                              </form>
                            </v-col>
                          </v-row>

                          <!-- FIRST NAME -->
                          <v-row class="mt-0">
                            <v-col cols="4" sm="4" class="py-0">
                              <v-subheader>
                                {{ this.$vuetify.lang.t("$vuetify.member.first_name") }}
                              </v-subheader>
                            </v-col>
                            <v-col cols="8" sm="8" class="py-0">
                              <v-text-field
                                :label="this.$vuetify.lang.t('$vuetify.member.first_name')"
                                v-model="selectedNewMember.first_name"
                                outlined
                                dense
                                :readonly="isReadonly"
                              ></v-text-field>
                            </v-col>
                          </v-row>

                          <!-- LAST NAME -->
                          <v-row class="mt-0 ">
                            <v-col cols="4" sm="4" class="py-0">
                              <v-subheader>
                                {{ this.$vuetify.lang.t("$vuetify.member.last_name") }}
                              </v-subheader>
                            </v-col>
                            <v-col cols="8" sm="8" class="py-0">
                              <v-text-field
                                :label="this.$vuetify.lang.t('$vuetify.member.last_name')"
                                v-model="selectedNewMember.last_name"
                                autocomplete="off"
                                outlined
                                dense
                                :readonly="isReadonly"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>

                    <v-divider></v-divider>
                    <v-card-actions >
                      <v-spacer></v-spacer>
                      <v-btn
                        type="reset"
                        class="red--text font-weight-bold"
                        text
                        @click.prevent="cancelInvitation"
                      >
                        {{ this.$vuetify.lang.t("$vuetify.common.cancel_btn") }}
                      </v-btn>
                      <v-btn
                        class="font-weight-bold"
                        color="primary"
                        :loading="enable_btn_loading"
                        :disabled="(!(selectedNewMember.email && selectedNewMember.role))"
                        @click="sendInvitation"
                      >
                        {{ this.$vuetify.lang.t("$vuetify.common.send_btn") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
            <v-divider></v-divider>

            <v-row>
              <v-col cols="12" xs="12" md="4">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$vuetify.lang.t('$vuetify.member.search')"
                  outlined
                  dense
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <Loader v-if="isLoading" class="mr-2 mt-6" />
            <v-data-table
              v-else
              :headers="headers"
              :items="allMembers.items"
              :sort-by="['created_at']"
              :sort-desc="[true]"
              :search="search"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              hide-default-footer

            >
              <template v-slot:[`item.id`]="{item}">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{item.id.split('-')[0]}}</span>
                  </template>
                  <span>{{item.id}}</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.first_name`]="{ item }">
                <span>{{item.first_name}} {{item.last_name}}</span>
              </template>

              <template v-slot:[`item.email`]="{ item }">
                <span :class="['d-inline-inherit', { 'font-weight-bold': !!item.is_account_owner }]">
                  {{ item.email }}
                  <v-icon class="mr-1" v-if="item.email_verified" small color="green">
                    mdi-check-decagram
                  </v-icon>
                  <v-icon  v-if="item.is_account_owner" small color="yellow darken-3">
                    mdi-shield-crown
                  </v-icon>
                </span>
              </template>

              <template v-slot:[`item.created_at`]="{ item }">
                {{
                  item.created_at ?
                    new Date(item.created_at).toLocaleDateString(
                      "en-us", { year: "numeric", month: "short", day: "numeric", }) : 'N/A'
                }}
              </template>

              <template v-slot:[`item.role`]="{ item }">
                <v-chip
                  v-if="!is_admin || (is_admin && loggedin_user===item.id) ||(!!item.is_account_owner )"
                  class="text-uppercase"
                  :color="( loggedin_user===item.id) ? 'blue' : 'success'"
                  outlined
                  small
                >
                  {{ item.role }}
                </v-chip>
                <v-select
                  v-else
                  class="custom-width text-uppercase"
                  :items="role_list"
                  :placeholder="item?.role?.toUpperCase()"
                  item-text="text"
                  item-value="value"
                  v-model="item.role"
                  dense
                  outlined
                  v-on:change="changeEvent('role', item, $event )"
                ></v-select>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  v-if="!is_admin || (is_admin && loggedin_user===item.id) ||(!!item.is_account_owner )"
                  small
                  outlined
                  class="text-uppercase"
                  :color="getColorOfStatus(item.status)"
                >
                  {{ item.status === 'created' ? "Invited" : item.status }}
                </v-chip>

                <v-select
                  v-else
                  class="custom-width "
                  :items="getStatusList(item.status)"
                  item-text="text"
                  item-value="value"
                  v-model="item.status"
                  :placeholder="(item?.status==='created' ? 'Invited' : item?.status).toUpperCase()"
                  dense
                  outlined
                  v-on:change="changeEvent('status', item, $event)"
                ></v-select>
              </template>

              <template v-slot:[`item.action`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      @click="memberDetails(item)"
                      v-bind="attrs"
                      v-on="on"
                    ><v-icon small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $vuetify.lang.t("$vuetify.member.details") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>

            <v-row>
              <v-col class="py-0" cols="12" md="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12" xs="12" sm="3" md="3"></v-col>
              <v-col cols="12" xs="12" sm="6" md="6">
                <v-pagination
                  class="text-center"
                  v-model="page"
                  :length="pageCount"
                ></v-pagination>
              </v-col>
              <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
                <v-select
                  v-model="itemsPerPage"
                  :items="rows"
                  hide-details="auto"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <DialogModal
      v-model="confirm_dialog"
      :title="this.$vuetify.lang.t('$vuetify.common.confirm_alert_title_txt')"
      :message="dialog_msg"
      @onCancel="cancelUpdate()"
      @onConfirmation="onUpdateConfirmed()"
    />
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters,
  mapState,
  mapMutations
} from "vuex";

import {
  LOAD_ALL_MEMBERS,
  SEND_MEMBER_INVITATION,
  UPDATE_MEMBER,
  FETCH_USER_STATUS
} from "@/store/_actiontypes";
import {
  SET_SELECTED_MEMBER,
  SET_SEARCHED_EMAIL,
  SET_SEARCHED_MEMBERS
} from "@/store/_mutationtypes";
import {
  TEXT
} from "@/components/common/input/_inputTypes";
import validations from "@/helpers/validations";
import DialogModal from "@/components/common/DialogModal";
import Loader from "@/components/common/loader/Loader";
import { Role } from "@/helpers/role";
import { authorize } from "@/helpers/helper";
import jwt_decode from "jwt-decode";

export default {
  components: {
    Loader,
    DialogModal,
  },
  props: {
    role_list: {
      type: Array,
      default() {
        return [
          {
            text: "USER",
            value: "user",
          },
          {
            text: "ADMIN",
            value: "admin",
          }
        ];
      }
    },
    status_list: {
      type: Array,
      default() {
        return [
          {
            text: "Active",
            value: "active",
          },
          {
            text: "Inactive",
            value: "inactive",
          },
          {
            text: "Invited",
            value:"invited"
          },
          {
            text: "Expired",
            value:"expired"
          },
          {
            text: "Canceled",
            value:"canceled"
          }
        ];
      }
    },
    headers: {
      type: Array,
      default() {
        return [
          {
            text:  this.$vuetify.lang.t("$vuetify.member.id"),
            align: "start",
            sortable: true,
            value: "id",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.member.full_name"),
            value: "first_name",
            sortable: true,
            align: "start",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.member.email"),
            align: "start",
            sortable: true,
            value: "email",
            cellClass: "d-inline-inherit"
          },
          {
            text: this.$vuetify.lang.t("$vuetify.table_header.created_at"),
            align: "start",
            sortable: true,
            value: "created_at",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.member.role"),
            align: "start",
            sortable: true,
            value: "role",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.table_header.status"),
            align: "start",
            sortable: true,
            value: "status",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.table_header.actions"),
            align: "center",
            value: "action",
            sortable: true,
          }
        ]
      }
    }
  },
  computed: {
    ...mapState({
      organization: (state) => state.account.organization,
      user: (state) => state.account.user,
    }),
    ...mapState('member',[
      'allMembers',
      'searchedMembers',
      'selectedMember',
      'searchedEmail'
    ]),
    ...mapGetters("loader", ["loading"]),
    pageCount() {
      if (this.allMembers && this.allMembers.total) {
        const count = Math.ceil(this.allMembers.total / (this.itemsPerPage || 1));
        return count > 0 ? count : 1; // Ensure it is at least 1
      }
      return 1;
    }
  },
  mounted() {
    this.getAllMembersFunction(this.page, this.itemsPerPage);
  },
  data() {
    return {
      is_admin: authorize([Role.ADMIN]),
      loggedin_user: '',
      invitationModal: false,
      enable_btn_loading: false,
      disable_submit_btn: true,
      confirm_dialog: false,
      change_action: '',
      change_request_id: '',
      change_event_val: '',
      dialog_msg: '',
      invitationForm: {
        email: "",
        role: "",
        first_name: "",
        last_name: "",
      },
      selectedNewMember:{
        email: "",
        role: "",
        first_name: "",
        last_name: '',
      },
      isReadonly: false,
      isLoading: false,
      TEXT: TEXT,
      detailsDialog: false,
      ...validations,
      search: '',
      page: 1,
      itemsPerPage: 10,
      rows: [ 5, 10, 20 ],
      search2: null,
      hideCard: true,
      debounceTimeout: null,
    };
  },
  watch: {
    //TODO invitation input loader needed
    search2 (val) {
      clearTimeout(this.debounceTimeout);
      // Set a new debounce timeout
      this.debounceTimeout = setTimeout(() => {

        if (val && val !== this.select) {
          this.querySelections(val);
        }
      }, 500); // 500ms delay for debouncing

    },
    page: {
      handler(value) {
        this.getAllMembers(this.page, this.itemsPerPage);
      },
      immediate: false,
    },
    itemsPerPage: {
      handler(value) {
        this.getAllMembers(this.page, this.itemsPerPage);
      },
      immediate: false,
    },
  },

  methods: {
    ...mapActions("member", [
      LOAD_ALL_MEMBERS,
      SEND_MEMBER_INVITATION,
      UPDATE_MEMBER,
      FETCH_USER_STATUS,
      SET_SELECTED_MEMBER
    ]),
    ...mapMutations('member', [
      SET_SELECTED_MEMBER,
      SET_SEARCHED_EMAIL,
      SET_SEARCHED_MEMBERS
    ]),
    getAllMembers(start,size) {
      this.isLoading = true;
      this.getAllMembersFunction(start,size);
    },
    getStatusList(currentStatus) {
      switch (currentStatus) {
        case "invited":
          return [{ text: "Cancel", value: "canceled" }];
        case "expired":
          return [{ text: "Re-Invite", value: "invited" }];
        case "active":
          return [{ text: "Inactive", value: "inactive" }];
        case "inactive":
          return [{ text: "Active", value: "active" }];
        case "canceled":
          return [{ text: "Re-Invite", value: "invited" }];
        default:
          return this.status_list;
      }
    },
    getAllMembersFunction(start,size) {
      this.loggedin_user = this.loggedinUserId()
      this.LOAD_ALL_MEMBERS({start,size}).then(
        (_response) => {
          this.isLoading = false;
        },
        (_error) => {
          this.isLoading = false;
        }
      );
    },
    memberDetails(item) {
      this.$router.push("/members/" + item.id);
    },
    sendInvitation(){
      if(!this.$refs.invitationForm.validate()) return;

      const {email,role,first_name,last_name} = this.selectedNewMember;
      if(email && role){
        this.enable_btn_loading = true;
        this.disable_submit_btn = true;

        this.SEND_MEMBER_INVITATION({
          email: email,
          role: role,
          first_name: first_name,
          last_name: last_name
        }).then((_res) => {
          this.enable_btn_loading = false;
          this.disable_submit_btn = false;
          this.invitationModal = false;
          this.$refs.invitationForm.reset();
          this.selectedNewMember ={
            email: "",
            role: "",
            first_name: "",
            last_name: "",
          };
          this.getAllMembersFunction(this.page, this.itemsPerPage)
        }).catch((err) => {
          this.enable_btn_loading = false;
          this.disable_submit_btn = false;
          console.error("Error in sendInvitation:", err);
        });
      }
    },
    cancelInvitation(){
      this.invitationModal = false;
      this.enable_btn_loading = false;
      this.disable_submit_btn = false;
      this.$refs.invitationForm.reset();
    },
    cancelUpdate(){
      this.confirm_dialog = false;
      this.getAllMembersFunction(this.page, this.itemsPerPage);
    },
    changeEvent(action, item, event){
      let dialog_msg = this.$vuetify.lang.t("$vuetify.member.role_dialog_msg");
      if(action === "status"){
        dialog_msg = this.$vuetify.lang.t("$vuetify.member.status_dialog_msg");
      }
      this.dialog_msg = dialog_msg.replace("__email__", item.email);
      this.confirm_dialog = true;
      this.change_action = action;
      this.change_request_id = item.id;
      this.change_event_val = event;
    },
    onUpdateConfirmed(){
      this.confirm_dialog = false;
      this.isLoading = true;

      this.UPDATE_MEMBER({
        member_id: this.change_request_id,
        action: this.change_action,
        value: this.change_event_val
      }).then((_res) => {
        this.isLoading = false;
        this.getAllMembersFunction(this.page, this.itemsPerPage);
      }).catch((_err) => {
        this.getAllMembersFunction(this.page, this.itemsPerPage);
        this.isLoading = false;
      });
    },
    getColorOfStatus(status) {
      switch (status) {
        case "verified":
          return "green";
        case "pending":
          return "orange";
        case "invited":
          return "blue";
        case "expired":
          return "gray";
        case "active":
          return "green";
        case "inactive":
          return "red";
        case "canceled":
          return "black";
        default:
          return "red"; // Default color for unhandled statuses
      }
    },
    loggedinUserId(){
      let user_data = jwt_decode(this.user.access_token)
      return user_data.sub
    },
    querySelections(val) {
      this.FETCH_USER_STATUS(val)

    },
  },
};
</script>

<style>
.custom-width{
  width: 115px;
  margin-bottom: -26px !important;
}


</style>
