<template>
    <v-app>
      <v-main>
        <v-container text-center id="login-page">
          <v-layout align-center justify-center row wrap>
            <v-flex xs12 sm8 md4>
              <h2 class="primary--text mt-16" v-if="!verificationComplete">
                {{ $vuetify.lang.t("$vuetify.verify_msg_txt") }}
              </h2>
            </v-flex>
          </v-layout>
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  import { mapState, mapActions } from "vuex";
  import getEnv from "@/config/env";
  import { VERIFY_EMAIL, LOAD_ORGANIZATION,VERIFY_MEMBER} from "@/store/_actiontypes";
  import router from "@/router/index";
  import jwt_decode from "jwt-decode";
  import { getCurrentUser } from "@/helpers/helper";
  
  export default {
    components: {},
    data() {
      return {
        verificationComplete: false,
      };
    },
    computed: {
      ...mapState({
        loading: (state) => state.loader.loading,
        user: (state) => state.account.user,
        organization: (state) => state.account.organization,
      }),
    },
    mounted() {
      if(getEnv("VUE_APP_CAPTCHA_ENABLED")){
        const plugin = document.createElement("script");
        plugin.setAttribute(
          "src",
          `https://www.google.com/recaptcha/api.js?render=${getEnv("VUE_APP_CAPTCHA_SITE_KEY")}`
        );
        plugin.async = true;
        document.head.appendChild(plugin);
      }
      this.verifyInviteLink();
    },
    methods: {
      ...mapActions("account", [VERIFY_MEMBER]),
      ...mapActions("account", [LOAD_ORGANIZATION]),
      verifyInviteLink() {
        if(getEnv("VUE_APP_CAPTCHA_ENABLED")){
          setTimeout(
            function () {
              if (
                typeof grecaptcha === "undefined" ||
                typeof grecaptcha.render === "undefined"
              ) {
              this.verifyInviteLink();
              } else {
                grecaptcha
                  .execute(getEnv("VUE_APP_CAPTCHA_SITE_KEY"), {action: 'verify_email'})
                  .then((recaptcha_token) => {
                    this.verifyMemberLink(recaptcha_token);
                  });
              }
            }.bind(this),
            10
          );
        } else {
          this.verifyMemberLink(null);
        }
      },
      // verifyResetPasswordLink(recaptcha_token) {
      //   this.verificationComplete = false;
      //   this.VERIFY_EMAIL({
      //     token: this.$route.params.member_token,
      //     verify_method: this.$route.params.verify_method,
      //     recaptcha_token: recaptcha_token
      //   })
      //     .then(
      //       (res) => {
      //         if (res.status === 200) {
      //           this.verificationComplete = true;
      //           if (this.user) {
      //             this.LOAD_ORGANIZATION()
      //               .then((res) => {
      //                 let parsedJWTJson = jwt_decode(this.user.access_token);
      //                 let user_id = parsedJWTJson["sub"];
      //                 let user_hash = parsedJWTJson["iuh"];
      //                 router.push("/setup-accounts").catch(() => {});
      //               })
      //               .catch(() => {});
      //           }
      //         }
      //       },
      //       (error) => {
      //         this.verificationComplete = true;
      //         router.push("/404");
      //       }
      //     )
      //     .catch((err) => {
      //       this.verificationComplete = true;
      //       router.push("/404");
      //     });
      // },

      verifyMemberLink(recaptcha_token) {
        this.verificationComplete = false;

                      // this.verificationComplete = true;
  
                      // const  parsedURLToken = jwt_decode(this.$route.params.member_token);
                      // const  account_id = parsedURLToken["account_id"];
                      // const user_id = parsedURLToken["sub"]
                      // console.log("account_id ,user_id}", account_id ,user_id);


                      //      this.CREATE_ACCOUNT({account_id ,user_id}).then(
                        
                      //   (res)=>{
                      //     console.log("DOONE RESPPNSE 2222222",res);
                      //     if (res.status === 200) {
                      //       this.verificationComplete = true;
                      //       this.addAccountToDB(res)
                      //       if (this.user) {
                      //         let parsedToken = jwt_decode(this.user.access_token);
                      //         let existedUserId = parsedToken["sub"];
                      //         let parsedURLToken = jwt_decode(this.$route.params.member_token)
                      //         const urlUserId = parsedURLToken["sub"]
                      //         if (existedUserId === urlUserId) {
                      //           router.push("/").catch(() => {});
                      //         } else {
                      //           router.push('/login').catch(() => {});
                      //         }  
                      //       } else {
                      //         router.push('/login').catch(() => {});
                      //       } 
                      //     }
                      //   }
                      // ).catch(err => {
                      //   console.log("ERRRR 11111111 ");
                      //     this.verificationComplete = true;
                      //     router.push("/404");
                      //   }
                      // )


      



        
        this.VERIFY_MEMBER({
          token: this.$route.params.member_token,
          verify_method: this.$route.params.verify_method,
          recaptcha_token: recaptcha_token
        }).then(
            (res) => {
              if (res.status === 200) {
                      // this.verificationComplete = true;
                      if (res.status === 200) {
                            this.verificationComplete = true;
                            // this.addAccountToDB(res)
                            if (this.user) {
                              let parsedToken = jwt_decode(this.user.access_token);
                              let existedUserId = parsedToken["sub"];
                              let parsedURLToken = jwt_decode(this.$route.params.member_token)
                              const urlUserId = parsedURLToken["sub"]
                              if (existedUserId === urlUserId) {
         
                                router.push("/").catch(() => {});
                              } else {
            
                                router.push('/login').catch(() => {});
                              }  
                            } else {
                              router.push('/login').catch(() => {});
                            } 
                          }

  
                      // const  parsedURLToken = jwt_decode(this.$route.params.member_token);
                      // const  account_id = parsedURLToken["account_id"];
                      // const user_id = parsedURLToken["sub"]
                      // console.log("account_id ,user_id}", account_id ,user_id);
                      // this.CREATE_ACCOUNT({account_id ,user_id}).then(
                        
                      //   (res)=>{
                      //     console.log("DOONE RESPPNSE 2222222",res);
                      //     if (res.status === 200) {
                      //       this.verificationComplete = true;
                      //       this.addAccountToDB(res)
                      //       if (this.user) {
                      //         let parsedToken = jwt_decode(this.user.access_token);
                      //         let existedUserId = parsedToken["sub"];
                      //         let parsedURLToken = jwt_decode(this.$route.params.member_token)
                      //         const urlUserId = parsedURLToken["sub"]
                      //         if (existedUserId === urlUserId) {
                      //           router.push("/").catch(() => {});
                      //         } else {
                      //           router.push('/login').catch(() => {});
                      //         }  
                      //       } else {
                      //         router.push('/login').catch(() => {});
                      //       } 
                      //     }
                      //   }
                      // ).catch(err => {
                      //   console.log("ERRRR 11111111 ");
                      //     this.verificationComplete = true;
                      //     router.push("/404");
                      //   }
                      // )
              }
            },
            (error) => {
              this.verificationComplete = true;
              router.push("/404");
            }
          )
          .catch((err) => {
            this.verificationComplete = true;
            router.push("/404");
          });
      },
      // addAccountToDB(){
      //   const  parsedURLToken = jwt_decode(this.$route.params.member_token);
      //   const  account_id = parsedURLToken["account_id"];
      //   const user_id = parsedURLToken["sub"]
      //   this.CREATE_ACCOUNT({account_id ,user_id}).then(
      //     (res)=>{
      //       if (res.status === 200) {
      //         this.verificationComplete = true;
      //         this.addAccountToDB(res)
      //         if (this.user) {
      //           let parsedToken = jwt_decode(this.user.access_token);
      //           let existedUserId = parsedToken["sub"];
      //           let parsedURLToken = jwt_decode(this.$route.params.member_token)
      //           const urlUserId = parsedURLToken["sub"]
      //           if (existedUserId === urlUserId) {
      //             router.push("/").catch(() => {});
      //           } else {
      //             router.push('/login').catch(() => {});
      //           }  
      //         } else {
      //           router.push('/login').catch(() => {});
      //         } 
      //       }
      //     }
      //   ).catch(err => {
      //       this.verificationComplete = true;
      //       router.push("/404");
      //     }
      //   )
      // }
    },
  };
  </script>
  