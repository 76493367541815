<template>
  <v-skeleton-loader
    v-if="!profile"
    class="mr-2 mt-6"
    type="list-item-three-line"
  ></v-skeleton-loader>

  <v-form v-else ref="profile_form" lazy-validation>
    <v-row>
      <v-col cols="12" md="12" xs="12">
        <v-text-field
          type="text"
          v-model="profile.profileName"
          :rules="[required($vuetify.lang.t('$vuetify.gpt_profile.name_header_txt'))]"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.name_header_txt')"
          :placeholder="
            $vuetify.lang.t('$vuetify.gpt_profile.profileNamePlaceholder')
          "
          outlined
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
          v-model="gpt_randomness"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.randomnessPlaceholder')"
          :items="gptRandomnessOptions"
          item-text="name"
          item-value="value"
          :placeholder="
            $vuetify.lang.t('$vuetify.gpt_profile.randomnessPlaceholder')
          "
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" xs="12">
        <v-textarea
          v-model="profile.intro"
          :rules="[
            required($vuetify.lang.t('$vuetify.gpt_profile.introduction')),
          ]"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.introduction')"
          outlined
          :placeholder="
            $vuetify.lang.t('$vuetify.gpt_profile.introductionPlaceholder')
          "
          type="text"
        ></v-textarea>
      </v-col>
      <v-col cols="12" xs="12">
        <v-textarea
          v-model="profile.system"
          :rules="[required($vuetify.lang.t('$vuetify.gpt_profile.system'))]"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.system')"
          :placeholder="
            $vuetify.lang.t('$vuetify.gpt_profile.systemPlaceholder')
          "
          required
          outlined
        ></v-textarea>
      </v-col>
      <v-col cols="12">
          <v-combobox
            v-model="profile.stop"
            :items="[]"
            :label="$vuetify.lang.t('$vuetify.tooltip.stop')"
            variant="solo"
            small-chips
            clearable
            outlined
            multiple
            closable
            :placeholder="$vuetify.lang.t('$vuetify.gpt_profile.stop_placeholder')"
            :hint="$vuetify.lang.t('$vuetify.gpt_profile.stop_sequence')"
            class="no-dropdown-icon"
          >
            <template v-slot:selection="{ attrs, item, select,  selected }">
              <v-chip v-bind="attrs" :model-value="selected" @click="select" @click:close="removeStop(item)" close>
                <span>{{ item }}</span
                >&nbsp;
              </v-chip>
            </template>
          </v-combobox>
        </v-col>
      <v-col cols="12" md="6" xs="12">
        <v-autocomplete
          v-model="profile.provider"
          :items="gptProviderList"
          item-text="display"
          item-value="provider"
          :label='$vuetify.lang.t("$vuetify.gpt_history.gpt_history_provider")'
          outlined
          required
          :rules="[required($vuetify.lang.t('$vuetify.gpt_history.gpt_history_provider'))]"
        >
          <template v-slot:selection="{ item }">
            <span>{{ item }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span>{{ item }}</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" xs="12">
        <v-autocomplete
          v-model="profile.model"
          :items="gptModelList.filter(model => model.provider === profile.provider)"
          item-text="display"
          item-value="model"
          :label='$vuetify.lang.t("$vuetify.gpt_profile.model")'
          outlined
          required
          :rules="[required($vuetify.lang.t('$vuetify.gpt_profile.model'))]"
          :filter="customFilter"
          :menu-props="{ maxHeight: '400px' }"
        >
          <template v-slot:selection="{ item }">
            <span>{{ item.model }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span>{{ item.model }}</span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6" xs="12">
        <v-text-field
          v-model="profile.temperature"
          :rules="temperatureRules"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.temperature')"
          outlined
          type="number"
          step="0.1"
          min="0"
          max="2"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="profile.top_p"
          :rules="topPRules"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.top_p')"
          outlined
          type="number"
          step="0.1"
          min="0"
          max="1"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="profile.frequencyPenalty"
          :rules="temperatureRules"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.frequency_penalty')"
          outlined
          type="number"
          step="0.1"
          min="0"
          max="2"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="profile.presencePenalty"
          :rules="temperatureRules"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.presence_penalty')"
          outlined
          type="number"
          step="0.1"
          min="0"
          max="2"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="profile.searchMaxToken"
          :rules="searchTokenRules"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.search_max_token')"
          outlined
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="profile.completionToken"
          :rules="completionTokenRules"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.completion_token')"
          outlined
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          v-model="profile.chat_history_length"
          :rules="chatHistoryLengthRules"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.chat_history_length')"
          outlined
          type="number"
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="profile.is_personalizer_only"
          :items="personalizerOnlyItems"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.is_personalizer_only')"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-select
          v-model="profile.is_auto_evaluation"
          :items="isAutoEvaluation"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.is_auto_evaluation')"
          outlined
        ></v-select>
      </v-col>
      <v-col cols="12">
        <p>{{ $vuetify.lang.t("$vuetify.gpt_profile.dataset_tags") }}:</p>
      </v-col>
      <v-col cols="12" md="12">
        <v-combobox
          v-model="profile.datasetTags.includes"
          :items="[]"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.includes')"
          variant="solo"
          chips
          clearable
          outlined
          multiple
          :hint="$vuetify.lang.t('$vuetify.gpt_profile.chip_hint')"
          class="no-dropdown-icon"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip v-bind="attrs" @click="select"  @click:close="removeIncludes(item)" :model-value="selected" closable close>
              <span>{{ item }}</span
              >&nbsp;
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="12" md="12">
        <v-combobox
          v-model="profile.datasetTags.excludes"
          :items="[]"
          :label="$vuetify.lang.t('$vuetify.gpt_profile.excludes')"
          variant="solo"
          chips
          clearable
          outlined
          multiple
          :hint="$vuetify.lang.t('$vuetify.gpt_profile.chip_hint')"
          class="no-dropdown-icon"
          close
        >
          <template v-slot:selection="{ attrs, select, item, selected }">
            <v-chip v-bind="attrs" @click="select" :model-value="selected" @click:close="removeExcludes(item)" close  closable>
              <span>{{ item }}</span
              >&nbsp;
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
    <p v-if="isVisionSupported">
      {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_vision_settings") }}:
    </p>
    <v-select
      v-if="isVisionSupported"
      v-model="profile.visionSettings.resolution"
      :items="['low', 'high']"
      :label="$vuetify.lang.t('$vuetify.gpt_history.gpt_history_resolution')"
      outlined
      required
      :rules="[required($vuetify.lang.t('$vuetify.gpt_history.gpt_history_resolution'))]"
    ></v-select>
    <v-checkbox
      v-if="isVisionSupported"
      v-model="profile.visionSettings.imageContextEnabled"
      :label="$vuetify.lang.t('$vuetify.gpt_history.gpt_history_image_context_enabled')"
    ></v-checkbox>
    <template v-if="isJsonSupported">
      <v-row>
        <v-col cols="12">
          <p>{{ $vuetify.lang.t("$vuetify.gpt_profile.json_mode_settings") }}</p>
        </v-col>
        <v-col cols="12" md="12">
          <v-select
            v-model="profile.responseFormat"
            :items="filteredResponseFormatOptions"
            label="Response Format"
            outlined
            class="mt-n4"
          ></v-select>
        </v-col>
      </v-row>
      <v-row v-if="profile.responseFormat === 'json_object'">
        <v-col cols="12">
          <v-alert
            type="info"
            outlined
            class="mt-n10">
            {{ $vuetify.lang.t("$vuetify.gpt_profile.json_warning") }}
          </v-alert>
        </v-col>
      </v-row>
    </template>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { gptProfileInitialData } from "@/store/modules/project";
import { UPDATE_GPT_PROFILE_STATE, GET_GPT_MODEL_DETAILS } from "@/store/_actiontypes";
import {
  PROMPT_TEMPLATE_SEPARATORS_TOKEN_COUNT,
} from "@/global/_constants";

export default {
  props: ["update"],
  computed: {
    ...mapState({
      profile: state => state.project.selectedGptProfile,
      gptModelList: state => state.project.gptModels,
      gptProviderList: state => state.project.gptProviders,
    }),
    filteredResponseFormatOptions() {
      if (this.isJsonSupported) {
        return [
          { value: 'json_object', text: 'JSON Format' },
          { value: 'text', text: 'Text' }
        ];
      } else {
        return [
          { value: 'text', text: 'Text' }
        ];
      }
    },
  },
  data() {
    return {
      temperatureRules: [
        value => value <= 2 || "Max value is 2",
        value => value >= 0 || "Min value 0",
      ],
      topPRules: [
        value => value <= 1 || "Max value is 1",
        value => value >= 0 || "Min value 0",
      ],
      completionTokenRules: [],
      profileLoader: true,
      maxToken: 32000,
      localProfile: this.profile,
      searchTokenRules: [
        value => value <= this.maxToken || `Max value is ${this.maxToken}`,
        value => value >= 0 || "Min value 0",
      ],
      chatHistoryLengthRules: [
        value => value >= 0 || "Value must be 0 or greater",
        value => Number.isInteger(Number(value)) || "Value must be an integer",
      ],
      personalizerOnlyItems: [
        { text: "true", value: true },
        { text: "false", value: false },
      ],
      isAutoEvaluation: [
        { text: "true", value: true },
        { text: "false", value: false },
      ],
      gptRandomnessOptions: [
        {
          value: "accurate_ans",
          name: "Generate answer only from given data",
        },
        {
          value: "generalized_ans",
          name: "Include its general knowledge with the answer",
        },
      ],
      gpt_randomness: null,
      introductionTemplates: {
        accurate_ans: `Please answer the query in the same language as the query using the provided context as truthfully as possible. You will be provided with \`Reference Context\` and a \`Query\`. Your task is to analyze the \`Reference Context\` and prepare response for the \`Query\`. If you can not find the answer in the provided context say you don't have knowledge about this question.`,
        generalized_ans: `Please answer the query in the same language as the query using the provided context as truthfully as possible. You will be provided with \`Reference Context\` and a \`Query\`. Your task is to analyze the \`Reference Context\` and prepare response for the \`Query\`. If the \`Reference Context\` is not relevant, use your own knowledge to reply or say that you don't know how to respond if your knowledge is not sufficient to answer.`,
      },
      isVisionSupported: false,
      isJsonSupported: false,
      supportedTokenSize: {},
    };
  },
  watch: {
    "profile.stop"(newVal) {
      if (newVal.length > 4) {
        this.profile.stop = newVal.slice(0, 4);
      }
    },
    profile: {
      deep: true,
      async handler(value) {
        setTimeout(async () => {
          if (this.profile.model !== "") {
            this.maxToken = await this.calculateMaxToken();
          }
        }, 1500);
        if (!value.chat_history_length && value.chat_history_length !== 0) {
          this.$set(this.profile, "chat_history_length", 0);
        }
        if (value.is_personalizer_only === undefined) {
          this.$set(this.profile, "is_personalizer_only", false);
        }
        if (value.is_auto_evaluation === undefined) {
          this.$set(this.profile, "is_auto_evaluation", false);
        }
        const localProfile = this.profile;
        this.UPDATE_GPT_PROFILE_STATE({ profile: localProfile });
      },
    },
    isJsonSupported(newValue) {
      if (!newValue && this.profile.responseFormat === 'json_object') {
        this.profile.responseFormat = 'text';
      }
    },
    "profile.model": {
      handler(newModel) {
        if (newModel) {
          this.gptModelDetails();
        }
      },
      immediate: true
    },
    gpt_randomness: {
      handler(newValue) {
        if (newValue && this.introductionTemplates[newValue]) {
          this.$set(
            this.profile,
            "intro",
            this.introductionTemplates[newValue],
          );
        }
      },
    },
  },
  mounted() {},
  methods: {
    ...mapActions("project", [UPDATE_GPT_PROFILE_STATE, GET_GPT_MODEL_DETAILS]),
    required(fieldName) {
      return (value) => {
        return !!value || `${fieldName}${this.$vuetify.lang.t('$vuetify.validation.required_error_msg_txt')}`;
      };
    },
    validateForm() {
      return this.$refs.profile_form.validate();
    },
    removeStop(item) {
      this.profile.stop.splice(this.profile.stop.indexOf(item), 1)
    },
    removeIncludes(item){
      this.profile.datasetTags.includes.splice(this.profile.datasetTags.includes.indexOf(item), 1)
    },
    removeExcludes(item){
      this.profile.datasetTags.excludes.splice(this.profile.datasetTags.excludes.indexOf(item), 1)
    },
    async calculateMaxToken() {
      try {
        const tokenizer = await this.$initTokenizer("gpt-3.5-turbo");

        const introTokens = this.profile.intro
          ? tokenizer.encode(this.profile.intro)
          : [];
        const systemTokens = this.profile.system
          ? tokenizer.encode(this.profile.system)
          : [];
        const completionToken = Number.isNaN(
          parseInt(this.profile.completionToken),
        )
          ? 0
          : parseInt(this.profile.completionToken);

        // tokenizer.free();

        const model = this.profile.model;
        if (!Object.prototype.hasOwnProperty.call(this.supportedTokenSize, model)) {
          // Handle undefined model. Return a safe default.
          return 0;
        }

        const modelWindow = this.supportedTokenSize[model];
        const availableTokens =
          modelWindow -
          (introTokens.length +
            systemTokens.length +
            completionToken +
            PROMPT_TEMPLATE_SEPARATORS_TOKEN_COUNT);
        return Math.ceil(availableTokens - modelWindow * 0.1);
      } catch (error) {
        console.error("Error in calculateMaxToken:", error);
        // Handle error appropriately
      }
    },
    resetValidation() {
      if (this.$refs.profile_form) {
        this.$refs.profile_form.resetValidation();
      }
      this.UPDATE_GPT_PROFILE_STATE({
        profile: { ...gptProfileInitialData },
      });
      this.maxToken = 32000;
      this.profileLoader = true;
      this.gpt_randomness = null;
      this.temperatureRules = [
        value => value <= 2 || "Max value is 2",
        value => value >= 0 || "Min value 0",
      ];
      this.topPRules = [
        value => value <= 1 || "Max value is 1",
        value => value >= 0 || "Min value 0",
      ];
      // this.completionTokenRules = [
      //   value => value <= 2500 || "Max value is 2500",
      //   value => value >= 0 || "Min value 0",
      // ];
      this.searchTokenRules = [
        value => value <= this.maxToken || `Max value is ${this.maxToken}`,
        value => value >= 0 || "Min value 0",
      ];
      this.chatHistoryLengthRules = [
        value => value >= 0 || "Value must be 0 or greater",
        value => Number.isInteger(Number(value)) || "Value must be an integer",
      ];
       if (this.modelDetails) {
      this.updateTokenLimits(this.modelDetails);
       }
    },
    gptModelDetails() {
      return this.GET_GPT_MODEL_DETAILS({
        project_id: this.$route.params.project_id,
        provider: this.profile.provider,
        model: this.profile.model
      }).then(
        (response) => {
          const modelDetails = response.data?.detail?.response;
          if (modelDetails) {
            this.isVisionSupported = modelDetails.is_vision_supported;
            this.isJsonSupported = modelDetails.is_json_supported;
            this.updateTokenLimits(modelDetails);
          }
          this.isLoading = false;
          return modelDetails;
        },
        (err) => {
          this.isLoading = false;
          return null;
        }
      );
    },
    updateTokenLimits(modelDetails) {
      const supportedTokenSize = modelDetails.supported_token_size;
      const maxOutputTokenSize = modelDetails.max_output_token_size;
      this.$set(this.supportedTokenSize, this.profile.model, supportedTokenSize);
      this.completionTokenRules = [
      value => !!value || 'Completion token is required',
      value => value > 0 || 'Value must be greater than 0',
      value => value <= maxOutputTokenSize || `Max value is ${maxOutputTokenSize}`,
    ];
    },
     customFilter(item, queryText) {
       if (!item || !queryText)
         return true;
        const searchText = queryText.toLowerCase();
        const modelName = (item.model || '').toLowerCase();
        const displayName = (item.display || '').toLowerCase();
         return modelName.includes(searchText) ||
              displayName.includes(searchText);
      },
  },
};
</script>

<style scoped>
.datasets-tag-text {
  padding-bottom: 0 !important;
}

.no-dropdown-icon >>> .v-input__append-inner .v-icon {
  display: none !important;
}
</style>
