<template>
  <div>
    <Navbar :isProfile="$route.path === '/profile-settings' || $route.path === '/account-settings'">
      <v-container
        pt-1
        :class="{'singleAppbar': $vuetify.breakpoint.smAndDown, 'doubleAppbar': $vuetify.breakpoint.mdAndUp}"
      >
      <v-banner
      v-if="Object.keys(organization).length && !organization.email_verified"
      class="mx-0 banner-style"
      
      app
      outlined
      single-line
    >
      <v-row align="center" justify="center">
        <v-icon class="mx-2" slot="icon" color="rgba(241, 101, 8, 0.986)" size="30">
          warning_amber
        </v-icon>
        <h4 class="mr-2">{{ $vuetify.lang.t("$vuetify.registration.resend_verification_email_msg_txt_2") }}</h4>
        <v-btn
          color="primary"
          small
          outlined
          @click="handleSubmitClick()"
          :loading="emailSendLoading"
        >{{ $vuetify.lang.t("$vuetify.registration.resend_verification_email_btn_txt") }}</v-btn>
      </v-row>
    </v-banner>
        <v-main class="pt-1 pl-1">
          <v-breadcrumbs :items="crumbs">
            <template v-slot:divider>
              <v-icon>mdi-forward</v-icon>
            </template>
          </v-breadcrumbs>
          <router-view></router-view>
        </v-main>
        <MainFooter />
      </v-container>
    </Navbar>
  </div>
</template>

<script>

import { mapState, mapGetters, mapActions } from "vuex";
import Navbar from "@/components/TheNavbar";
import TheProgressBar from "@/components/TheProgressBar";
import getEnv from "@/config/env";
import {
    LOAD_ORGANIZATION,
    SWITCH_ACCOOUNT,
    REFRESHTOKEN,
    LOAD_ACCOUNT,
    LOGOUT,
    RESEND_VERIFICATION_EMAIL,
    ADD_ALERT,
    WALLET_REVOKE
} from "@/store/_actiontypes";
import MainFooter from "@/components/layout/MainFooter.vue";
export default {
  components: {
    Navbar,
    MainFooter,
  },
  computed: {
    ...mapState({
      // theme: state => (state.account.user ? state.account.user.theme : "")
    }),
    ...mapState({
            organization: (state) => {
              return state.account.organization
            },
        }),
    crumbs: function() {
      let pathArray = this.$route.path.split("/")
      pathArray.shift()
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        let routeelements = pathArray.slice(0, idx);
        breadcrumbArray.push({
            path: path,
            href: routeelements.length === 0 ? "/" : "/"+ routeelements.join("/") + "/" + path,
            text: idx===0 ? path.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : path,
            disabled: true,
          });
        return breadcrumbArray;
      }, [])
      return breadcrumbs;
    },
    
  },
  watch: {
    // theme(newTheme, oldTheme) {
    //   this.$vuetify.theme.dark = newTheme === "dark";
    // }
  },
  methods: {
    ...mapActions("account", [
            LOAD_ORGANIZATION,
            SWITCH_ACCOOUNT,
            REFRESHTOKEN,
            LOAD_ACCOUNT,
            LOGOUT,
            RESEND_VERIFICATION_EMAIL
        ]),
    handleSubmit(recaptcha_token) {
            this.emailSendLoading = true;
            this.LOAD_ORGANIZATION().then(
                (response) => {
                    if (response) {
                        if (this.organization.email_verified) {
                            this.$store.dispatch(
                                `alert/${ADD_ALERT}`, {
                                    message: this.$vuetify.lang.t("$vuetify.account_already_verified"),
                                    color: "error",
                                }, { root: true }
                            );
                        } else {
                            this.RESEND_VERIFICATION_EMAIL({ email: this.organization.email, recaptcha_token }).then((res) => {
                                this.emailSendLoading = false;
                            }).catch(err => {
                                this.emailSendLoading = false;
                            });
                        }
                    }
                },
                (error) => {}
            );
        },
    handleSubmitClick() {
            if (getEnv("VUE_APP_CAPTCHA_ENABLED")) {
                grecaptcha.execute(getEnv("VUE_APP_CAPTCHA_SITE_KEY"), { action: 'resend_verify_email' }).then((recaptcha_token) => {
                    this.handleSubmit(recaptcha_token);
                });
            } else {
                this.handleSubmit(null);
            }
        },
  },
  data() {
    return {
      emailSendLoading: false,
    };
  },
};
</script>
<style>
/* scroll bar style */
::-webkit-scrollbar-track {
  --webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #757575;
}

.form-label label[for] {
  height: 20px;
  font-size: 10pt;
}

.tableHeader {
  height: 20px;
  font-size: 16px !important;
}

.singleAppbar {
  margin-top: 48px;
}

.doubleAppbar {
  margin-top: 10px;
}

.v-select__slot {
  font-size: smaller;
}

.v-text-field__slot {
  font-size: smaller;
}

.v-breadcrumbs {
  padding-top: 58px !important;
  padding-left: 1px !important;
  padding-bottom: 2px !important;
}
@media screen and (max-width: 950px) {
  .v-breadcrumbs {
    padding-top: 10px !important;
  }
}
</style>
