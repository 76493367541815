import { render, staticRenderFns } from "./VerifyMember.vue?vue&type=template&id=97b94a6c"
import script from "./VerifyMember.vue?vue&type=script&lang=js"
export * from "./VerifyMember.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports