<template>
  <v-app :key="locale">
    <Snackbar />
    <router-view :key="this.$route.path"> </router-view>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import Snackbar from "@/components/common/Snackbar.vue";
import storageMixin from "@/mixins/storageMixin";

export default {
  name: "App",
  components: {Snackbar},
  mixins: [storageMixin],
  computed: {
    ...mapState({
      // user: (state) => state.account.user,
      locale: (state) => state.alert.locale,
    }),
  },
};

(function () {
  function widgetApi() {
    return new Promise((resolve) => {
      let timeoutId;

      const getApi = () => {
        const event = new Event('getWidgetApi');
        timeoutId = window.setTimeout(getApi, 1000);
        window.dispatchEvent(event);
      };

      const onWidgetApi = (e) => {
        const api = e.detail;
        window.clearTimeout(timeoutId);
        resolve(api);
      };

      window.addEventListener('widgetApi', onWidgetApi, { once: true });
      getApi();
    });
  }
  const customColorSet = {
    // "--message-header-background-color": "#YOUR_MESSAGE_HEADER_BACKGROUND_COLOR",
  };
  const customTheme='#0a97d9';
  widgetApi().then(api=>{
    // クライアントのウェブサイトのUSER_IDとMEMBER_IDを渡します。（ログイン時）
    // api.updateIdentifiers({userId: 'YOUR_USER_ID', memberId: 'YOUR_MEMBER_ID'});
    api.sendConfig({theme:customTheme,colorSet:customColorSet});
  });
})();
    
</script>
