<template>
  <!-- <div> -->
  <v-container>
    <v-row>
      <v-col class="pa-0" xs="12" md="12">
        <v-card class="mx-auto px-2" outlined>
          <v-row>
            <v-col xs="12" md="6">
              <div class="px-2 primary--text font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_profile.profiles") }}
              </div>
            </v-col>
            <v-col xs="12" md="6">
              <template>
                <v-btn
                  class="float-right"
                  color="primary"
                  :disabled="$authorize([$role.USER])"
                  @click="onCreateClick"
                >
                  {{$vuetify.lang.t('$vuetify.gpt_profile.create_profile')}}
                  <v-icon right class="ml-2">add_circle_outline</v-icon>
                </v-btn>
              </template>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <!-- <v-col cols="12" xs="12" md="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$vuetify.lang.t('$vuetify.common.search_btn_txt')"
                outlined
                dense
                single-line
                hide-details
              ></v-text-field>
            </v-col> -->
          </v-row>
          <v-divider></v-divider>
          <Loader v-if="isLoading" class="mr-2 mt-6" />
          <v-data-table
            v-else
            :headers="headers"
            :items="gptProfiles.profiles"
            :search="search"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
          >
            <template v-slot:[`item.id`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    {{item.id.split("-")[0]}}
                  </span>
                </template>
                <span>{{ item.id }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.name`]="{ item }">
              <div>
                <v-btn
                  text
                  class="primary--text text--darken-3"
                  @click="onDetailsClick(item)"
                >{{ item.name }}</v-btn>
              </div>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              {{
                item.created_at
                  ? new Date(item.created_at).toLocaleDateString("en-us", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                  : "N/A"
              }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <ButtonGroup
                :disabledEditButton="$authorize([$role.USER])"
                :disabledDeleteButton="$authorize([$role.USER])"
                :groupType="ACTION_BUTTON_GROUP"
                :addButton="false"
                :stopButton="false"
                :startButton="false"
                @onDeleteClick="deleteItem(item)"
                @onEditClick="onEditClick(item)"
                @onDetailsClick="onDetailsClick(item)"
              />
            </template>
          </v-data-table>
          <v-row>
            <v-col class="py-0" cols="12" md="12">
              <v-divider></v-divider>
            </v-col>
            <v-col cols="12" xs="12" sm="3" md="3"></v-col>
            <v-col cols="12" xs="12" sm="6" md="6">
              <v-pagination
                class="text-center"
                v-model="page"
                :length="pageCount"
              ></v-pagination>
            </v-col>
            <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
              <v-select
                v-model="itemsPerPage"
                :items="rows"
                hide-details="auto"
                outlined
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <DialogModal
      v-model="deleteDialog"
      :title="$vuetify.lang.t('$vuetify.common.confirm_alert_title_txt')"
      :message="$vuetify.lang.t('$vuetify.common.confirm_alert_msg_txt_6')"
      :confirmButtonText="$vuetify.lang.t('$vuetify.common.confirm_btn')"
      :cancelButtonText="$vuetify.lang.t('$vuetify.common.cancel_btn')"
      @onConfirmation="deleteProfile()"
      @onCancel="deleteDialog = false"
    />
    <!-- create profile -->
    <v-dialog persistent v-model="openCreateProfileModal" max-width="100%">
      <v-card
        class="d-flex flex-column"
        style="overflow-y: scroll; overflow-x: hidden"
      >
        <Loader v-if="profileEdit && profileLoading" class="mr-2 mt-6" />
        <v-container v-else>
          <v-row>
            <v-card-title class="font-weight-bold">
              {{ profileEdit ? $vuetify.lang.t('$vuetify.gpt_profile.update_profile') : $vuetify.lang.t('$vuetify.gpt_profile.create_profile') }}
            </v-card-title>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <CreateGptProfile ref="profile" />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <v-card-actions class="justify-end">
                <v-btn
                  color="error"
                  outlined
                  @click="closeProfileModal()"
                >
                  {{$vuetify.lang.t('$vuetify.common.close_btn')}}
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  @click="profileEdit ? updateProfile() : createProfile()"
                  :loading="isLoading"
                >
                  {{ profileEdit ? $vuetify.lang.t('$vuetify.common.update_btn') : $vuetify.lang.t('$vuetify.common.create') }}
                  <v-progress-circular v-if="isLoading" indeterminate color="white"></v-progress-circular>
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- view profile -->
    <v-dialog persistent v-model="openProfileViewModal" max-width="100%">
      <v-card
        outlined
        class="d-flex flex-column"
        style="overflow-y: scroll; overflow-x: hidden"
      >
        <Loader v-if="viewProfile && profileLoading" class="mr-2 mt-6" />

        <v-container v-else>
          <v-row>
            <v-card-title class="font-weight-bold">
              {{ $vuetify.lang.t("$vuetify.gpt_profile.profile_information") }}
            </v-card-title>
          </v-row>
          <v-divider></v-divider>
          <v-list>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                ID
              </v-list-item-content>
              <v-list-item-content class="d-flex justify-space-between align-center">
                <span>
                  {{ selectedGptProfile.id }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="copyToClipboard(selectedGptProfile.id)"
                        class="hover-blue"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                    </template>
                    <span>Copy</span>
                  </v-tooltip>
                </span>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.table_header.name_header_txt") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.profileName }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_profile.introduction") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.intro }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_system") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.system }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_model") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.model }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_provider") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ profile.provider }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_temperature") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.temperature }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_profile.top_p") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.top_p }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_profile.search_max_token") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.searchMaxToken}}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_completion_tokens") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.completionToken }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_frequency_penalty") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.frequencyPenalty }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_presence_penalty") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.presencePenalty }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_profile.stop") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <template v-if="!selectedGptProfile.stop || selectedGptProfile.stop.length === 0">
                  N/A
                </template>
                <template v-else>
                  {{ selectedGptProfile.stop.join(', ') }}
                </template>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_profile.dataset_tags") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                <template v-if="(!selectedGptProfile.datasetTags || (selectedGptProfile.datasetTags.includes.length === 0 && selectedGptProfile.datasetTags.excludes.length === 0))">
                  N/A
                </template>
                <template v-else>
                  <span v-if="selectedGptProfile.datasetTags.includes.length > 0">
                    include: {{ selectedGptProfile.datasetTags.includes.join(', ') }}
                  </span>
                  <span v-if="selectedGptProfile.datasetTags.excludes.length > 0">
                    exclude: {{ selectedGptProfile.datasetTags.excludes.join(', ') }}
                  </span>
                </template>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_auto_evaluation") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.is_auto_evaluation }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_personalizer_only") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.is_personalizer_only }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_chat_history_length") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.chat_history_length }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_intro_token") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.introToken }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_system_token") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.systemToken }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isJsonSupported">
              <v-list-item-content class="font-weight-bold">
                {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_response_format") }}
              </v-list-item-content>
              <v-list-item-content class="align-end">
                {{ selectedGptProfile.responseFormat }}
              </v-list-item-content>
            </v-list-item>
            <template v-if="isVisionSupported">
              <v-list-item>
                <v-list-item-content class="font-weight-bold">
                  {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_vision_settings") }}:
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="font-weight-bold pl-4">
                  {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_resolution") }}
                </v-list-item-content>
                <v-list-item-content class="align-end pr-4">
                 {{ selectedGptProfile.visionSettings?.resolution }}
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content class="font-weight-bold pl-4">
                  {{ $vuetify.lang.t("$vuetify.gpt_history.gpt_history_image_context_enabled") }}
                </v-list-item-content>
                <v-list-item-content class="align-end pr-4">
                  {{ selectedGptProfile.visionSettings?.imageContextEnabled }}
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <v-divider></v-divider>
          <v-row>
            <v-col>
              <v-card-actions class="justify-end">
                <v-btn
                  color="error"
                  outlined
                  text
                  @click="closeProfileDetailModal()"
                >{{$vuetify.lang.t('$vuetify.common.close_btn')}}</v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </v-container>
  <!-- </div> -->
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  SEGMENTS
} from '@/global/_constants';
import {
  GET_GPT_PROFILES,
  DELETE_GPT_PROFILE,
  UPDATE_GPT_PROFILE_STATE,
  CREATE_GPT_PROFILE,
  ADD_ALERT,
  GET_GPT_PROFILE_BY_ID,
  UPDATE_GPT_PROFILE_API,
  GET_GPT_SETTINGS,
  CREATE_GPT_SETTINGS,
  GET_GPT_MODEL_DETAILS
} from "@/store/_actiontypes";
import Loader from "@/components/common/loader/Loader";
import { ACTION_BUTTON_GROUP } from "@/components/common/buttongroup/_buttongrouptypes";
import ButtonGroup from "@/components/common/buttongroup/ButtonGroup";
import DialogModal from "@/components/common/DialogModal";
import CreateGptProfile from "@/components/Projects/CreateGptProfile/CreateGptProfile";
import { GptProfileMapper } from "@/helpers/dataMappers/gptProfile";

export default {
  mounted() {
    this.getGPTProfiles();
  },
  computed: {
    ...mapState({
      selectedProject: (state) => state.project.selectedProject,
      gptProfiles: (state) => state.project.gptProfiles,
      selectedGptProfile: (state) => state.project.selectedGptProfile,
      gptModelList: (state) => state.project.gptModels,
      profile: (state) => state.project.selectedGptProfile,
      gptModelsDetails: (state) => state.project.gptModelsDetails,
      pageCount(state) {
        if (state.project.gptProfiles.total_hits) {
          const count = Math.ceil(
            state.project.gptProfiles.total_hits / this.itemsPerPage
          );
          return count;
        } else return 1;
      },
    }),
  },
  components: { Loader, ButtonGroup, DialogModal, CreateGptProfile },
  props: {
    tabName: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      PROJECT_SEGMENTS: SEGMENTS,
      profileEdit: false,
      isLoading: false,
      openProfileViewModal: false,
      viewProfile: false,
      search: "",
      profileLoading: false,
      itemsPerPage: 10,
      page: 1,
      deleteDialog: false,
      toBeDeletedProfileId: null,
      ACTION_BUTTON_GROUP: ACTION_BUTTON_GROUP,
      rows: [5, 10, 20],
      openCreateProfileModal: false,
      isVisionSupported: false,
      isJsonSupported: false,
      headers: [
        {
          text: "Id",
          align: "center",
          value: "id",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.name_header_txt"),
          align: "center",
          value: "name",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.gpt_history.gpt_history_model"),
          value: "model",
          align: "center",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.created_at"),
          value: "created_at",
          align: "center",
          sortable: true,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.table_header.actions"),
          value: "actions",
          sortable: false,
          align: "start",
        },
      ],
    };
  },
  watch: {
    page: {
      handler() {
        const start = (this.page - 1) * this.itemsPerPage;
        const size = this.itemsPerPage;
        this.getGPTProfiles(start, size);
      },
      immediate: false,
    },
    itemsPerPage: {
      handler(newVal) {
        if (this.page === 1) {
          const start = 0;
          const size = newVal;
          this.getGPTProfiles(start, size);
        } else {
          this.page = 1;
        }
      },
      immediate: false,
    },
    tabName: {
      immediate: true,
      handler(newVal) {
        if (newVal === "gptProfiles") {
          this.getGPTProfiles();
        }
      },
    },
  },
  methods: {
    ...mapActions("project", [
      GET_GPT_PROFILES,
      DELETE_GPT_PROFILE,
      UPDATE_GPT_PROFILE_STATE,
      CREATE_GPT_PROFILE,
      GET_GPT_PROFILE_BY_ID,
      UPDATE_GPT_PROFILE_API,
      GET_GPT_SETTINGS,
      CREATE_GPT_SETTINGS,
      GET_GPT_MODEL_DETAILS
    ]),

    closeProfileModal() {
      this.openCreateProfileModal = false;
      this.profileEdit = false;
    },
    async getGPTProfiles(start = 0, size = 10) {
      this.isLoading = true;
      this.profileLoading = true;

      return this.GET_GPT_PROFILES({
        project_id: this.$route.params.project_id,
        start,
        size,
      }).then(
        (res) => {
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          console.error("err", err.response);
        }
      );
    },
    deleteItem(item) {
      this.deleteDialog = true;
      this.toBeDeletedProfileId = item.id;
    },
    closeProfileDetailModal() {
      this.viewProfile = false;
      this.openProfileViewModal = false;
    },
    onCreateClick() {
      if (this.$refs.profile) {
        this.$refs.profile.resetValidation();
      }
      this.profileEdit = false;
      this.openCreateProfileModal = true;

      // Reset the Vuex `profile` state to default values
      this.UPDATE_GPT_PROFILE_STATE({
        profile: GptProfileMapper.mapBackendToFrontend(),
      });
    },
    onEditClick(item) {
      this.profileEdit = true;
      this.profileLoading = true;
      this.openCreateProfileModal = true;
      this.GET_GPT_PROFILE_BY_ID({
        project_id: this.$route.params.project_id,
        profile_id: item.id,
      })
        .then((res) => {
          this.profileLoading = false;
        })
        .catch((e) => {
          this.profileLoading = false;
        });
    },
    onDetailsClick(item) {
      this.openProfileViewModal = true;
      this.profileLoading = true;
      this.viewProfile = true;
      this.GET_GPT_PROFILE_BY_ID({
        project_id: this.$route.params.project_id,
        profile_id: item.id,
      }).then((res) => {
        this.profileLoading = false;
        this.gptModelDetails();
        this.viewProfile = false;
      }).catch((e) => {
        this.viewProfile = false;
        this.profileLoading = false;
      });
    },

    deleteProfile() {
      this.deleteDialog = false;
      this.DELETE_GPT_PROFILE({
        project_id: this.selectedProject.id,
        profile_id: this.toBeDeletedProfileId,
      }).then((res) => {
        this.isLoading = true;
        setTimeout(async () => {
          await this.getGPTProfiles();
          this.isLoading = false;
        }, 2000);
      }).catch((e) => {
        console.error("error", e);
      });
    },
    createProfile() {
      const valid = this.$refs.profile.validateForm();
      if (!valid) {
        this.$store.dispatch(
          `alert/${ADD_ALERT}`,
          {
            message: this.$vuetify.lang.t("$vuetify.gpt_profile.profile_validation_error"),
            color: "red",
          },
          { root: true }
        );
        return;
      }
      this.isLoading = true;
      //adding setTimeout to add includes or excludes unconfirmed chip to be added to the list
      setTimeout(()=> {
        this.CREATE_GPT_PROFILE({
        project_id: this.$route.params.project_id,
        profile: this.selectedGptProfile,
      }).then(
        (res) => {
          this.$store.dispatch(
            `alert/${ADD_ALERT}`,
            {
              message: this.$vuetify.lang.t(
                "$vuetify.gpt_profile.profile_creation_success"
              ),
              color: "success",
            },
            { root: true }
          );
          // set default profile when creating 1st profile from Personalizer
          if(this.selectedProject.segment.name === this.PROJECT_SEGMENTS.PERSONALIZER){
            this.GET_GPT_SETTINGS({
            project_id: this.$route.params.project_id,
          }).then((res2) => {
            if (!res2.data?.detail?.settings) {
              this.CREATE_GPT_SETTINGS({
                project_id: this.$route.params.project_id,
                default_profile: res.data?.detail?.profile_id,
              }).then((_res) => {
              }).catch((_err) => {
              });
            }
          });
          }
          this.isLoading = true;
          this.closeProfileModal();
          setTimeout(async () => {
            await this.getGPTProfiles();
            this.isLoading = false;
          }, 2000);
        },
        (err) => {
          console.error("Err", err.response);
          this.isLoading = false;
          this.$store.dispatch(
            `alert/${ADD_ALERT}`,
            {
              message:
                err.response?.data?.detail?.response ||
                this.$vuetify.lang.t(
                  "$vuetify.gpt_profile.profile_creation_error"
                ),
              color: "error",
            },
            { root: true }
          );
        }
      );
      }, 100)
    },
    updateProfile() {
      const valid = this.$refs.profile.validateForm();
      if (!valid) {
        this.$store.dispatch(
          `alert/${ADD_ALERT}`,
          {
            message: this.$vuetify.lang.t("$vuetify.gpt_profile.profile_validation_error"),
            color: "red",
          },
          { root: true }
        );
        return;
      }
      this.isLoading = true;
      //adding setTimeout to add includes or excludes unconfirmed chip to be added to the list
      setTimeout(()=> {
        this.UPDATE_GPT_PROFILE_API({
          project_id: this.$route.params.project_id,
          profile: this.selectedGptProfile,
        }).then(
          (res) => {
            this.closeProfileModal();
            setTimeout(async () => {
              await this.getGPTProfiles();
              this.isLoading = false;
            }, 2000);
            this.$store.dispatch(
              `alert/${ADD_ALERT}`,
              {
                message: this.$vuetify.lang.t(
                  "$vuetify.gpt_profile.profile_update_success"
                ),
                color: "success",
              },
              { root: true }
            );
          },
          (err) => {
            this.isLoading = false;
            this.$store.dispatch(
              `alert/${ADD_ALERT}`,
              {
                message: this.$vuetify.lang.t(
                  "$vuetify.gpt_profile.profile_update_error"
                ),
                color: "error",
              },
              { root: true }
            );
          }
        );
      }, 100)

    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.$store.dispatch(
            `alert/${ADD_ALERT}`,
            {
              message: 'Copied to Clipboard',
              color: 'success',
            },
            { root: true }
          );
        },
        (err) => {
          console.error('Could not copy text: ', err);
        }
      );
    },
   gptModelDetails() {
      return this.GET_GPT_MODEL_DETAILS({
        project_id: this.$route.params.project_id,
        provider: this.profile.provider,
        model: this.profile.model,
      }).then(
        (response) => {
          const modelDetails = response.data?.detail?.response;
            this.isVisionSupported = modelDetails.is_vision_supported;
            this.isJsonSupported = modelDetails.is_json_supported;
          this.isLoading = false;
          return modelDetails;
        },
        (err) => {
          this.isLoading = false;
          return null;
        }
      );
    },
  },
};
</script>
