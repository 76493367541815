<template>
  <div>
    <v-container fill-height fluid>
      <v-row>
        <v-col cols="12" class="pa-0" xs="12" md="12">
          <Loader
            v-if="profileLoading"
            class="mr-2 mt-6"
            loaderType="card-avatar, article, actions"
          />
          <v-card v-else class="pa-2 mr-2" outlined>
            <v-row class="mx-2 mb-3">
              <v-col xs="12" md="6">
                <v-card-title>
                  <div class="d-flex flex-column">
                    <div class="my-2 ml-2">
                      {{account.account_name}}
                      <v-chip x-small outlined color="info">
                        {{ account.type }}
                      </v-chip>
                    </div>
                    <div class="body-1 ml-2">
                      {{ profile.email }}
                      <v-icon v-if="profile.email_verified" small color="green">
                        mdi-check-decagram
                      </v-icon>
                    </div>
                  </div>
                </v-card-title>
              </v-col>
            </v-row>
            <v-row class="mx-2 mb-3">
              <v-col xs="12" md="6">
                <div class="d-flex flex-column">
                  <div class="my-2 ml-2">
                    <span v-for="item in accountRoles()" :key="item">
                      <v-chip color="primary" outlined> {{ item.toUpperCase() }} </v-chip
                      >&nbsp;
                    </span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-tabs v-model="tab" show-arrows>
              <v-tab v-for="tab in tabs" :key="tab.id" @click="tabClick(tab.id)">
                <v-icon size="20" class="me-3">
                  {{ tab.icon }}
                </v-icon>
                <span>{{ tab.title }}</span>
              </v-tab>
            </v-tabs>
            <!-- tabs item -->
            <v-tabs-items v-model="tab">
              <v-tab-item>
                <v-form>
                  <v-card flat class="pa-4 mt-2">
                    <ValidationObserver ref="profileStep">
                      <v-row class="mt-4">
                        <v-col md="6" cols="12">
                          <ValidationProvider
                            name="First Name"
                            rules="is_numeric"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="profile.first_name"
                              :label="$vuetify.lang.t('$vuetify.member.first_name')"
                              outlined
                              :error-messages="
                                localizeErrorMsg(errors, 'first_name', profile.first_name)
                              "
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>

                        <v-col md="6" cols="12">
                          <ValidationProvider
                            name="Middle Name"
                            rules="is_numeric"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="profile.middle_name"
                              :label="$vuetify.lang.t('$vuetify.member.middle_name')"
                              outlined
                              :error-messages="
                                localizeErrorMsg(
                                  errors,
                                  'middle_name',
                                  profile.middle_name
                                )
                              "
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>

                        <v-col md="6" cols="12">
                          <ValidationProvider
                            name="Last Name"
                            rules="is_numeric"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="profile.last_name"
                              :label="$vuetify.lang.t('$vuetify.member.last_name')"
                              outlined
                              :error-messages="
                                localizeErrorMsg(errors, 'last_name', profile.last_name)
                              "
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <v-col md="6" cols="12">
                          <ValidationProvider
                            name="Email"
                            rules=""
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="profile.email"
                              :label="$vuetify.lang.t('$vuetify.member.email')"
                              :error-messages="errors"
                              outlined
                              disabled
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                        <!-- date of birth  -->
                        <v-col md="6" cols="12">
                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                          >
                            <!-- mdi-cake-variant-outline -->
                            <template v-slot:activator="{ on, attrs }">
                              <ValidationProvider
                                name="Date of Birth"
                                rules="birth_date"
                                v-slot="{ errors }"
                              >
                                <v-text-field
                                  v-model="date"
                                  :label="$vuetify.lang.t('$vuetify.profile.dob_label_txt')"
                                  prepend-icon=""
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  outlined
                                  :error-messages="localizeErrorMsg(errors, 'dob')"
                                ></v-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker v-model="date" no-title scrollable>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="menu = false">
                                {{ $vuetify.lang.t("$vuetify.common.cancel_btn") }}
                              </v-btn>
                              <v-btn text color="primary" @click="$refs.menu.save(date)">
                                {{ $vuetify.lang.t("$vuetify.common.ok_btn") }}
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col md="6" cols="12">
                          <ValidationProvider
                            name="Phone Number"
                            rules=""
                            v-slot="{ errors }"
                          >
                            <vue-tel-input-vuetify
                              v-model="profile.phone"
                              :errorMessages="
                                localizeErrorMsg(errors, 'phone_no', profile.phone)
                              "
                              @input="onInput"
                              mode="international"
                              placeholder=" "
                              :label="$vuetify.lang.t('$vuetify.profile.phone_no_label_txt')"
                              :disabledFetchingCountry="true"
                              outlined
                            ></vue-tel-input-vuetify>
                          </ValidationProvider>
                        </v-col>

                        <v-col md="6" cols="12">
                          <ValidationProvider name="Gender" rules="" v-slot="{ errors }">
                            <v-autocomplete
                              :label="$vuetify.lang.t('$vuetify.profile.gender_label_txt')"
                              :items="genderParams"
                              v-model="profile.gender"
                              item-text="text"
                              item-value="value"
                              :error-messages="errors"
                              outlined
                            ></v-autocomplete>
                          </ValidationProvider>
                        </v-col>

                        <v-col cols="12" md="6">
                          <ValidationProvider
                            name="Country"
                            rules=""
                            v-slot="{ errors }"
                          >
                            <form autocomplete="off" @submit.prevent>
                              <v-autocomplete
                                :label="$vuetify.lang.t('$vuetify.profile.country_label_txt')"
                                v-model="profile.country"
                                :items="AllCountry"
                                item-text="countryName"
                                item-value="countryCode"
                                :error-messages="localizeErrorMsg(errors, 'country')"
                                outlined
                              ></v-autocomplete>
                            </form>
                          </ValidationProvider>
                        </v-col>

                        <v-col cols="12" md="6">
                          <ValidationProvider
                            name="State"
                            rules=""
                            v-slot="{ errors }"
                          >
                            <form autocomplete="off" @submit.prevent>
                              <v-autocomplete
                                :label="$vuetify.lang.t('$vuetify.profile.state_label_txt')"
                                :items="
                                  AllCountry.filter(
                                    (obj) => obj.countryCode === profile.country
                                  )[0] &&
                                  AllCountry.filter(
                                    (obj) => obj.countryCode === profile.country
                                  )[0].regions
                                "
                                item-text="name"
                                item-value="name"
                                v-model="profile.region"
                                :error-messages="localizeErrorMsg(errors, 'state')"
                                outlined
                              ></v-autocomplete>
                            </form>
                          </ValidationProvider>
                        </v-col>

                        <v-col cols="12" md="6">
                          <ValidationProvider
                            name="City"
                            rules="is_numeric"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="profile.city"
                              :label="$vuetify.lang.t('$vuetify.profile.city_label_txt')"
                              :error-messages="
                                localizeErrorMsg(errors, 'city', profile.city)
                              "
                              outlined
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>

                        <v-col cols="12" md="6">
                          <ValidationProvider
                            name="Postal Code"
                            mode="eager"
                            rules="postal_code:@Country"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="profile.postal_code"
                              :error-messages="
                                localizeErrorMsg(errors, 'post_code', profile.postal_code)
                              "
                              :label="$vuetify.lang.t('$vuetify.profile.post_code_label_txt')"
                              outlined
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>

                        <v-col cols="12" md="6">
                          <ValidationProvider
                            name="Address"
                            rules="is_numeric"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              v-model="profile.address"
                              :error-messages="
                                localizeErrorMsg(errors, 'address', profile.address)
                              "
                              :label="$vuetify.lang.t('$vuetify.profile.address_label_txt')"
                              outlined
                            ></v-text-field>
                          </ValidationProvider>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          outlined
                          large
                          class="mr-2"
                          type="reset"
                          @click.prevent="resetForm"
                        >
                          {{ $vuetify.lang.t("$vuetify.common.cancel_btn") }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          large
                          @click="updateProfile"
                          :loading="isUpdateProfile"
                        >
                          {{ $vuetify.lang.t("$vuetify.common.save_btn") }}
                        </v-btn>
                      </v-card-actions>
                    </ValidationObserver>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item v-if="showAccountInfo">
                <div>
                  <AccountInformation />
                </div>
              </v-tab-item>
              <v-tab-item v-if="showIDVerification">
                <v-form>
                  <v-card flat class="pa-4 mt-2">
                    <ValidationObserver ref="verifyStep">
                      <v-row>
                        <v-col md="12" cols="12">
                          <v-chip
                            v-if="organization.id_status === 'verified'"
                            class="text-capitalize"
                            outlined
                            medium
                            color="primary"
                          >
                            {{ organization.id_status }}
                          </v-chip>
                          <v-chip
                            v-else-if="organization.id_status === 'pending'"
                            class="text-capitalize"
                            outlined
                            medium
                            color="orange"
                          >
                            {{ organization.id_status }}
                          </v-chip>
                          <v-chip
                            v-else
                            class="text-capitalize"
                            outlined
                            medium
                            color="red"
                          >
                            {{ organization.id_status }}
                          </v-chip>
                        </v-col>
                        <v-col cols="12" xs="12" md="12">
                          <v-label>
                            {{ $vuetify.lang.t("$vuetify.profile.id_verify_header_txt") }}
                          </v-label>
                        </v-col>
                        <v-col cols="12" md="6">
                          <!-- select image  -->
                          <v-row no-gutters>
                            <v-col cols="12" md="12">
                              <ValidationProvider
                                name="Front Side"
                                rules="image"
                                ref="front_side"
                                v-slot="{ validate, errors }"
                              >
                                <v-file-input
                                  show-size
                                  :label="
                                    $vuetify.lang.t('$vuetify.profile.upload_img_1_label_txt')
                                  "
                                  accept="image/*"
                                  @change="selectImage"
                                  outlined
                                  :disabled="isDisabled()"
                                  :error-messages="
                                    localizeErrorMsg(errors, 'upload_img_1')
                                  "
                                >
                                </v-file-input>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                          <!-- Image Preview -->
                          <v-row>
                            <v-col cols="12" v-if="previewImage">
                              <div v-if="progress">
                                <div>
                                  <v-progress-linear
                                    v-model="progress"
                                    color="primary"
                                    height="25"
                                    reactive
                                  >
                                    <strong>{{ progress }} %</strong>
                                  </v-progress-linear>
                                </div>
                              </div>

                              <div>
                                <v-img
                                  class="preview my-3"
                                  aspect-ratio="2"
                                  contain
                                  :src="previewImage"
                                  alt=""
                                ></v-img>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12" md="6">
                          <!-- select image id back  -->
                          <v-row no-gutters>
                            <v-col cols="12" md="12">
                              <ValidationProvider
                                name="Back Side"
                                rules="image"
                                ref="back_side"
                                v-slot="{ validate, errors }"
                              >
                                <v-file-input
                                  show-size
                                  :label="
                                    $vuetify.lang.t('$vuetify.profile.upload_img_2_label_txt')
                                  "
                                  accept="image/*"
                                  @change="selectImageBack"
                                  outlined
                                  :disabled="isDisabled()"
                                  :error-messages="
                                    localizeErrorMsg(errors, 'upload_img_2')
                                  "
                                >
                                </v-file-input>
                              </ValidationProvider>
                            </v-col>
                          </v-row>
                          <!-- Image Preview -->
                          <v-row>
                            <v-col cols="12" v-if="previewImageBack">
                              <div v-if="progressBack">
                                <div>
                                  <v-progress-linear
                                    v-model="progressBack"
                                    color="primary"
                                    height="25"
                                    reactive
                                  >
                                    <strong>{{ progressBack }} %</strong>
                                  </v-progress-linear>
                                </div>
                              </div>

                              <div>
                                <v-img
                                  class="preview my-3"
                                  aspect-ratio="2"
                                  contain
                                  :src="previewImageBack"
                                  alt=""
                                ></v-img>
                              </div>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="error"
                          outlined
                          large
                          class="mr-2"
                          type="reset"
                          @click.prevent="resetForm"
                        >
                          {{ $vuetify.lang.t("$vuetify.common.cancel_btn") }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          large
                          :disabled="isDisabled()"
                          :loading="uploading"
                          @click="upload"
                        >
                          {{ $vuetify.lang.t("$vuetify.common.upload_btn") }}
                        </v-btn>
                      </v-card-actions>
                    </ValidationObserver>
                  </v-card>
                </v-form>
              </v-tab-item>
              <v-tab-item>
                <!-- change password implementation  -->
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card>
                    <v-row class="px-5 py-5">
                      <v-col cols="12" md="12">
                        <v-text-field
                          :label="$vuetify.lang.t('$vuetify.registration.current_password_label_txt')"
                          v-model="oldPassword"
                          :placeholder="
                            $vuetify.lang.t('$vuetify.registration.current_password_label_txt')
                          "
                          outlined
                          :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showCurrentPassword ? 'text' : 'password'"
                          :rules="[
                            (v) =>
                              !!v ||
                              $vuetify.lang.t('$vuetify.registration.current_password_error_msg_txt'),
                          ]"
                          @click:append="showCurrentPassword = !showCurrentPassword"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          :label="$vuetify.lang.t('$vuetify.registration.new_password_label_txt')"
                          v-model="newPassword"
                          :placeholder="
                            $vuetify.lang.t('$vuetify.registration.new_password_label_txt')
                          "
                          outlined
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPassword ? 'text' : 'password'"
                          :rules="[
                            (v) =>
                              !!v ||
                              $vuetify.lang.t('$vuetify.registration.new_password_error_msg_txt'),
                            (v) =>
                              (v && v !== oldPassword) ||
                              $vuetify.lang.t('$vuetify.registration.new_password_error_msg_txt_2'),
                            password($vuetify.lang.t('$vuetify.registration.new_password_label_txt')),
                            passwordWithEmail(
                              $vuetify.lang.t('$vuetify.registration.new_password_label_txt'),
                              profile.email
                            ),
                          ]"
                          @click:append="showPassword = !showPassword"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          :label="$vuetify.lang.t('$vuetify.registration.re_password_label_txt')"
                          v-model="confirmNewPassword"
                          :placeholder="$vuetify.lang.t('$vuetify.registration.re_password_label_txt')"
                          outlined
                          :append-icon="showRePassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showRePassword ? 'text' : 'password'"
                          :rules="[
                            (v) =>
                              !!v ||
                              $vuetify.lang.t('$vuetify.registration.re_password_error_msg_txt'),
                            match(newPassword),
                          ]"
                          @click:append="showRePassword = !showRePassword"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        outlined
                        large
                        class="mr-2"
                        type="reset"
                        @click="dialog = false"
                      >
                        {{ $vuetify.lang.t("$vuetify.common.cancel_btn") }}
                      </v-btn>
                      <v-btn
                        color="primary"
                        large
                        :loading="updateDisabled"
                        @click="updatePassword"
                      >
                        {{ $vuetify.lang.t("$vuetify.common.save_btn") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <Modal
      v-model="alertDialog"
      :title="alertTitle"
      :message="alertMessage"
      confirmButtonText="Ok"
      :cancelButton="false"
      @onConfirmation="alertDialog = false"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import validations from "@/helpers/validations";
import router from "@/router/index";
import {
  CHANGE_USER_PASSWORD,
  LOAD_ORGANIZATION,
  EDIT_USER_PROFILE,
  ADD_ALERT,
  SAVE_CONTENT,
  UPLOAD_USER_ID,
} from "@/store/_actiontypes";

import { PASSWORD } from "@/components/common/input/_inputTypes";

import { RAISED_BUTTON, TEXT_BUTTON } from "@/components/common/button/_buttontypes";

import Modal from "@/components/common/modal/Modal";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import "@/helpers/vee-validate";
import { AllCountry } from "vuetify-country-search";
import AccountInformation from "@/components/MyProfile/AccountInformation";
import Loader from "@/components/common/loader/Loader";
import { Role } from "@/helpers/role";
import { authorize } from "@/helpers/helper";
import jwt_decode from "jwt-decode";

export default {
  components: {
    Modal,
    AccountInformation,
    ValidationObserver,
    ValidationProvider,
    Loader,
  },
  props: {
    genderParams: {
      type: Array,
      default() {
        return [
          {
            text: this.$vuetify.lang.t("$vuetify.profile.male_label_txt"),
            value: "male",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.profile.female_label_txt"),
            value: "female",
          },
          {
            text: this.$vuetify.lang.t("$vuetify.profile.other_label_txt"),
            value: "other",
          },
        ];
      },
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      organization: (state) => state.account.organization,
      account: (state) => state.account.account,
    }),
    size() {
      const size = { xs: "large", sm: "large", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
  },
  created() {
    this.showAccountInfo = authorize([Role.ADMIN]);
    if(!this.showAccountInfo){
      this.tabs.splice(1,1);
    }

    let tab = this.$route.query.tab;
    if (tab && tab <= 3) {
      this.tab = parseInt(tab);
    }
    this.getProfileData();
  },
  mounted() {},
  data() {
    return {
      PASSWORD: PASSWORD,
      RAISED_BUTTON: RAISED_BUTTON,
      TEXT_BUTTON: TEXT_BUTTON,
      loading: false,
      profileLoading: true,
      showCurrentPassword: false,
      showPassword: false,
      showRePassword: false,
      authorize,
      profile: {
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        phone: "",
        logo: "",
        country: "",
        region: "",
        city: "",
        postal_code: "",
        address: "",
        roles: "",
      },
      ...validations,
      dialog: false,
      updateDisabled: false,
      oldPassword: null,
      newPassword: null,
      confirmNewPassword: null,
      alertDialog: false,
      alertTitle: "Error",
      alertMessage: "Unknown error occurs",
      saveClicked: false,
      valid: true,
      tab: 0,
      tabs: [
        {
          id: 0,
          title: this.$vuetify.lang.t("$vuetify.profile.profile_basic_tab"),
          icon: "mdi-account",
        },
        {
          id: 1,
          title: this.$vuetify.lang.t("$vuetify.profile.profile_account_tab"),
          icon: "mdi-account-outline",
        },
        // remove profile verification.
        // {
        //   id: 2,
        //   title: this.$vuetify.lang.t("$vuetify.profile.profile_verify_tab"),
        //   icon: "domain_verification",
        // },
        {
          id: 3,
          title: this.$vuetify.lang.t("$vuetify.profile.profile_settings_tab"),
          icon: "mdi-cog",
        },
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      AllCountry,
      currentImage: undefined,
      previewImage: undefined,
      id_img_key: null,
      progress: 0,
      disableUpload: false,
      currentImageBack: undefined,
      previewImageBack: undefined,
      id_img_key_back: null,
      progressBack: 0,
      disableUploadBack: false,
      identity_type: "ID",
      uploadedNumber: 0,
      isUpdateProfile: false,
      uploading: false,
      showAccountInfo: false,
      showIDVerification:false,
    };
  },
  watch: {},
  methods: {
    ...mapActions("account", [
      CHANGE_USER_PASSWORD,
      LOAD_ORGANIZATION,
      EDIT_USER_PROFILE,
      UPLOAD_USER_ID
    ]),
    ...mapActions("alert", [ADD_ALERT]),
    ...mapActions("upload", [SAVE_CONTENT]),
    localizeErrorMsg() {
      if (arguments[0] && arguments[0].length && arguments[1] && !arguments[2]) {
        return this.$vuetify.lang.t(`$vuetify.profile.${arguments[1]}_error_msg_txt`);
      }
      if (arguments[0] && arguments[0].length && arguments[1] && arguments[2]) {
        return this.$vuetify.lang.t(`$vuetify.profile.${arguments[1]}_error_msg_txt_2`);
      }
    },
    getProfileData() {
      this.LOAD_ORGANIZATION()
        .then((res) => {
          this.profileLoading = false;
          let data = res.data;
          this.profile = {
            first_name: data.first_name,
            middle_name: data.middle_name,
            last_name: data.last_name,
            email: data.email,
            email_verified: data.email_verified,
            gender: data.gender,
            phone: data.phone,
            phone_verified: data.phone_verified,
            logo: data.photo,
            country: data.country_code,
            region: data.state_or_province,
            city: data.city,
            postal_code: data.postal_code,
            address: data.address,
            // roles: data.roles[0].name,
          };
          this.date = data.birth_date;
          // this.previewImage = data.photo_id_front;
          // this.previewImageBack = data.photo_id_back;
        })
        .catch((err) => err);
    },
    //let's update user details
    updateProfile() {
      this.$refs.profileStep.validate().then((success) => {
        if (!success) {
          return;
        }
        let userData = {
          first_name: this.profile.first_name,
          middle_name: this.profile.middle_name ? this.profile.middle_name : "",
          last_name: this.profile.last_name,
          phone: this.profile.phone?.replaceAll(" ", ""),
          ...(this.date ? { birth_date: this.date } : {}),
          photo: this.profile.logo ? this.profile.logo : "",
          ...(this.profile.gender ? { gender: this.profile.gender } : {}),
          country_code: this.profile.country ? this.profile.country :"",
          state_or_province: this.profile.region ? this.profile.region :"",
          city: this.profile.city,
          postal_code: this.profile.postal_code,
          address: this.profile.address,
        };
        this.isUpdateProfile = true;
        this.EDIT_USER_PROFILE({ userData })
          .then((res) => {
            this.$store.dispatch(
              `alert/${ADD_ALERT}`,
              {
                message: this.$vuetify.lang.t("$vuetify.account.acc_update_msg_txt"),
                color: "success",
              },
              { root: true }
            );
            this.isUpdateProfile = false;
            this.getProfileData();
          })
          .catch((err) => {
            this.isUpdateProfile = false;
          });
      });
    },
    changePasswordClick() {
      this.resetForm();
      this.saveClicked = false;
      this.dialog = true;
    },
    updatePassword() {
      if (!this.$refs.form.validate()) return;
      this.saveClicked = true;
      if (this.newPassword !== this.confirmNewPassword) return;
      this.updatePasswordAction();
    },
    updatePasswordAction() {
      this.updateDisabled = true;
      this.CHANGE_USER_PASSWORD({
        old_password: this.oldPassword,
        new_password: this.newPassword,
      }).then(
        (response) => {
          this.updateDisabled = false;
        },
        (error) => {
          this.updateDisabled = false;
          this.showErrorModal(error);
        }
      );
    },
    saveID() {
      let userData = {
        photo_id_front: this.id_img_key,
        photo_id_back: this.id_img_key_back,
      };
      this.UPLOAD_USER_ID({ userData })
        .then((res) => {
          this.$store.dispatch(
            `alert/${ADD_ALERT}`,
            {
              message: this.$vuetify.lang.t("$vuetify.profile.id_upload_success_msg_txt"),
              color: "success",
            },
            { root: true }
          );
          this.getProfileData();
          this.uploadedNumber = 0;
          this.uploading = false;
        })
        .catch((err) => {
          this.uploading = false;
        });
    },
    saveIDHandle() {
      if (this.uploadedNumber >= 2) {
        this.saveID();
      }
    },
    showErrorModal(error) {
      this.alertTitle = "Error";
      if (error.response && error.response.status) {
        this.alertTitle += ": " + error.response.status;
      }
      let errormessage =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      if (errormessage) {
        this.alertMessage = errormessage;
      } else {
        this.alertMessage = "Unknown Error!";
      }
      this.alertDialog = true;
    },
    validateForm() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    resetForm() {},
    onInput(formattedNumber, { number, valid, country }) {
      this.profile.phone = number.international;
    },
    async selectImage(image) {
      const { valid } = await this.$refs.front_side.validate(image);
      this.currentImage = image;
      if (image === null) {
        this.previewImage = undefined;
      } else {
        this.previewImage = URL.createObjectURL(this.currentImage);
      }
      this.progress = 0;
    },
    // upload
    upload() {
      this.$refs.verifyStep.validate().then((success) => {
        if (!success) {
          return;
        }
        this.uploading = true;
        this.uploadedNumber = 0;
        this.uploadFront();
        this.uploadBack();
      });
    },
    // upload front
    uploadFront() {
      if (!this.currentImage) {
        this.alertMessage = "Please select both front and back side of your ID!";
        this.alertDialog = true;
        return;
      }
      this.disableUpload = true;
      this.progress = 0;
      this.SAVE_CONTENT({
        formData: this.currentImage,
        content_type: "identity",
        identity_type: this.identity_type,
        identity_side: "front",
        onUploadProgress: (event) => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        },
      })
        .then((res) => {
          this.previewImage = res.data.details.url;
          this.id_img_key = res.data.details.key;
          this.disableUpload = false;
          this.uploadedNumber++;
          this.saveIDHandle();
        })
        .catch((err) => {
          this.disableUpload = false;
          this.uploading = false;
        });
    },
    async selectImageBack(image) {
      const { valid } = await this.$refs.back_side.validate(image);
      this.currentImageBack = image;
      if (image === null) {
        this.previewImageBack = undefined;
      } else {
        this.previewImageBack = URL.createObjectURL(this.currentImageBack);
      }
      this.progressBack = 0;
    },
    // upload back
    uploadBack() {
      if (!this.currentImageBack) {
        this.alertMessage = "Please select both front and back side of your ID!";
        this.alertDialog = true;
        return;
      }
      this.disableUploadBack = true;
      this.progressBack = 0;
      this.SAVE_CONTENT({
        formData: this.currentImageBack,
        content_type: "identity",
        identity_type: this.identity_type,
        identity_side: "back",
        onUploadProgress: (event) => {
          this.progressBack = Math.round((100 * event.loaded) / event.total);
        },
      })
        .then((res) => {
          this.previewImageBack = res.data.details.url;
          this.id_img_key_back = res.data.details.key;
          this.disableUploadBack = false;
          this.uploadedNumber++;
          this.saveIDHandle();
        })
        .catch((err) => {
          this.disableUploadBack = false;
          this.uploading = false;
        });
    },
    isDisabled() {
      if (this.organization.id_status === "pending") {
        return true;
      } else if (this.organization.id_status === "verified") {
        return true;
      } else {
        return false;
      }
    },
    accountRoles() {
      return jwt_decode(this.user.access_token)["scope"];
    },
    tabClick(id) {
      this.$router.push("/profile-settings?tab=" + id);
    },
  },
};
</script>

<style></style>
